import React, {useState, useEffect, useRef } from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
//import {  faPlus } from "@fortawesome/free-solid-svg-icons"; //faHome,
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//----- new grid
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Button  from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Vacaciones = () =>{

    //-------- hooks
    const [pendientes, setPendientes]               = useState([]);
    const [historia, setHistoria]                   = useState([]);
    const [visible , SetVisible]                    = useState({ show: false, id: null });
    const [visibleAutorizar , SetVisibleAutorizar]  = useState({ show: false, id: null });
    //const [visible2 , SetVisible2]                  = useState(false);
    const [visible3 , SetVisible3]                  = useState(false);
    const [nombreArea, setNombreArea]               = useState("");
    const [status, setStatus]                       = useState("");
    const [errorNombreArea, setErrorNombreArea]     = useState(null);
    const [errorStatus, setErrorStatus]             = useState(null);
    const [showBtn, setShowBtn]                     = useState(false);
    const [isAuthorized, setIsAuthorized]           = useState(true);
    //----- data grid
    const [loading, setLoading]                     = useState(false);
    //const [page, setPage]                         = useState(0);
    const [selectionModel, setSelectionModel]       = useState([]);
    const prevSelectionModel                        = useRef(selectionModel);    
    const [totalRows, setTotalRows]                 = useState(0);
    const [totalRows2, setTotalRows2]               = useState(0);
    const [open, setOpen]                           = useState(false);

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    const OnChangeNombreArea = (e) =>{
        setNombreArea(e.target.value);        
    }

    const OnChangeStatus = (e) =>{
        setStatus(e.target.value);          
    }

    const updateData = () => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/areas/')
            .then(response => {
                setIsAuthorized(true);
                setPendientes(response.data.data.data);
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })

        );
        
    }

    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        

        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
            const retrieveData = () => {

                trackPromise(

                    instance.get('/approve-vacaciones')
                    .then(response => {
                        
                        setIsAuthorized(true);
                        setPendientes(response.data.data.data);
                        setTotalRows(response.data.data.total);
                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
    
                );

                trackPromise(

                    instance.get('/history-vacaciones')
                    .then(response => {
                        setIsAuthorized(true);
                        setHistoria(response.data.data.data);
                        setTotalRows2(response.data.data.total);
                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
    
                );
                
            }

        retrieveData();

        }
        

    },[]);

    const updateListado = () => {
        setPendientes([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/approve-vacaciones')
            .then(response => {
                setPendientes(response.data.data.data);
            })
            .catch(e => {
              console.log(e);
            })

        );

        trackPromise(

            instance.get('/history-vacaciones')
            .then(response => {
                setIsAuthorized(true);
                setHistoria(response.data.data.data);
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })

        );


    }

    const handleDeleteTrue = (id) => {
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.put('/deny/'+id)
            .then(response => {
                if(response.status === 200){
                    updateListado();
                    setOpen(true);
                    SetVisible({
                        visible: false,
                        id: null,
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const handleDeleteTrueAutorizar = (id) => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.put('/authorize/'+id)
            .then(response => {
                console.log(response);
                console.log(id);
                if(response.status === 200){
                    updateListado();
                    setOpen(true);
                    SetVisibleAutorizar({
                        visible: false,
                        id: null,
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    const handleDeleteFalseAutorizar = () => {
        SetVisibleAutorizar(false);
    }

    const handleDeleteFalse3 = () => {
        SetVisible3(false);
    }

    const handleSubmitNew = () => {

        let errorsForm = 0;
        setShowBtn(true)
            const regex_letras 	= /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
            
            //---------------------- [		validaciones		] --------------------------
            if(nombreArea.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreArea("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
            }
            else{
                setErrorNombreArea("");
            }

                if(!status){
                    //console.log("Error: solo letras");
                    setErrorStatus("Por favor seleccione una opción válida");
                    errorsForm = errorsForm + 1;
                    console.log(errorsForm);
                }
                else{
                    setErrorStatus("");
                }

                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    trackPromise(
                        instance.post('areas', { 
                            nombre_area: nombreArea, 
                            status: status
                            })
                        .then(response => {
                          
                          if(response.data.success === true){
                                //alert("El área se guardó exitosamente")
                                setPendientes([]);
                                updateData();
                                setNombreArea(""); 
                                setStatus("");
                                SetVisible3(false);
                                updateData();
                          }
                          
                        })
                          .catch(e => {
                            console.log(e);
                          })
                      );
                } 
    }

    const showConfirm = (id) => {
        SetVisible({visible: true, id: id});
    }

    const showConfirmAutorizar = (id) => {
        SetVisibleAutorizar({visible: true, id: id});
    }

    //-------------- nuevo grid ---------------------------

    const NextPage = (page) => {

        setLoading(true);
        setPendientes([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/approve-vacaciones?page='+page)
            .then(response => {
                setPendientes(response.data.data.data);
                setTotalRows(response.data.data.total);
                setLoading(false);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const columns = [
        {
          field: 'nombres',
          headerName: 'Nombre empleado',
          minWidth: 200,
          flex: .24,
          headerClassName: 'letal',
          renderCell: (params) => {
                return params.row.nombres+" "+params.row.apellido_paterno+" "+params.row.apellido_materno;
            },
        },
        {
            field: 'cantidad_dias',
            headerName: 'Cantidad días',
            minWidth: 100,
            flex: .08,
            headerClassName: 'letal',
        },
        {
            field: 'fecha_inicio',
            headerName: 'Fecha inicio',
            minWidth: 150,
            flex: .1,
            headerClassName: 'letal',
        },
        {
            field: 'fecha_fin',
            headerName: 'Fecha fin',
            minWidth: 150,
            flex: .1,
            headerClassName: 'letal',
        },
        {
          field: 'status',
          flex: 0.1,
          headerName: 'Estatus',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
                let txt_status = "";
                //console.log(params.row.status);
                switch (params.row.status) {
                    case 1:
                        txt_status = "En proceso"
                        break;
                    case 2:
                            txt_status = "Autorizadas"
                        break;
                    
                    case 3:
                            txt_status = "Rechazadas"
                        break;
                    default:
                        txt_status = "-"
                        break;
                }

                
                return txt_status;
          },
         },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 150,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                  <Button
                    variant="text"
                    color="success"
                    size="large"
                    className="pr-1 mr-1 btn_emp"
                    startIcon={<CheckCircleIcon />}
                    onClick={() => showConfirmAutorizar(params.id)}
                  />
                  
                  <Button
                    variant="text"
                    color="error"
                    size="large"
                    className="mr-1"
                    startIcon={<CancelIcon />}
                    onClick={() => showConfirm(params.id)}
                  />
                </strong>
            ),
        }
        
    
    ];

    const rows = pendientes;

    const columns2 = [
        {
          field: 'nombres',
          headerName: 'Nombre empleado',
          minWidth: 200,
          flex: .18,
          headerClassName: 'letal',
          renderCell: (params) => {
                return params.row.nombres+" "+params.row.apellido_paterno+" "+params.row.apellido_materno;
            },
        },
        {
            field: 'cantidad_dias',
            headerName: 'Cantidad días',
            minWidth: 100,
            flex: .08,
            headerClassName: 'letal',
        },
        {
            field: 'fecha_inicio',
            headerName: 'Fecha inicio',
            minWidth: 150,
            flex: .1,
            headerClassName: 'letal',
        },
        {
            field: 'fecha_fin',
            headerName: 'Fecha fin',
            minWidth: 150,
            flex: .1,
            headerClassName: 'letal',
        },
        {
          field: 'status',
          flex: 0.1,
          headerName: 'Estatus',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
                let txt_status = "";
                //console.log(params.row.status);
                switch (params.row.status) {
                    case 1:
                        txt_status = "En proceso"
                        break;
                    case 2:
                            txt_status = "Autorizadas"
                        break;
                    
                    case 3:
                            txt_status = "Rechazadas"
                        break;
                    default:
                        txt_status = "-"
                        break;
                }

                
                return txt_status;
          },
         },
         {
            field: 'name',
            headerName: 'Autorizado por',
            minWidth: 150,
            flex: .15,
            headerClassName: 'letal',
        },
    ];

    const rows2 = historia;

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    ///----------------------[  main return  ]---------------------------
      
     if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized){
        return(<>
                <Header />

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        La información se guardo exitosamente.
                    </Alert>
                </Snackbar>

                <Container className="mr-inf">
                    <div className="row mt-4">
                        <div className="col-10">
                            <div className="titulo_login mb-4">Vacaciones pendientes de autorizar</div>
                        </div>
                        <div className="col-2">
                        &nbsp;
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="areas_datagrid">
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        //setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>
                    
                <div className="row mt-4">
                        <div className="col-12">
                            <div className="titulo_login mb-4">Historial de vacaciones</div>
                        </div>
                </div>
                <div className="row">
                        <div className="col-md-12">
                            <div className="areas_datagrid">
                                <DataGrid
                                    rows={rows2}
                                    columns={columns2}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows2}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        //setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>
            </Container>

            <Modal size="lg" show={visible.visible} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>¿Estas seguro que deseas rechazarlas?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrue(visible.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalse()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visibleAutorizar.visible} onHide={handleDeleteFalseAutorizar} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>¿Estas seguro que deseas autorizarlas?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrueAutorizar(visibleAutorizar.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalseAutorizar()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

          
            <Modal size="lg" show={visible3} onHide={handleDeleteFalse3} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Cargar vacaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="row mt-4">
					<div className="col-md-2">
						<span>Nombre área</span>
					</div>
					<div className="col-md-10">
						<span><input type="text" className="form-control" id="inp_nombre_proyecto" name="nombre_area" defaultValue={nombreArea || ''} onChange={OnChangeNombreArea} required="required" /></span>
						<span className="red_alert">{ errorNombreArea }</span>
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-2">
						<span>Estatus</span>
					</div>
					<div className="col-md-10">
						<span>
							<select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
								<option value="">Seleccione...</option>
                                <option value="1">Activo</option>
								<option value="2">Inactivo</option>
							</select>
						</span>
                        <span className="red_alert">{ errorStatus }</span>
					</div>
				</div>
                <div className="row mt-3 mb-5">
					<div className="col-md-2">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-10">
						<span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmitNew}  /></span>
					</div>
				</div>
                    </Container>
                </Modal.Body>
            </Modal>

        </>)
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default Vacaciones;