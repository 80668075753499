let sys_environment = "prod"; //test o prod

if(sys_environment === "test"){

    module.exports = global.config = {
        server_endpoint: {
            url: "http://localhost:8000/api",
            maxFilesEmployee: 5
            //title: "RCI Notifications"
        }
    };
}
else if(sys_environment === "prod"){

    module.exports = global.config = {
        server_endpoint: {
            url: "https://api.tonino.mx/api",
            maxFilesEmployee: 5
            //title: "RCI Notifications"
        }
    };
}