import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react';
import './css/Welcome.css';
import './css/Login.css';
import Header from './header';
import { Container } from 'react-bootstrap';
import  { Redirect } from 'react-router-dom'
import GroupIcon from '@mui/icons-material/Group';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Home = () =>{

	let ud       									= sessionStorage.getItem('userData');
	let userData 									= JSON.parse(ud);

	if(sessionStorage.getItem('userData') != null && userData.jerarquia>0){
        document.body.style = 'background: #fff !important;';

        if(userData.jerarquia<3){

        return(<>
                <Header />
                <Container className="mr-inf">
                    <div className="row">
                        <div className="col-12">
                            <div className="titulo_login mt-5">Bienvenido {userData.name}</div>
                            <div className="row mt-4">
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                            <a href="./empleados">
                                                <GroupIcon className="iconos_centrales" />
                                                <h1 className="titulo_iconos">Empleados</h1>
                                            </a>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                        <a href="./clientes">
                                            <BusinessCenterIcon className="iconos_centrales" />
                                            <h1 className="titulo_iconos">Clientes</h1>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                        <a href="./proyectos">
                                            <AssignmentIcon className="iconos_centrales" />
                                            <h1 className="titulo_iconos">Proyectos</h1>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                        <a href="./mi-empresa">
                                            <ApartmentIcon className="iconos_centrales" />
                                            <h1 className="titulo_iconos">Mi empresa</h1>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                        <a href="./rendimiento-horas">
                                            <AssessmentIcon className="iconos_centrales" />
                                            <h1 className="titulo_iconos">Rendimiento de horas</h1>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                        <a href="./vacaciones">
                                            <AirplanemodeActiveIcon className="iconos_centrales" />
                                            <h1 className="titulo_iconos">Autorizar vacaciones</h1>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
                </Container>
            </>)
        }
        else{
            return(<>
                <Header />
                <Container className="mr-inf">
                    <div className="row">
                        <div className="col-12">
                            <div className="titulo_login mt-5">Bienvenido {userData.name}</div>
                            <div className="row mt-4">
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                            <a href="./mi-cuenta">
                                                <AccountCircleIcon className="iconos_centrales" />
                                                <h1 className="titulo_iconos">Mi cuenta</h1>
                                            </a>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                        <a href="./mis-tareas">
                                            <BusinessCenterIcon className="iconos_centrales" />
                                            <h1 className="titulo_iconos">Mis tareas</h1>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 mt-3" >
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 carde text-center">
                                        <a href="./mis-vacaciones">
                                            <AirplanemodeActiveIcon className="iconos_centrales" />
                                            <h1 className="titulo_iconos">Mis vacaciones</h1>
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
                </Container>
            </>)
        }
     }
     else{
        return <Redirect to="/logout"  />;
    }

}

export default Home;