import React, {useState, useEffect, useCallback, useRef } from 'react';
import { Container, Modal, Row, Col, Table } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button  from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons"; //faHome,
//------------
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//------------
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useDropzone} from 'react-dropzone'
import Tooltip from '@mui/material/Tooltip';

//---------------------------------------------------------------
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


//---------------------------------------------------------------
const puestos = ['Cuentas', 'Project Manager', 'Programador SR', 'Programador JR', 'Diseñador JR', 'Diseñador SR', 'Maquetador', 'Trafficker']
//---------------------------------------------------------------

const Proyectos = () =>{

    //-------- hooks
    const [proyectos, setProyectos]                         = useState([]);
    const [listadoClientes, setListadoClientes]             = useState([]); 
    const [listadoEmpleadosRol, setListadoEmpleadosRol]     = useState([]); 
    const [listadoEmpleados, setListadoEmpleados]           = useState([]); 
    const [listadoTareas, setListadoTareas]                 = useState([]); 
    //const [empleados, setEmpleados]                         = useState([]);
    const [visible , SetVisible]                            = useState({ show: false, id: null });
    const [visible2 , SetVisible2]                          = useState(false)
    const [visible3 , SetVisible3]                          = useState(false)
    const [newTarea , SetNewTarea]                          = useState(false)
    const [editTarea , SetEditTarea]                        = useState(false)
    const [showListadoTareasMain, SetShowListadoTareasMain] = useState(true)
    const [showBtnNewTarea, SetShowBtnNewTarea]             = useState("original")
    const [showBtnEditTarea, SetShowBtnEditTarea]           = useState("apagado")
    const [nombreProyecto, setNombreProyecto]               = useState("")
    const [idCliente, setIdCliente]                         = useState("")
    const [fechaIngreso, setFechaIngreso]                   = useState("")
    const [descripcion, setDescripcion]                     = useState("")
    const [status, setStatus]                               = useState("")
    const [roles, setRoles]                                 = useState([])
    const [activeEmp, setActiveEmp]                         = useState(true)
    const [open, setOpen]                                   = useState(false)
    //-------------------------------------- new tarea -------------------------------------
    const [tituloTarea, SetTituloTarea]                     = useState("")
    const [descripcionTarea, SetDescripcionTarea]           = useState("")
    const [fechaTarea, SetFechaTarea]                       = useState("")
    const [horaTarea, SetHoraTarea]                         = useState("")
    const [listEmpleados, setListEmpleados]                 = useState([])
    const [idEmpleado, setIdEmpleado]                       = useState("")
    const [errorTituloTarea, setErrorTituloTarea]           = useState(null)
    const [errorDescripcionTarea, setErrorDescripcionTarea] = useState(null)
    const [errorFechaTarea, setErrorFechaTarea]             = useState(null)
    const [errorHoraTarea, setErrorHoraTarea]               = useState(null)
    const [errorIdEmpleadoTarea, setErrorIdEmpleadoTarea]   = useState(null)
    //-------------------------------------- end new tarea ---------------------------------
    // -------------------------------------- editar tarea  --------------------------------
    const [tituloEdit, setTituloEdit]                       = useState("")
    const [descripcionEdit, setDescripcionEdit]             = useState("")
    const [comentariosEdit, setComentariosEdit]             = useState("")
    const [fechaEntregaEdit, setFechaEntregaEdit]           = useState("")
    const [horaEntregaEdit, setHoraEntregaEdit]             = useState("")
    const [idEmpleadoEdit, setIdEmpleadoEdit]               = useState("")
    const [statusEdit, setStatusEdit]                       = useState("")
    const [idEdit, setIdEdit]                               = useState("")    
    const [errorTituloEdit, setErrorTituloEdit]             = useState(null)
    const [errorDescripcionEdit, setErrorDescripcionEdit]   = useState(null)
    const [errorFechaEdit, setErrorFechaEdit]               = useState(null)
    const [errorHoraEdit, setErrorHoraEdit]                 = useState(null)
    const [errorIdEmpleadoEdit, setErrorIdEmpleadoEdit]     = useState(null)   
    const [errorStatusEdit, setErrorStatusEdit]             = useState(null)   
    // ----------------------------------------------------------------------------
    const [idToChange, setIdToChange]                       = useState("")
    const [isAuthorized, setIsAuthorized]                   = useState(true)
    const [idBusqueda, setIdBusqueda]                       = useState();
    //--------------------------------------
    const [valueEmpleado, setValueEmpleado]                 = useState();
    const [valuePuesto, setValuePuesto]                     = useState();
    //--------------------------------------
    const [errorNombreProyecto, setErrorNombreProyecto]     = useState(null)
    const [errorDescripcion, setErrorDescripcion]           = useState(null)
    const [errorFechaIngreso, setErrorFechaIngreso]         = useState(null)
    const [errorStatus, setErrorStatus]                     = useState(null)
    const [showBtn, setShowBtn]                             = useState(false)
    const [showBtn2, setShowBtn2]                           = useState(false)
    const [showBtn3, setShowBtn3]                           = useState(false)
    const [disabledSave, setDisabledSave]                   = useState(true)
    //-------------------- nuevo data grid -------------------
    const [loading, setLoading]                             = useState(false);
    const [page, setPage]                                   = useState(0);
    const [selectionModel, setSelectionModel]               = useState([]);
    const prevSelectionModel                                = useRef(selectionModel);
    const [totalRows, setTotalRows]                         = useState(0);
    const [value, setValue]                                 = useState(0);
    const [loadingUploading, setLoadingUploading]           = useState(false);
    const [btnDisabled, setBtnDisabled]                     = useState(false);
    // ------    files    --------
    const [activeFiles, setActiveFiles ]                    = useState([]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const OnChangeTituloTarea = (e) =>{
        SetTituloTarea(e.target.value);        
    };

    const OnChangeHoraTarea = (e) =>{
        SetHoraTarea(e.target.value);        
    };

    const OnChangeTituloEdit = (e) =>{
        setTituloEdit(e.target.value);        
    };

    const OnChangeDescripcionTarea = (e) =>{
        SetDescripcionTarea(e.target.value);      
    }

    const OnChangeDescripcionEdit = (e) =>{
        setDescripcionEdit(e.target.value);      
    }

    const OnChangeComentariosEdit = (e) =>{
        setComentariosEdit(e.target.value);      
    }

    const OnChangeIdEmpleadoEdit = (e) =>{
        setIdEmpleadoEdit(e.target.value);      
    }

    const OnChangeFechaTarea = (e) =>{
        SetFechaTarea(e.target.value);      
    }

    const OnChangeFechaEdit = (e) =>{
        setFechaEntregaEdit(e.target.value);      
    }

    const OnChangeHoraEdit = (e) =>{
        setHoraEntregaEdit(e.target.value);      
    }

    const OnChangeStatusEdit = (e) =>{
        setStatusEdit(e.target.value);      
    }

    const OnChangeNombreProyecto = (e) =>{
        setNombreProyecto(e.target.value);        
    }

    const OnChangeIdCliente = (e) =>{
        setIdCliente(e.target.value);        
    }

    const OnChangeIdEmpleado = (e) =>{
        setIdEmpleado(e.target.value);        
    }

    const OnChangeDescripcion = (e) =>{
        setDescripcion(e.target.value);        
    }

    const OnChangeFechaIngreso = (e) =>{
        setFechaIngreso(e.target.value);        
    }

    const OnChangeStatus = (e) =>{
        setStatus(e.target.value);          
    }

    const DeleteElement = (id) =>{
        const list = [...roles];
        list.splice(id, 1)
        setRoles(list);
        //alert("se borro exitosamente el registro")
    }

    const DeleteElementEdit = (id) =>{
        //alert(id)
        
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.delete('tareas/'+id)
            .then(response => {
                //console.log(response);
                if(response.status === 200){
                    const list = [...listadoTareas];
                    let nuevo = list.filter(data => data.id !== id);
                    setListadoTareas(nuevo);
                    alert("borrado exitosamente");
                }
            })
              .catch(e => {
                console.log(e);
              })
        );

        
    }

    const SaveElement = () =>{
        let rolnuevo = [valueEmpleado, valuePuesto];
        setRoles(roles => [...roles, rolnuevo]); 
        setValueEmpleado()
        setValuePuesto()
        setActiveEmp(false)
        setDisabledSave(true)
    }

    const SaveElementEdit = () =>{
        let rolnuevo = [valueEmpleado, valuePuesto];
        setListadoEmpleadosRol(listadoEmpleadosRol => [...listadoEmpleadosRol, rolnuevo]); 
        setValueEmpleado()
        setValuePuesto()
        setActiveEmp(false)
        setDisabledSave(true)
        setActiveEmp(true)
    }

    const updateData = () => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/proyectos')
            .then(response => {
                setIsAuthorized(true);
                setProyectos(response.data.data.data);
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })

        );
        
    }

    const onChangeCliente = (valor) => {
        window.location = "/proyectos/"+valor;
    }

    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
            const retrieveData = () => {
            
                let url                 = window.location.pathname;
                var por                 = url.split('/');
                let id_cliente_search, url_search;

                if(por.length>2){
                    let get_number          = (por.length)-1;
                    id_cliente_search       = por[get_number]
                    setIdBusqueda(id_cliente_search);
                }
                
                
                //url_search = '/proyectos/'+id_cliente_search;
                if(!id_cliente_search)
                    url_search = '/proyectos';
                else
                    url_search = '/proyectos/'+id_cliente_search;
                
                    
                trackPromise(

                    instance.get(url_search)
                    .then(response => {
                        setIsAuthorized(true);
                        setProyectos(response.data.data.data);
                        setTotalRows(response.data.data.total);
                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
    
                );


                trackPromise(
                    instance.get('/get-clientes')
                    .then(response => {
                        setListadoClientes(response.data.data);
                        
                    })
                    .catch(e => {
                        console.log(e);
                    })
                );

                trackPromise(
                    instance.get('/empleados')
                    .then(response => {
                        setIsAuthorized(true);
                        let cual = response.data.data.data
                        // ------------------------------------------------------------------------
                        cual.forEach( post => {
                            const { nombres, apellido_paterno } = post;
                            const elemento = nombres+" "+apellido_paterno;
                            setListadoEmpleados(listadoEmpleados => [...listadoEmpleados, elemento]);
                        })
                        // ------------------------------------------------------------------------
                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
                );
        }

        retrieveData();

        }
        

    },[]);

    const NextPage = (page) => {

        setLoading(true);
        setProyectos([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/proyectos?page='+page)
            .then(response => {
                setProyectos(response.data.data.data);
                setTotalRows(response.data.data.total);
                setLoading(false);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const updateProyectos = () => {
        setProyectos([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/proyectos')
            .then(response => {
                setProyectos(response.data.data.data);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const handleDeleteTrue = (id) => {
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.delete('/proyectos/'+id)
            .then(response => {
                if(response.status === 200){
                    updateProyectos();
                    NextPage(page);
                    SetVisible({
                        visible: false,
                        id: null,
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    const handleDeleteFalse2 = () => {
        SetVisible2(false);
    }

    const handleDeleteFalse3 = () => {
        SetVisible3(false);
    }

    const openModal = (id) => {
        
        SetVisible2(true);
        setNombreProyecto(""); 
        setIdCliente(""); 
        setDescripcion("");
        setStatus("");
        setShowBtn(false);
        setIdToChange(id);
        UpdateFilesFromServer(id);
        setListadoEmpleadosRol([])


        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('/show-proyectos/'+id)
            .then(response => {
                //console.log(response.data.data);
                setNombreProyecto(response.data.data[0].nombre_proyecto || null);
                setIdCliente(response.data.data[0].id_cliente || null);
                setFechaIngreso(response.data.data[0].fecha_creacion || null);
                setDescripcion(response.data.data[0].descripcion || null);
                setStatus(response.data.data[0].status || null);
            })
              .catch(e => {
                console.log(e);
              })
          );

          trackPromise(
            instance.get('/relarols/'+id)
            .then(response => {
                
                let res = response.data.data;
                
                for (let index = 0; index < res.length; index++) {
                    const element = res[index];
                    //console.log(element.rol+" - "+element.nombre_completo)
                    let rolnuevoo = [element.nombre_completo, element.rol];
                    setListadoEmpleadosRol(listadoEmpleadosRol => [...listadoEmpleadosRol, rolnuevoo]); 
                }

            })
              .catch(e => {
                console.log(e);
              })
          );

          trackPromise(
            instance.get('/get-clientes')
            .then(response => {
                setListadoClientes(response.data.data);
            })
              .catch(e => {
                console.log(e);
              })
          );

          trackPromise(
            instance.get('tareas-proyecto/'+id)
            .then(response => {
                //console.log(response.data.data)
                setListadoTareas(response.data.data);
            })
              .catch(e => {
                console.log(e);
              })
          );

        //   trackPromise(
        //     instance.get('/empleados/all')
        //     .then(response => {
        //         //console.log(response.data.data)
        //         setListEmpleados(response.data.data)
        //     })
        //       .catch(e => {
        //         console.log(e);
        //       })
        //   );

          // ------------

          trackPromise(
            instance.get('/relarols/'+id)
            .then(response => {
                setListEmpleados(response.data.data)
            })
              .catch(e => {
                console.log(e);
              })
          );

       
    }

    const openModalNew = (id) => {
        SetVisible3(true);
        setShowBtn(false)
        setNombreProyecto(""); 
        setIdCliente(""); 
        setFechaIngreso("");
        setDescripcion("");
        setStatus("");

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('/get-clientes')
            .then(response => {
                //console.log(response.data);
                setIsAuthorized(true);
                setListadoClientes(response.data.data);
            })
              .catch(e => {
                    setIsAuthorized(false);
                    console.log(e);
              })
          );

    }

    const handleSubmitNew = (id) => {

            let errorsForm = 0;
            setShowBtn(true)
            //const regex_letras 	= /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
            const regex_fecha       = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/; 
            const regex_final       = /^.{6,}$/;
                //---------------------- [		validaciones		] --------------------------
                if(nombreProyecto.match(regex_final) == null){
                    //console.log("Error: solo letras");
                    setErrorNombreProyecto("Sólo letras");
                    errorsForm = errorsForm + 1;
                    console.log(errorsForm);
                    setShowBtn(false)
                }
                else{
                    setErrorNombreProyecto("");
                }

                if(descripcion.match(regex_final) == null){
                    //console.log("Error: solo letras");
                    setErrorDescripcion("Sólo letras");
                    errorsForm = errorsForm + 1;
                    console.log(errorsForm);
                    setShowBtn(false)
                }
                else{
                    setErrorDescripcion("");
                }

                if(fechaIngreso.match(regex_fecha) == null){
                    //console.log("Error: solo letras");
                    setErrorFechaIngreso("Fecha inválida");
                    errorsForm = errorsForm + 1;
                    console.log(fechaIngreso+"kskjs");
                    setShowBtn(false)
                }
                else{
                    setErrorFechaIngreso("");
                }

                if(!status){
                    //console.log("Error: solo letras");
                    setErrorStatus("Por favor seleccione una opción válida");
                    errorsForm = errorsForm + 1;
                    console.log(errorsForm);
                    setShowBtn(true)
                }
                else{
                    setErrorStatus("");
                }

                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });
                    
                    trackPromise(
                        instance.post('proyectos', { 
                            nombre_proyecto: nombreProyecto, 
                            id_cliente: idCliente,
	                        fecha_creacion: fechaIngreso,
	                        descripcion: descripcion, 
                            status: status,
                            roles: roles
                            })
                        .then(response => {
                          
                          if(response.data.success === true){
                              //console.log(response.data)
                                //alert("El área se guardó exitosamente")
                                setProyectos([]);
                                updateData();
                                setNombreProyecto(""); 
                                setDescripcion(""); 
                                setFechaIngreso("");
                                setIdCliente(""); 
                                setStatus("");
                                SetVisible3(false);
                                NextPage(page);
                          }
                          
                        })
                          .catch(e => {
                            console.log(e);
                          })
                      );
                } 
    }

    const handleSubmit = (id) => {

        let errorsForm = 0;
        setShowBtn(true)
        //const regex_letras 	= /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
        const regex_fecha 	    = /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
        const regex_final       = /^.{6,}$/;

            //---------------------- [		validaciones		] --------------------------
            if(nombreProyecto.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorNombreProyecto("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn(false)
            }
            else{
                setErrorNombreProyecto("");
            }

            if(descripcion.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorDescripcion("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn(false)
            }
            else{
                setErrorDescripcion("");
            }

            if(fechaIngreso.match(regex_fecha) == null){
                //console.log("Error: solo letras");
                setErrorFechaIngreso("Fecha inválida");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn(false)
            }
            else{
                setErrorFechaIngreso("");
            }

            if(!status){
                //console.log("Error: solo letras");
                setErrorStatus("Por favor seleccione una opción válida");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn(true)
            }
            else{
                setErrorStatus("");
            }

            if(!errorsForm){

                let ud       = sessionStorage.getItem('userData');
                let userData = JSON.parse(ud);

                const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000,
                    headers: {'Authorization': 'Bearer '+userData.token}
                });
                //console.log(idToChange);
                trackPromise(
                    instance.put('/proyectos/'+idToChange, { 
                        nombre_proyecto: nombreProyecto, 
                        id_cliente: idCliente,
                        fecha_creacion: fechaIngreso,
                        descripcion: descripcion, 
                        status: status,
                        roles: listadoEmpleadosRol
                        })
                    .then(response => {
                      
                      if(response.data.success === true){
                            //alert("El área se guardó exitosamente")
                            setProyectos([]);
                            updateData();
                            setNombreProyecto(""); 
                            setDescripcion(""); 
                            setFechaIngreso("");
                            setIdCliente(""); 
                            setStatus("");
                            SetVisible2(false);
                            NextPage(page);
                      }
                      
                    })
                      .catch(e => {
                        console.log(e);
                      })
                  );
            } 
    }

    const showConfirm = (id) => {
        SetVisible({visible: true, id: id});
    }

    //------------------------- [ files ] ---------------------------------

    const UpdateFilesFromServer = (id) =>{
        setLoadingUploading(true);
        setActiveFiles("");
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('files/show/proyectos/'+id)
            .then(response => {
                setActiveFiles(response.data.data.data);
                setLoadingUploading(false);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const deleteFile = (id) => {

        setBtnDisabled(true);

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
             instance.delete('files/delete/'+id)
            .then(response => {
                //console.log(response.data.data);
                if(response.status === 200){
                    setBtnDisabled(false);
                    UpdateFilesFromServer(idToChange);
                }
            })
              .catch(e => {
                console.log(e);
              })
        );

    }
    
    const MyDropzone = () =>{
        const onDrop = useCallback(acceptedFiles => {
            alert(idToChange);
            // ------------- Logica de AWS ----------------
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);
        
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 100000,
                headers: {'Authorization': 'Bearer '+userData.token, "content-type": 'multipart/form-data'}
            });
            
            let formd = new FormData();
            formd.append("filename", acceptedFiles[0], acceptedFiles[0].name);
            formd.append("id_proyecto",idToChange);
            trackPromise(
                
                instance.post('/upload-files', formd)
                .then(response => {
                    if(response.data.success === true){
                        UpdateFilesFromServer(idToChange);
                    }
                    else{
                        alert("Error #44344");
                    }
    
                })
                .catch(e => {
                    alert(e);
                })
    
            );

            // ----------- END Logica de AWS --------------
          
        }, [])

        
        const { getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
        if(activeFiles.length < global.config.server_endpoint.maxFilesEmployee){

            return (
            <div {...getRootProps()} style={{border: "3px dashed #2c63ff", marginTop: "30px", paddingTop: "20px", textAlign: "center"}}>
                <input {...getInputProps()} />
                {
                isDragActive ?
                    <p className="limite_empleado">Dejelos caer aquí</p> :
                    <p className="limite_empleado mt-3">Para subir un archivo nuevo, por favor arrastre los archivos aquí.</p>
                }
            </div>
            )
        }
        else{
            return(
                <div className="text-center limite_empleado">Se ha alcanzado el límite de archivos por empleado</div>
            )
        }
    }

    //------------------------ [ end files ] -------------------------------

    // ------------------------------ [ modulos tareas] ------------------------------

    const NuevaTarea = () => (
        <Container>
            <h1 className="titulo_login mb-4">Nueva tarea</h1>
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Nombre tarea</span>
                </div>
                <div className="col-md-9">
                    <span><input type="text" className="form-control" id="inp_titulo_tarea" name="tituloTarea" defaultValue={tituloTarea} onChange={OnChangeTituloTarea} required="required" autoComplete="off" /></span>
                    <span className="red_alert">{ errorTituloTarea }</span>
                </div>
            </div> 
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Descripción</span>
                </div>
                <div className="col-md-9">
                    <span><input type="text" className="form-control" id="inp_descripcion" name="descripcion" defaultValue={descripcionTarea} onChange={OnChangeDescripcionTarea} required="required" autoComplete="off" /></span>
                    <span className="red_alert">{ errorDescripcionTarea }</span>
                </div>
            </div>  
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Empleado</span>
                </div>
                <div className="col-md-9">
                    <span>
                        <select className="form-control" id="inp_id_empleado" name="id_empleado" value={idEmpleado} onChange={OnChangeIdEmpleado} required="required">
                            <option value="">Seleccione...</option>
                            {
                                listEmpleados.map(post => {
                                    const { id_empleado, nombre_completo  } = post;
                                    
                                    return (
                                        <option key={id_empleado} value={id_empleado}>{nombre_completo}</option>
                                    );
                                    
                                    })
                            }
                        </select>
                    </span>
                    <span className="red_alert">{ errorIdEmpleadoTarea }</span>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Fecha entrega</span>
                </div>
                <div className="col-md-9">
                    <span><input type="date" className="form-control" id="inp_fecha" name="fecha" defaultValue={fechaTarea} onChange={OnChangeFechaTarea} required="required" autoComplete="off" /></span>
                    <span className="red_alert">{ errorFechaTarea }</span>
                </div>
            </div>   
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Hora entrega</span>
                </div>
                <div className="col-md-9">
                    <span>
                        <select className="form-control" id="inp_horas" name="horas" value={horaTarea} onChange={OnChangeHoraTarea} required="required">
                            <option value="">Seleccione...</option>
                            <option value='08:00:00'>08:00</option>
                            <option value='08:30:00'>08:30</option>
                            <option value='09:00:00'>09:00</option>
                            <option value='09:30:00'>09:30</option>
                            <option value='10:00:00'>10:00</option>
                            <option value='10:30:00'>10:30</option>
                            <option value='11:00:00'>11:00</option>
                            <option value='11:30:00'>11:30</option>
                            <option value='12:00:00'>12:00</option>
                            <option value='12:30:00'>12:30</option>
                            <option value='13:00:00'>13:00</option>
                            <option value='13:30:00'>13:30</option>
                            <option value='14:00:00'>14:00</option>
                            <option value='14:30:00'>14:30</option>
                            <option value='15:00:00'>15:00</option>
                            <option value='15:30:00'>15:30</option>
                            <option value='16:00:00'>16:00</option>
                            <option value='16:30:00'>16:30</option>
                            <option value='17:00:00'>17:00</option>
                            <option value='17:30:00'>17:30</option>
                            <option value='18:00:00'>18:00</option>
                            <option value='18:30:00'>18:30</option>
                            <option value='19:00:00'>19:00</option>
                            <option value='19:30:00'>19:30</option>
                            <option value='20:00:00'>20:00</option>
                        </select>
                    </span>
                    <span className="red_alert">{ errorHoraTarea }</span>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-3">
                    <span>&nbsp;</span>
                </div>
                <div className="col-md-9">
                    <span><input type="submit" value="Guardar" disabled={showBtn2} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={() => handleSubmitNewTarea()}  /></span>
                </div>
            </div>
        </Container>
    )


    const EditarTarea = () => (
        <Container>
            <h1 className="titulo_login mb-4">Editar tarea</h1>
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Nombre tarea</span>
                </div>
                <div className="col-md-9">
                    <span><input type="text" className="form-control" id="inp_titulo_tarea" name="tituloTarea" defaultValue={tituloEdit} onChange={OnChangeTituloEdit} required="required" autoComplete="off" /></span>
                    <span className="red_alert">{ errorTituloEdit }</span>
                </div>
            </div> 
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Descripción</span>
                </div>
                <div className="col-md-9">
                    <span><input type="text" className="form-control" id="inp_descripcion" name="descripcion" defaultValue={descripcionEdit} onChange={OnChangeDescripcionEdit} required="required" autoComplete="off" /></span>
                    <span className="red_alert">{ errorDescripcionEdit }</span>
                </div>
            </div>  
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Empleado</span>
                </div>
                <div className="col-md-9">
                    <span>
                        <select className="form-control" id="inp_id_empleado" name="id_empleado" value={idEmpleadoEdit} onChange={OnChangeIdEmpleadoEdit} required="required">
                            <option value="">Seleccione...</option>
                            {
                                listEmpleados.map(post => {
                                    const { id_empleado, nombre_completo  } = post;
                                    
                                    return (
                                        <option key={id_empleado} value={id_empleado}>{nombre_completo}</option>
                                    );
                                    
                                    })
                            }
                        </select>
                    </span>
                    <span className="red_alert">{ errorIdEmpleadoEdit }</span>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Comentarios</span>
                </div>
                <div className="col-md-9">
                    <span><input type="text" className="form-control" id="inp_comentarios" name="comentarios" defaultValue={comentariosEdit} onChange={OnChangeComentariosEdit} required="required" autoComplete="off" readOnly /></span>
                </div>
            </div>  
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Fecha entrega</span>
                </div>
                <div className="col-md-9">
                    <span><input type="date" className="form-control" id="inp_fecha" name="fecha" value={fechaEntregaEdit} onChange={OnChangeFechaEdit} required="required" autoComplete="off" /></span>
                    <span className="red_alert">{ errorFechaEdit }</span>
                </div>
            </div>  
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Hora entrega</span>
                </div>
                <div className="col-md-9">
                    <span>
                        <select className="form-control" id="inp_horas" name="horas" value={horaEntregaEdit} onChange={OnChangeHoraEdit} required="required">
                            <option value="">Seleccione...</option>
                            <option value='08:00:00'>08:00</option>
                            <option value='08:30:00'>08:30</option>
                            <option value='09:00:00'>09:00</option>
                            <option value='09:30:00'>09:30</option>
                            <option value='10:00:00'>10:00</option>
                            <option value='10:30:00'>10:30</option>
                            <option value='11:00:00'>11:00</option>
                            <option value='11:30:00'>11:30</option>
                            <option value='12:00:00'>12:00</option>
                            <option value='12:30:00'>12:30</option>
                            <option value='13:00:00'>13:00</option>
                            <option value='13:30:00'>13:30</option>
                            <option value='14:00:00'>14:00</option>
                            <option value='14:30:00'>14:30</option>
                            <option value='15:00:00'>15:00</option>
                            <option value='15:30:00'>15:30</option>
                            <option value='16:00:00'>16:00</option>
                            <option value='16:30:00'>16:30</option>
                            <option value='17:00:00'>17:00</option>
                            <option value='17:30:00'>17:30</option>
                            <option value='18:00:00'>18:00</option>
                            <option value='18:30:00'>18:30</option>
                            <option value='19:00:00'>19:00</option>
                            <option value='19:30:00'>19:30</option>
                            <option value='20:00:00'>20:00</option>
                        </select>
                    </span>
                    <span className="red_alert">{ errorHoraEdit }</span>
                </div>
            </div>  
            <div className="row mt-2">
                <div className="col-md-3">
                    <span className="txt_listado">Status</span>
                </div>
                <div className="col-md-9">
                    <span>
                        <select className="form-control" id="inp_status" name="status" value={statusEdit} onChange={OnChangeStatusEdit} required="required">
                            <option value="">Seleccione...</option>
                            <option value="1">Asignado</option>
                            <option value="2">Enviada</option>
                            <option value="3">Finalizada</option>
                            <option value="4">Cancelada</option>
                        </select>
                    </span>
                    <span className="red_alert">{ errorStatusEdit }</span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <span>&nbsp;</span>
                </div>
                <div className="col-md-9">
                    <span><input type="submit" value="Guardar" disabled={showBtn3} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={() => handleSubmitEditTarea(idEdit)}  /></span>
                </div>
            </div>
        </Container>
    )


    const handleSubmitNewTarea = (id) => {

        let errorsForm = 0;
        setShowBtn2(true)
        //const regex_letras 	    = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
        const regex_fecha       = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/; 
        const regex_final       = /^.{6,}$/;
        
            //---------------------- [		validaciones		] --------------------------
            if(tituloTarea.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorTituloTarea("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn2(false)
            }
            else{
                setErrorTituloTarea("");
            }

            if(descripcionTarea.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorDescripcionTarea("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn2(false)
            }
            else{
                setErrorDescripcionTarea("");
            }

            if(fechaTarea.match(regex_fecha) == null){
                //console.log("Error: solo letras");
                setErrorFechaTarea("Fecha inválida");
                errorsForm = errorsForm + 1;
                setShowBtn2(false)
            }
            else{
                setErrorFechaTarea("");
            }

            if(!horaTarea){
                //console.log("Error: solo letras");
                setErrorHoraTarea("Seleccione una hora válida");
                errorsForm = errorsForm + 1;
                setShowBtn2(false)
            }
            else{
                setErrorFechaTarea("");
            }

            if(!idEmpleado){
                //console.log("Error: solo letras");
                setErrorIdEmpleadoTarea("Por favor seleccione una opción válida");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn2(true)
            }
            else{
                setErrorIdEmpleadoTarea("");
            }

            if(!errorsForm){
               
                let ud       = sessionStorage.getItem('userData');
                let userData = JSON.parse(ud);

                const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000,
                    headers: {'Authorization': 'Bearer '+userData.token}
                });
                
                trackPromise(
                    instance.post('tareas', { 
                        id_proyecto: idToChange,
                        id_empleado: idEmpleado,
                        titulo: tituloTarea, 
                        descripcion: descripcionTarea, 
                        fecha_entrega: fechaTarea,
                        hora_entrega: horaTarea,
                        status: 1
                        })
                    .then(response => {
                      
                        if(response.data.success === true){
                            setOpen(true)
                            SetNewTarea(false)  
                            SetShowBtnNewTarea("original")
                            SetShowListadoTareasMain(true)  

                            SetTituloTarea("")
                            SetDescripcionTarea("")
                            setIdEmpleado("")
                            SetFechaTarea("")
                            SetHoraTarea("")
                            setShowBtn2(false)

                            trackPromise(
                                instance.get('tareas-proyecto/'+idToChange)
                                .then(response => {
                                    //console.log(response.data.data)
                                    setListadoTareas(response.data.data);
                                })
                                  .catch(e => {
                                    console.log(e);
                                  })
                            );
                        }
                      
                    })
                      .catch(e => {
                        console.log(e);
                      })
                  );
            } 

    }


    const handleSubmitEditTarea = (id) => {

        let errorsForm = 0;
        setShowBtn3(true)
        //const regex_letras 	= /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
        const regex_fecha  = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/; 
        const regex_final       = /^.{6,}$/;
        
            //---------------------- [		validaciones		] --------------------------
            if(tituloEdit.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorTituloEdit("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn3(false)
            }
            else{
                setErrorTituloEdit("");
            }

            if(descripcionEdit.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorDescripcionEdit("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn3(false)
            }
            else{
                setErrorDescripcionEdit("");
            }

            if(fechaEntregaEdit.match(regex_fecha) == null){
                //console.log("Error: solo letras");
                setErrorFechaEdit("Fecha inválida");
                errorsForm = errorsForm + 1;
                setShowBtn3(false)
            }
            else{
                setErrorFechaEdit("");
            }

            if(!horaEntregaEdit){
                //console.log("Error: solo letras");
                setErrorHoraEdit("Por favor seleccione una hora válida");
                errorsForm = errorsForm + 1;
                setShowBtn3(false)
            }
            else{
                setErrorHoraEdit("");
            }

            if(!idEmpleadoEdit){
                //console.log("Error: solo letras");
                setErrorIdEmpleadoEdit("Por favor seleccione una opción válida");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn3(true)
            }
            else{
                setErrorIdEmpleadoEdit("");
            }

            if(!statusEdit){
                //console.log("Error: solo letras");
                setErrorStatusEdit("Por favor seleccione una opción válida");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn3(true)
            }
            else{
                setErrorStatusEdit("");
            }

        
            if(!errorsForm){
               
                let ud       = sessionStorage.getItem('userData');
                let userData = JSON.parse(ud);

                const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000,
                    headers: {'Authorization': 'Bearer '+userData.token}
                });
                
                trackPromise(
                    instance.put('tareas/'+idEdit, { 
                        titulo: tituloEdit, 
                        descripcion: descripcionEdit, 
                        fecha_entrega: fechaEntregaEdit,
                        hora_entrega: horaEntregaEdit,
                        id_empleado: idEmpleadoEdit,
                        status: statusEdit
                        })
                    .then(response => {
                      
                        if(response.data.success === true){
                            setOpen(true)
                            SetNewTarea(false) 
                            SetEditTarea(false)  
                            SetShowBtnNewTarea("original")
                            SetShowBtnEditTarea("apagado")
                            SetShowListadoTareasMain(true)  

                            setTituloEdit("")
                            setDescripcionEdit("")
                            setComentariosEdit("")
                            setIdEmpleadoEdit("")
                            setFechaEntregaEdit("")
                            setHoraEntregaEdit("")
                            setShowBtn3(false)

                            trackPromise(
                                instance.get('tareas-proyecto/'+idToChange)
                                .then(response => {
                                    //console.log(response.data.data)
                                    setListadoTareas(response.data.data);
                                })
                                  .catch(e => {
                                    console.log(e);
                                  })
                            );
                        }
                      
                    })
                      .catch(e => {
                        console.log(e);
                      })
                  );
            } 

    }

    const ButtonNewTarea = () => (
        <IconButton aria-label="delete" className="float-right" color="error" size="large" onClick={() => handleNewTarea()}>
            <AddCircle fontSize="inherit" className="new-icon"  />
            <h1 className="add-new ml-2 mt-2">Agregar</h1>
        </IconButton>
    )

    const ButtonEditTarea = () => (
        <>
            
        </>
    )

    const ButtonCloseNewTarea = () => (
        <>
            <CloseIcon fontSize="inherit" className="close-icon-tarea" onClick={() => handleNewTarea()} />
        </>
    )

    const ButtonCloseEditTarea = () => (
        <>
            <CloseIcon fontSize="inherit" className="close-icon-tarea" onClick={() => handleEditTarea()} />
        </>
    )

    const ListadoTareasMain = () => (
        <Table striped bordered hover className='table-movil'>
            <thead>
                <tr>
                    <th><span className='dataspan'>Tarea</span></th>
                    <th><span className='dataspan'>Empleado</span></th>
                    <th><span className='dataspan'>Fecha entrega</span></th>
                    <th><span className='dataspan'>Descripción</span></th>
                    <th><span className='dataspan'>Estatus</span></th>
                    <th style={{"width" : "10%"}}><span className='dataspan'></span></th>
                </tr>
            </thead>
            <tbody>
            {
            listadoTareas &&
            listadoTareas.map( (item, index) => {
                
                    let txt_status = "";
                    switch(item['status']) {
                        case 1:
                            txt_status = "Asignada"
                            break;
                        case 2:
                            txt_status = "Enviada"
                            break;
                        case 3:
                            txt_status = "Finalizada"
                            break;
                        default:
                            txt_status = "Cancelada"
                            break;
                    }

                    return (
                        <tr key={index}>
                            <td><span className='dataspan'>{item['titulo']}</span></td>
                            <td><span className='dataspan'>{item['nombre_completo']}</span></td>
                            <td><span className='dataspan'>{item['fecha_entrega']}</span></td>
                            <td><span className='dataspan'>{item['descripcion']}</span></td>
                            <td><span className='dataspan'>{txt_status}</span></td>
                            <td><EditIcon className='delete_rol_line inline' onClick={() => handleEditTarea(item['id'])} /> </td>
                        </tr>
                        )
                
            })  
            }
            </tbody>
        </Table>
    )

    const handleNewTarea = () =>{
        if(newTarea){
            SetNewTarea(false)
            SetShowBtnNewTarea("original")
            SetShowBtnEditTarea("apagado")
            SetShowListadoTareasMain(true)
        }      
        else{
            SetNewTarea(true)  
            SetShowBtnNewTarea("abierto")
            SetShowBtnEditTarea("apagado")
            SetShowListadoTareasMain(false)  
        }
    }

    const handleEditTarea = (id) =>{
        //console.log(id)
        // ---------------
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('tareas/'+id)
            .then(response => {
                //console.log(response.data.data);
                //let dateStr =new Date(response.data.data.fecha_entrega || "")
                //let dateTimeInParts = dateStr.toISOString().split("T" )
                //console.log(dateTimeInParts[1])
                //var porciones = dateTimeInParts[1].split(':');
                //console.log(porciones[0]+":"+porciones[1]);

                // ------------------------------------------------------
                let fecha_entrega   = response.data.data.fecha_entrega
                let dateTimeInParts = fecha_entrega.split(" ")
                //console.log(dateTimeInParts[0])
                //console.log(dateTimeInParts[1])
                // ------------------------------------------------------
                setTituloEdit(response.data.data.titulo)
                setDescripcionEdit(response.data.data.descripcion)
                setComentariosEdit(response.data.data.comentarios)
                setFechaEntregaEdit(dateTimeInParts[0])
                setHoraEntregaEdit(dateTimeInParts[1])
                setIdEmpleadoEdit(response.data.data.id_empleado)
                setStatusEdit(response.data.data.status)
                setIdEdit(response.data.data.id)
            })
              .catch(e => {
                console.log(e);
              })
          );

        // ---------------
        if(editTarea){
            SetEditTarea(false)
            SetShowBtnNewTarea("original") 
            SetShowBtnEditTarea("apagado")
            SetShowListadoTareasMain(true)
        }      
        else{
            SetEditTarea(true)
            SetShowBtnNewTarea("apagado")
            SetShowBtnEditTarea("abierto")
            SetShowListadoTareasMain(false)  
        }
    }


    // ------------------------------ [ end tareas] ------------------------------

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const columns = [
       
        {
          field: 'nombre_proyecto',
          headerName: 'Nombre proyecto',
          minWidth: 200,
          flex: .5,
          headerClassName: 'letal',
        },
        {
          field: 'descripcion',
          flex: 0.4,
          headerName: 'Descripción',
          headerClassName: 'letal',
          minWidth: 250,
        },
        {
          field: 'fecha_creacion',
          headerName: 'Fecha creación',
          minWidth: 150,
          flex: .2,
          headerClassName: 'letal',
        },
        {
          field: 'id_cliente',
          headerName: 'Cliente',
          minWidth: 250,
          flex: .3,
          headerClassName: 'letal',
          renderCell: (params) => {

            let name_empresa;
            if(listadoClientes){ 
            let found = listadoClientes.find(element => element.id === params.row.id_cliente);
            if(found)
                name_empresa = found.nombre_empresa;
            }

              return name_empresa;
          },
        },
        {
          field: 'status',
          flex: 0.2,
          headerName: 'Estatus',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
                let txt_status = "";
                //console.log(params.row.status);
                switch (params.row.status) {
                    case 1:
                        txt_status = "Activo"
                        break;
                    case 2:
                            txt_status = "Inactivo"
                        break;
                    case 3:
                            txt_status = "Suspendido"
                        break;
                    case 4:
                            txt_status = "Finalizado"
                        break;
                    default:
                        txt_status = "-"
                        break;
                }

                return txt_status;
          },
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 150,
            flex: 1,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                  <Tooltip title="Borrar">
                    <Button
                        variant="text"
                        color="error"
                        size="large"
                        className="pr-1 mr-1 btn_emp"
                        startIcon={<DeleteIcon />}
                        alt="holaaaa"
                        onClick={() => showConfirm(params.id)}
                    />
                  </Tooltip>
                  
                  <Tooltip title="Editar">
                    <Button
                        variant="text"
                        color="primary"
                        size="large"
                        className="mr-1"
                        startIcon={<EditIcon />}
                        onClick={() => openModal(params.id)}
                    />
                  </Tooltip>
                </strong>
            ),
        }
        
    
    ];

    const rows = proyectos;

    const TabsComponent = () =>{
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Información" {...a11yProps(0)} />
                        <Tab label="Archivos" {...a11yProps(1)} />
                        <Tab label="Tareas" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Container className="mt-2">
                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <span className="txt_listado">Nombre proyecto</span>
                                </div>
                                <div className="col-md-9">
                                    <span><input type="text" className="form-control" id="inp_nombre_proyecto" name="nombre_proyecto" defaultValue={nombreProyecto || ''} onChange={OnChangeNombreProyecto} required="required" /></span>
                                    <span className="red_alert">{ errorNombreProyecto }</span>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <span className="txt_listado">Descripción</span>
                                </div>
                                <div className="col-md-9">
                                    <span><input type="text" className="form-control" id="inp_descripcion" name="descripcion" defaultValue={descripcion || ''} onChange={OnChangeDescripcion} required="required" /></span>
                                    <span className="red_alert">{ errorDescripcion }</span>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <span className="txt_listado">Fecha ingreso</span>
                                </div>
                                <div className="col-md-9">
                                    <span><input type="date" className="form-control" id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={fechaIngreso || ''}  onChange={OnChangeFechaIngreso} required="required" /></span>
                                    <span className="red_alert">{ errorFechaIngreso }</span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <span className="txt_listado">Cliente</span>
                                </div>
                                <div className="col-md-9">
                                    <span>
                                        <select className="form-control" id="inp_id_cliente" name="id_cliente" value={idCliente} onChange={OnChangeIdCliente} required="required">
                                            <option value="">Seleccione...</option>
                                            {
                                                listadoClientes.map(post => {
                                                    const { id, nombre_empresa } = post;
                                                    
                                                    return (
                                                        <option key={id} value={id}>{nombre_empresa}</option>
                                                    );
                                                    
                                                    })
                                            }
                                        </select>
                                    </span>
                                    <span className="red_alert">{ errorStatus }</span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <span className="txt_listado">Status</span>
                                </div>
                                <div className="col-md-9">
                                    <span>
                                        <select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
                                            <option value="">Seleccione...</option>
                                            <option value="1">Activo</option>
                                            <option value="2">Inactivo</option>
                                            <option value="3">Suspendido</option>
                                            <option value="4">Finalizado</option>
                                        </select>
                                    </span>
                                    <span className="red_alert">{ errorStatus }</span>
                                </div>
                            </div>
                            {/* roles */}
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <span className="txt_listado">Asignar roles</span>
                                </div>
                                <div className="col-md-9">
                                <div className='row'>
                                    <div className='col-md-5 col-12'>
                                    {/* <div>{`value: ${value2 !== null ? `'${value2}'` : 'null'}`}</div>
                                    <div>{`inputValue: '${inputValue}'`}</div>
                                    <br /> */}
                                    <Autocomplete
                                        className="movil-autocomp"
                                        onChange={(event, newValue) => {
                                            //console.log(newValue)
                                            setValueEmpleado(newValue);
                                            setActiveEmp(false)
                                        }}
                                        value={valueEmpleado || null}
                                        options={listadoEmpleados}
                                        sx={{ width: 225 }}
                                        renderInput={(params) => <TextField {...params} label="Empleado" />}
                                    />
                                                    
                                    </div>
                                    <div className='col-md-5 col-12'>

                                        <Autocomplete
                                            disabled={activeEmp}
                                            className="movil-autocomp"
                                            onChange={(event, newValue) => {
                                                setValuePuesto(newValue);
                                                if(valueEmpleado){
                                                    setDisabledSave(false)
                                                }
                                                
                                            }}
                                            value={valuePuesto || null}
                                            options={puestos}
                                            sx={{ width: 225 }}
                                            renderInput={(params) => <TextField {...params} label="Puesto" />}
                                        />
                                    </div>
                                    <div className='col-md-2 col-12 text-center'>
                                        <button disabled={disabledSave} className='btn-save' onClick={() => SaveElementEdit()} ><CheckCircleIcon className='save-icon' /></button>
                                    </div>
                                    </div>
                                    
                                    <br />
                                    <Table striped bordered hover className='table-movil'>
                                        <thead>
                                            <tr>
                                            <th>Nombre</th>
                                            <th>Posición</th>
                                            <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                    {
                                        listadoEmpleadosRol &&
                                        listadoEmpleadosRol.map( (item, index) => {
                                            
                                            return (
                                                <tr key={index}>
                                                    <td>{item[0]}</td>
                                                    <td>{item[1]}</td>
                                                    <td><Button onClick={() => DeleteElementEdit(index)}  className='btn  delete_rol' startIcon={<DeleteIcon />} /></td>
                                                </tr>
                                                )
                                        })
                                    }
                                        </tbody>
                                    </Table>
                                      
                                    
                                </div>
                            </div>

                        <div className="row mt-3 mb-5">
                            <div className="col-md-3">
                                <span>&nbsp;</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={() => handleSubmit()}  /></span>
                            </div>
                        </div>
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Container className="mb-5">
                        <div className="row mt-2">
                            <div className="col-md-5">
                                <span className="txt_listado"><strong>Nombre archivo</strong></span>
                            </div>
                            <div className="col-md-3">
                                <span className="txt_listado"><strong>Fecha</strong></span>
                            </div>
                            <div className="col-md-3">
                                <span className="txt_listado"><strong>Subido por</strong></span>
                            </div>
                            <div className="col-md-1">
                                <span className="txt_listado"><strong>&nbsp;</strong></span>
                            </div>
                        </div>
                        {
                            loadingUploading &&
                                <>
                                <div className="text-center">
                                    <Loader type="ThreeDots"  color="#2c63ff" height="100" width="100" />
                                    <h1 className="loading-final">Cargando...</h1>
                                </div>
                                </>
                        }
                        {
                            activeFiles &&
                            activeFiles.length<1 &&
                                <h4 className="text-center mt-4 no_hay">No hay documentos disponibles</h4>
                        }
                        {
                            activeFiles &&
                            activeFiles.map(post => {
                                
                                    const { id, filename, created_at, name} = post;
                                    //let cut = created_at.split("T");
                                    let donde = "https://d2sx5pnhyjgoui.cloudfront.net/files/"+filename;
                                
                                    return (
                                        <div className="row mt-2" key={id}>
                                            <div className="col-md-5">
                                                <span className="txt_listado"><a href={donde} target="_blank" rel="noreferrer">{filename}</a></span>
                                            </div>
                                            <div className="col-md-3">
                                                <span className="txt_listado">{created_at}</span>
                                            </div>
                                            <div className="col-md-3">
                                                <span className="txt_listado">{name}</span>
                                            </div>
                                            <div className="col-md-1">
                                                <Button variant="contained" color="error" onClick={() => deleteFile(id)} disabled={btnDisabled}><FontAwesomeIcon icon={faTrash} className="icono_trash"  /></Button>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </Container>
                    <MyDropzone />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Container className="mb-5">
                        <div className='row'>
                            <div className='col-md-12 text-right'>
                                
                               
                                {
                                (showBtnNewTarea === "original")
                                ?
                                <ButtonNewTarea />
                                :
                                (showBtnNewTarea === "abierto")
                                    ?
                                    <ButtonCloseNewTarea />
                                    :
                                    <></>
                                }

                            </div> 
                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-right'>
                            {
                                (showBtnEditTarea === "original")
                                ?
                                <ButtonEditTarea />
                                :
                                (showBtnEditTarea === "abierto")
                                    ?
                                    <ButtonCloseEditTarea />
                                    :
                                    <></>
                                }
                            </div> 
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                            { newTarea ? NuevaTarea() : null }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                            { editTarea ? EditarTarea() : null }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {showListadoTareasMain ? ListadoTareasMain() : null }
                            </div>
                            
                        </div>
                    </Container>
                </TabPanel>
                
            </Box>
          )
    }

    ///----------------------[  main return  ]---------------------------
      
     if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized){
        return(<>
                <Header />

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            La información se guardó exitosamente.
                        </Alert>
                    </Snackbar>

                <Container className="mr-inf">
                    {/* Original desktop */}
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="titulo_login">Proyectos</div> 
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <h1 className="hello">
                                <select className="form-control buscador-select mt-4" value={idBusqueda || ""} onChange={e => onChangeCliente(e.target.value)}>
                                        <option value="">Filtrar por cliente...</option>
                                        {
                                        listadoClientes.map(post => {
                                            const { id, nombre_empresa } = post;
                                            
                                            return (
                                                <option key={id} value={id}>{nombre_empresa}</option>
                                            );
                                            
                                            })
                                        }
                                </select>
                            </h1>
                        </div>
                        <div className="col-md-2 col-lg-2">
                            <IconButton aria-label="delete" className="mt-2 float-right" color="error" size="large" onClick={() => openModalNew()}>
                                <AddCircle fontSize="inherit" className="new-icon"  />
                                <h1 className="add-new ml-2 mt-2">Agregar</h1>
                            </IconButton>
                        </div>
                    </div>

                  
                    <div className="row mt-1">
                        <div className="col-md-12">
                            <div className="areas_datagrid">
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>
            </Container>

            <Modal size="lg" show={visible.visible} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">¿Estas seguro que deseas eliminarlo?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrue(visible.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalse()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="xl" show={visible2} onHide={handleDeleteFalse2} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Editar proyecto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { TabsComponent() }
                </Modal.Body>
            </Modal>



            <Modal size="lg" show={visible3} onHide={handleDeleteFalse3} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Agregar proyecto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="row mt-2">
					    <div className="col-md-3">
						    <span className="txt_listado">Nombre proyecto</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_nombre_proyecto" name="nombre_proyecto" defaultValue={nombreProyecto || ''} onChange={OnChangeNombreProyecto} required="required" /></span>
						    <span className="red_alert">{ errorNombreProyecto }</span>
					    </div>
				    </div>

                    <div className="row mt-3">
					    <div className="col-md-3">
						    <span className="txt_listado">Descripción</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_descripcion" name="descripcion" defaultValue={descripcion || ''} onChange={OnChangeDescripcion} required="required" /></span>
						    <span className="red_alert">{ errorDescripcion }</span>
					    </div>
				    </div>

				    <div className="row mt-3">
					    <div className="col-md-3">
						    <span className="txt_listado">Fecha de inicio</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="date" className="form-control" id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={fechaIngreso || ''}  onChange={OnChangeFechaIngreso} required="required" /></span>
						    <span className="red_alert">{ errorFechaIngreso }</span>
					    </div>
				    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <span className="txt_listado">Cliente</span>
                        </div>
                        <div className="col-md-9">
                            <span>
                                <select className="form-control" id="inp_id_cliente" name="id_cliente" value={idCliente} onChange={OnChangeIdCliente} required="required">
                                    <option value="">Seleccione...</option>
                                    {
                                        listadoClientes.map(post => {
                                            const { id, nombre_empresa } = post;
                                            
                                            return (
                                                <option key={id} value={id}>{nombre_empresa}</option>
                                            );
                                            
                                            })
                                    }
                                </select>
                            </span>
                            <span className="red_alert">{ errorStatus }</span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <span className="txt_listado">Status</span>
                        </div>
                        <div className="col-md-9">
                            <span>
                                <select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
                                    <option value="">Seleccione...</option>
                                    <option value="1">Activo</option>
                                    <option value="2">Inactivo</option>
                                    <option value="3">Suspendido</option>
                                    <option value="4">Finalizado</option>
                                </select>
                            </span>
                            <span className="red_alert">{ errorStatus }</span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <span className="txt_listado">Asignar roles</span>
                        </div>
                        <div className="col-md-9">
                        <div className='row'>
                            <div className='col-md-5 col-12'>
                            {/* <div>{`value: ${value2 !== null ? `'${value2}'` : 'null'}`}</div>
                            <div>{`inputValue: '${inputValue}'`}</div>
                            <br /> */}
                            <Autocomplete
                                className="movil-autocomp"
                                onChange={(event, newValue) => {
                                    //console.log(newValue)
                                    setValueEmpleado(newValue);
                                    setActiveEmp(false)
                                }}
                                value={valueEmpleado || null}
                                options={listadoEmpleados}
                                sx={{ width: 225 }}
                                renderInput={(params) => <TextField {...params} label="Empleado" />}
                            />
                                            
                            </div>
                            <div className='col-md-5 col-12'>

                                <Autocomplete
                                    className="movil-autocomp"
                                    disabled={activeEmp}
                                    onChange={(event, newValue) => {
                                        setValuePuesto(newValue);
                                        setDisabledSave(false)
                                    }}
                                    value={valuePuesto || null}
                                    options={puestos}
                                    sx={{ width: 225 }}
                                    renderInput={(params) => <TextField {...params} label="Puesto" />}
                                />
                            </div>
                            <div className='col-md-2 col-12 text-center'>
                                <button disabled={disabledSave} className='btn-save' onClick={() => SaveElement()} ><CheckCircleIcon className='save-icon' /></button>
                            </div>
                            </div>
                            
                            <br />
                            
                            <Table striped bordered hover className='table-movil'>
                                <thead>
                                    <tr>
                                    <th>Nombre</th>
                                    <th>Posición</th>
                                    <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                            {
                                roles.map( (item, index) => {
                                    
                                    return (
                                        <tr key={index}>
                                            <td>{item[0]}</td>
                                            <td>{item[1]}</td>
                                            <td><Button onClick={() => DeleteElement(index)}  className='btn  delete_rol' startIcon={<DeleteIcon />} /></td>
                                        </tr>
                                        )
                                })
                            }
                                </tbody>
                            </Table>
                                <div className="row">
                                <div className='col-md-12 text-center' style={{marginTop:20}}>
                                    
                                    </div>
                                </div>
                            
                            
                        </div>
                    </div>


                <div className="row mt-3 mb-5">
					<div className="col-md-3">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-9">
						<span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={() => handleSubmitNew()}  /></span>
					</div>
				</div>
                    </Container>
                </Modal.Body>
            </Modal>

        </>)
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default Proyectos;