import 'bootstrap/dist/css/bootstrap.min.css'
import React, {useState} from 'react';
//import {Redirect} from 'react-router-dom';
//import {PostData} from './services/PostData';
import './css/Login.css';
import './css/bienvenido.css';
// import Header from './header';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';

const Forgot = () =>{

    const [email, setEmail]                             = useState("");
    //const [password, setPassword]                       = useState("");
    const [errorCredenciales, setErrorCredenciales]     = useState();
    const [textoCredenciales, setTextoCredenciales]     = useState();
    //const [redirectTrue, setRedirectTrue]               = useState();
    const [showLoading, setShowLoading]                 = useState({display: "none"});

    const LoadingIndicator = props => {
        const { promiseInProgress } = usePromiseTracker();
            return promiseInProgress && 
                <div className="centerDots text-center" >
                      <Loader type="ThreeDots"  color="#0f44d6" height="100" width="100" />
                      <h3 style={{color: "#FFF", marginTop: "-20px"}}>Loading...</h3>
                </div>        
    };

    const OnChangeEmail = (e) =>{
        setEmail(e.target.value);        
    }

    const handleSubmit = () => {
        
        if(email){
                
                setShowLoading({display: "block"});
                const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000
                });
    
                trackPromise(
    
                    instance.post('/forgot',{
                        email: email
                    })
                    .then(response => {
                        
                        setShowLoading({display: "none"});
                        if(response.data.data.status === 200){
                            setTextoCredenciales("Gracias, te enviamos por correo el link para restablecer tu contraseña. ");
                            setErrorCredenciales("");
                            setEmail("");
                        }
                        else{
                            setErrorCredenciales("Error, email no válido.");
                            setTextoCredenciales("")
                            setEmail("");
                        }
                        
                    })
                    .catch(e => {
                        setErrorCredenciales("Error en credenciales, por favor verifique la información 🙏");
                        setShowLoading({display: "none"});
                    })
    
                );
                    
        }
        else{
            alert("Error.")
        }


    }

    document.body.style = 'background: #1C2833 !important;';
	return (
        <>
        <div className="loading-div text-center" style={showLoading}>
            <LoadingIndicator/> 
        </div>
		{/* <Header /> */}
		<Container>
            <div className="row justify-content-center mt-4" >
                <div className="col-md-6 MainLogin">
                    <img alt="Logo" src='logo.png' className="logo_bienvenido" />
                    <h1 className="titulo_login_new text-center">Bienvenido a tu empresa digital</h1>
                    <h3 className="error-credenciales mb-3 mt-5 text-center">{errorCredenciales} {textoCredenciales}</h3>
                    
                    {
                        !textoCredenciales &&
                        <>
                            <h1 className="titulo_login_new text-center mt-5">¿Olvidaste tu contraseña?</h1>
                            <Form.Group className="mt-4" controlId="formBasicEmail">
                                <Form.Label className="label_login">Email</Form.Label>
                                <Form.Control type="text" name="email" placeholder="Email" value={email} onChange={OnChangeEmail} />
                            </Form.Group>
                            <Button className="btn float-right form-control2"  type="submit" onClick={() => handleSubmit()} >
                                Enviar
                            </Button>
                        </>
                    }
                    
                    <div className="row text-center" style={{ marginBottom: "0px" }}>
                        <div className="col-md-12" style={{ marginBottom: "0px" }}>
                            <a href="/login"><p className="mt-5 text-center forgot-link">Regresar al login</p></a>
                        </div>
                    </div>
                </div>
            </div>
		</Container>
        </>
	);

}

export default Forgot;