import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Login from './login'
import Forgot from './forgot'
import Restore from './restore'
import Empleados from './empleados'
import MiEmpresa from './mi-empresa'
import AddEmployee from './add-employee'
import AddUser from './add-user'
import AddEmpresa from './add-empresa'
import MiCuenta from './mi-cuenta'
import Clientes from './clientes'
import Proyectos from './proyectos2'
import Catalogo from './catalogo'
import Parrillas from './parrillas'
import CreateParrilla from './create-parrilla'
import GetParrilla from './get-parrilla'
import MisVacaciones from './mis-vacaciones'
import Vacaciones from './vacaciones'
import RendimientoHoras from './rendimiento-horas-2'
import ListadoTareas from './mis-tareas'
import Welcome from './welcome'
import Home from './home'
import Somos from './somos'
import Logout from './logout'
import Footer from './footer'
import './css/General.css';

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot">
            <Forgot />
          </Route>
          <Route path="/restore/:email/:hash">
            <Restore />
          </Route>
          <Route path="/add-user">
            <AddUser />
          </Route>
          <Route path="/add-empresa">
            <AddEmpresa />
          </Route>
          <Route path="/empleados">
            <Empleados />
          </Route>
          <Route path="/add-employee">
            <AddEmployee />
          </Route>
          <Route path="/mi-cuenta">
            <MiCuenta />
          </Route>
          <Route path="/mi-empresa">
            <MiEmpresa />
          </Route>
          <Route path="/clientes">
            <Clientes />
          </Route>
          <Route path="/proyectos/">
            <Proyectos />
          </Route>
          <Route path="/catalogo/">
            <Catalogo />
          </Route>
          <Route path="/parrillas/">
            <Parrillas />
          </Route>
          <Route path="/create-parrilla/:id_cliente">
            <CreateParrilla />
          </Route>
          <Route path="/get-parrilla/:id_parrilla">
            <GetParrilla />
          </Route>
          <Route path="/mis-vacaciones/">
            <MisVacaciones />
          </Route>
          <Route path="/rendimiento-horas/">
            <RendimientoHoras />
          </Route>
          <Route path="/mis-tareas">
            <ListadoTareas />
          </Route>
          <Route path="/vacaciones/">
            <Vacaciones />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/welcome">
            <Welcome />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/somos">
            <Somos />
          </Route>
          <Redirect from="/" to="/logout"/>
        </Switch>
        <Footer /> 
      </div>
    </Router>
  );
}