import 'bootstrap/dist/css/bootstrap.min.css'
import React, {Component} from 'react';
import './css/Somos.css';
import Header from './header';
import  { Redirect } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import axios from 'axios';
//import Alert from '@material-ui/lab/Alert';
//import { Alert } from '@mui/material';
//import Button from '@material-ui/core/Button';
//import { useParams } from "react-router";
import { Alert } from '@mui/material';

class AddEmployee extends Component {

constructor(){
	super();
	this.state = {
		id_cuenta: '',
		nombres: '',
		apellido_paterno: '',
		apellido_materno: '',
		email: '',
		celular: '',
		puesto: '',
		tipo_sangre: '',
		fecha_nacimiento: '',
		fecha_ingreso: '',
		tel_emergencia: '',
		RFC: '',
		password: '',
		jerarquia: '',
		redirectToReferrer: false,
		windowState: false,
		errorNombre: '',
		errorPaterno: '',
		errorMaterno: '',
		errorEmail: '',
		errorCelular: '',
		redirectClientes: false
	};
	
	this.enviarForm 	= this.enviarForm.bind(this);
	this.onChange 		= this.onChange.bind(this);
	this.changeStatus 	= this.changeStatus.bind(this);
	this.clearForm 		= this.clearForm.bind(this);
}


async enviarForm() {

	let erroresForm = false;

	/* **************************    VALIDACIONES    ****************************** */

	
	//-------------------[		validate inp_nombres	]----------------
	//if(this.state.nombres.match(/^[a-zA-Z]+$/) == null){
	if(this.state.nombres.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u) == null){
		//console.log("Error: solo letras");
		this.setState( { errorNombre: "Error: solo letras" } );
		erroresForm = true;
	}
	else{
		this.setState( { errorNombre: "" } );
	}

	//-------------------[		validate inp_apellido_paterno	]----------------
	//if(this.state.apellido_paterno.match(/^[a-zA-Z]+$/) == null){
	if(this.state.apellido_paterno.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u) == null){
		//console.log("Error: solo letras");
		this.setState( { errorPaterno: "Error: solo letras" } );
		erroresForm = true;
	}
	else{
		this.setState( { errorPaterno: "" } );
	}

	//-------------------[		validate inp_apellido_materno	]----------------
	//if(this.state.apellido_materno.match(/^[a-zA-Z]+$/) == null){
	if(this.state.apellido_materno.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u) == null){
		//console.log("Error: solo letras");
		this.setState( { errorMaterno: "Error: solo letras" } );
		erroresForm = true;
	}
	else{
		this.setState( { errorMaterno: "" } );
	}


	//-------------------[				celular 			]----------------

	if(this.state.celular.match(/^[0-9]+$/) == null){
		//console.log("Error: solo letras");
		this.setState( { errorCelular: "Error: solo números" } );
		erroresForm = true;
	}
	else{
		this.setState( { errorCelular: "" } );
	}

	//-------------------[		validate email pt 1		]----------------
	if(!this.state.email){
       this.setState( { errorEmail: "Error: email vacío" } );
       erroresForm = true;
    }
    else{
    	this.setState( { errorEmail: "" } );
    }

    //-------------------[		validate email pt 2		]----------------
    if(typeof this.state.email !== "undefined"){
       let lastAtPos = this.state.email.lastIndexOf('@');
       let lastDotPos = this.state.email.lastIndexOf('.');

       if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
          this.setState( { errorEmail: "Error: email no válido" } );
          erroresForm = true;
        }
        else{
        	this.setState( { errorEmail: "" } );
        }
   }  


   /* **************************  END VALIDACIONES  ****************************** */
   
    

	//if( this.state.nombres && this.state.apellido_paterno && this.state.apellido_materno && this.state.email && this.state.celular && this.state.puesto && this.state.tipo_sangre && this.state.fecha_nacimiento && this.state.tel_emergencia && this.state.RFC && this.state.password ){
	if(!erroresForm){

		let ud       			= sessionStorage.getItem('userData');
  		let userData 			= JSON.parse(ud);
		
		try {


			const instance = axios.create({
			baseURL: global.config.server_endpoint.url,
			timeout: 8000,
			headers: {'Authorization': 'Bearer '+userData.token}
			});

			instance.post('/empleados', { 
					nombres: this.state.nombres, 
					apellido_paterno: this.state.apellido_paterno, 
					apellido_materno: this.state.apellido_materno, 
					email: this.state.email, 
					celular: this.state.celular, 
					puesto: this.state.puesto, 
					tipo_sangre: this.state.tipo_sangre, 
					fecha_nacimiento: this.state.fecha_nacimiento, 
					tel_emergencia: this.state.tel_emergencia, 
					RFC: this.state.RFC, 
					fecha_ingreso: this.state.fecha_ingreso, 
					password: this.state.password, 
					c_password: this.state.password2
			    })
			.then(response => {
			
				console.log(response);
				if(response.status === 200){
					alert("Agregado exitosamente");
					//this.clearForm();
					this.setState( { redirectClientes: true } );
				}
				else{
					alert("error");
				}
				
				
			
			})
			.catch(error => this.setState({ error, isLoading: false }));

  
		} catch (e) {
  			console.log("Email repetido.");
  			
  			//console.log(r)
			console.log(this.state);
		}

	}
	else{
		console.log("Error: Información incompleta");
	}
}


onChange(e){
	this.setState({[e.target.name]:e.target.value});
}

clearForm(e){
	this.setState({ id_empresa: '' });
	this.setState({ nombres: '' });
	this.setState({ apellido_paterno: '' });
	this.setState({ apellido_materno: '' });
	this.setState({ email: '' });
	this.setState({ celular: '' });
	this.setState({ puesto: '' });
	this.setState({ tipo_sangre: '' });
	this.setState({ fecha_nacimiento: '' });
	this.setState({ fecha_ingreso: '' });
	this.setState({ tel_emergencia: '' });
	this.setState({ RFC: '' });
	this.setState({ password: '' });
	this.setState({ password2: '' });
	this.setState({ jerarquia: '' });

}


async changeStatus(){
	this.setState({"windowState": false});
}

render() {


	if(this.state.redirectClientes !== true){

	var loginbu;

	if(this.state.windowState){
		loginbu = <Alert show={this.state.windowState} onClose={ this.changeStatus }>Se guardo exitosamente el empleado</Alert>;
	}
	else{
		loginbu = "";
	}




	if(sessionStorage.getItem('userData') != null){
	return (
		<>
		<Header />
		<Container className="mb-5">
			<h1 className="titulo_login mt-4">Agregar empleado</h1>

			{loginbu}
			
			<div className="row mt-4">
				<div className="col-md-2">
					<span>Nombre(s)</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" className="form-control" id="inp_nombres" name="nombres" value={this.state.nombres} onChange={this.onChange} required="required" /></span>
					<span className="red_alert">{ this.state.errorNombre }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Apellido Paterno</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" className="form-control" id="inp_apellido_paterno" name="apellido_paterno" value={this.state.apellido_paterno} onChange={this.onChange} required="required" /></span>
					<span className="red_alert">{ this.state.errorPaterno }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Apellido Materno</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" className="form-control" id="inp_apellido_materno" name="apellido_materno" value={this.state.apellido_materno} onChange={this.onChange} required="required" /></span>
					<span className="red_alert">{ this.state.errorMaterno }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Email</span>
				</div>
				<div className="col-md-10">
					<span><input type="email" className="form-control" id="inp_email" name="email" value={this.state.email} onChange={this.onChange} required="required" /></span>
					<span className="red_alert">{ this.state.errorEmail }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Celular</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" maxLength="10" className="form-control" id="inp_celular" name="celular" value={this.state.celular} onChange={this.onChange} required="required" /></span>
					<span className="red_alert">{ this.state.errorCelular }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Puesto</span>
				</div>
				<div className="col-md-10">
					<span><input type="text"className="form-control" id="inp_puesto" name="puesto" value={this.state.puesto} onChange={this.onChange} required="required" /></span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Tipo sangre</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" maxLength="2" className="form-control" id="inp_tipo_sangre" name="tipo_sangre" value={this.state.tipo_sangre} onChange={this.onChange} required="required" /></span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Fecha nacimiento</span>
				</div>
				<div className="col-md-10">
					<span><input type="date" className="form-control" id="inp_fecha_nacimiento" name="fecha_nacimiento" value={this.state.fecha_nacimiento} onChange={this.onChange} required="required" /></span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Teléfono emergencia</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" className="form-control" id="inp_tel_emergencia" name="tel_emergencia" value={this.state.tel_emergencia} onChange={this.onChange} required="required" /></span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>RFC</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" className="form-control" id="inp_RFC" name="RFC" value={this.state.RFC} onChange={this.onChange} required="required" /></span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Fecha ingreso</span>
				</div>
				<div className="col-md-10">
					<span><input type="date" className="form-control" id="inp_fecha_ingreso" name="fecha_ingreso" value={this.state.fecha_ingreso} onChange={this.onChange} required="required" /></span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Password</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" className="form-control" id="inp_password" name="password" value={this.state.password} onChange={this.onChange} required="required" /></span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Password</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" className="form-control" id="inp_password2" name="password2" value={this.state.password2} onChange={this.onChange} required="required" /></span>
				</div>
			</div>

			<div className="row mt-3 mb-5">
				<div className="col-md-2">
					<span>&nbsp;</span>
				</div>
				<div className="col-md-10">
					<span><input type="submit" value="Guardar" className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={this.enviarForm}  /></span>
				</div>
			</div>

			{ /* espacio de seguridad */ }
			<div className="row mb-2">
				<div className="col-md-2">
					<span>&nbsp;</span>
				</div>
				<div className="col-md-10">
					<span>&nbsp;</span>
				</div>
			</div>

		</Container>
		</>)
	}
	else{
		return (<>
				<Header />
			<h1>Por favor inicie sesión</h1>
			<p><a href={'/login'}>Log in</a></p>
		</>);
	}


	}
	else{
		return <Redirect to="/empleados"  />;
	}	




}

}
export default AddEmployee;