import 'bootstrap/dist/css/bootstrap.min.css'
import React, {useState, useEffect, useRef } from 'react';
import './css/Login.css';
import './css/calendario.css';
import Header from './header';
import moment from "moment";
import DatePicker, {registerLocale} from "react-datepicker";
import  { Redirect } from 'react-router-dom'
//import {  faPlus } from "@fortawesome/free-solid-svg-icons"; //faHome,
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { trackPromise } from 'react-promise-tracker';
//------------
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
//----------
import { DataGrid } from '@mui/x-data-grid';
import Button  from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
//import EditIcon from '@mui/icons-material/Edit';
//import AddCircle from '@mui/icons-material/AddCircle';
//import IconButton from '@mui/material/IconButton';
import { Bar, defaults } from 'react-chartjs-2';
//----------
import es from 'date-fns/locale/es';
registerLocale('es', es);
//----------
defaults.animation = false;

///
//---------------------------------------------------------------
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
//---------------------------------------------------------------



  const RendimientoHoras = () =>{

          let ud       = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);

          // hook para funcionar
          const [posts, setPosts]                 = useState([]);
          const [posts2, setPosts2]               = useState([]);
          const [posts3, setPosts3]               = useState([]);
          const [proyectos, setProyectos]         = useState(null);
          const [empleados, setEmpleados]         = useState(null);
          const [clientes, setClientes]           = useState(null);
          const [areas, setAreas]                 = useState(null);
          const [loading2, setLoading2]           = useState(true);
          const [visible, setVisible]             = useState(false);
          const [willDelete, setWillDelete]       = useState(false);
          const [isAuthorized, setIsAuthorized]   = useState(true);
          //const [visible, setVisible]           = useState(null);
          const [inputCliente, setInputCliente]   = useState("");
          const [inputArea, setInputArea]         = useState("");
          const [inputEmpleado, setInputEmpleado] = useState("");
          const [value, setValue]                 = useState(0);
          // ------------------- data de los filtros ------------------
          const [checkInDate, setCheckInDate] = useState(null);
          const [checkOutDate, setCheckOutDate] = useState(null);
          const [checkInDateArea, setCheckInDateArea] = useState(null);
          const [checkOutDateArea, setCheckOutDateArea] = useState(null);
          const [checkInDateEmpleado, setCheckInDateEmpleado] = useState(null);
          const [checkOutDateEmpleado, setCheckOutDateEmpleado] = useState(null);
          // ------------------ end data de los filtros --------------------

          //-------------------- nuevo data grid -------------------
          const [loading, setLoading]                     = useState(false);
          //const [page, setPage]                           = useState(0);
          const [selectionModel, setSelectionModel]       = useState([]);
          const prevSelectionModel                        = useRef(selectionModel);
          const [totalRows, setTotalRows]                 = useState(0);
          const [totalRows2, setTotalRows2]               = useState(0);
          const [totalRows3, setTotalRows3]               = useState(0);
          //-------
          const [pageEmpleado, setPageEmpleado]           = useState(0);
          const [pageArea, setPageArea]                   = useState(0);
          
          //------------------------- graficas -----------------------
          const [dataOk, setDataOk] 			                = useState(null);
	        const [labelsOk, setLabelsOk] 		              = useState(null);
          const [dataOkArea, setDataOkArea] 			        = useState(null);
	        const [labelsOkArea, setLabelsOkArea] 		      = useState(null);
          const [dataOkEmpleado, setDataOkEmpleado] 			= useState(null);
	        const [labelsOkEmpleado, setLabelsOkEmpleado]   = useState(null);
          
          const handleCheckInDate = (date) => {
            setCheckInDate(date);
            setCheckOutDate(null);
          };

          const handleCheckInDateArea = (date) => {
            setCheckInDateArea(date);
            setCheckOutDateArea(null);
          };

          const handleCheckInDateEmpleado = (date) => {
            setCheckInDateEmpleado(date);
            setCheckOutDateEmpleado(null);
          };

          const handleCheckOutDate = (date) => {
              setCheckOutDate(date);
          };

          const handleCheckOutDateArea = (date) => {
            setCheckOutDateArea(date);
          };

          const handleCheckOutDateEmpleado = (date) => {
            setCheckOutDateEmpleado(date);
          };

          //useEffect(async () => {
          useEffect(() => {

            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
            const retrieveData = () => {

                trackPromise(

                  instance.get('/get-clientes')
                  .then(response => {
                    setIsAuthorized(true);
                    setClientes(response.data.data);
                  })
                  .catch(e => {
                    setIsAuthorized(false);
                    console.log(e);
                  })

                );
          
                //......

                trackPromise(

                  instance.get('/maestro-cliente')
                  .then(response => {
                      setPosts(response.data.data.data);
                      setTotalRows(response.data.data.total);
                  })
                  .catch(e => {
                    console.log(e);
                  })

                );

                //......

                trackPromise(

                  instance.get('/maestro-area')
                  .then(response => {
                      setPosts2(response.data.data.data);
                      setTotalRows2(response.data.data.total);
                  })
                  .catch(e => {
                    console.log(e);
                  })

                );

                //......

                trackPromise(

                  instance.get('/maestro-empleado')
                  .then(response => {
                      setPosts3(response.data.data.data);
                      setTotalRows3(response.data.data.total);
                  })
                  .catch(e => {
                    console.log(e);
                  })

                );


                //......

                trackPromise(

                  instance.get('/empleados/all')
                  .then(response => {
                    //console.log(response.data.data)
                    setEmpleados(response.data.data);
                  })
                  .catch(e => {
                    console.log(e);
                  })

                );

                //......

                trackPromise(

                  instance.get('/all-proyectos')
                  .then(response => {
                    setProyectos(response.data.data);
                  })
                  .catch(e => {
                    console.log(e);
                  })

                );

                //......

                trackPromise(

                  instance.get('/areas-all')
                  .then(response => {
                    //console.log(response.data.data);
                    setAreas(response.data.data);
                  })
                  .catch(e => {
                    console.log(e);
                  })

                );

                //......

                trackPromise(
                  instance.get('/get-graph-marca')
                  .then(response => {
                    setDataOk(response.data.data[0].data);
                    setLabelsOk(response.data.data[1].labels);
                    
                  })
                  .catch(error => console.log(error))   
                );

                //......

                trackPromise(
                  instance.get('/get-graph-area')
                  .then(response => {
                    setDataOkArea(response.data.data[0].data);
                    setLabelsOkArea(response.data.data[1].labels);
                    
                  })
                  .catch(error => console.log(error))   
                );

                //......

                trackPromise(
                  instance.get('/get-graph-empleado')
                  .then(response => {
                    setDataOkEmpleado(response.data.data[0].data);
                    setLabelsOkEmpleado(response.data.data[1].labels);
                    
                  })
                  .catch(error => console.log(error))   
                );
          
      
            };

            retrieveData();
            setTimeout(() => setLoading2(false), 1000)

          },[]);

          //-------------- data para gráficas --------------------
	
          const datax = {
            labels: labelsOk,
              datasets: [
                {
                  label: '# horas por proyecto',
                  data: dataOk,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                  ],
                  borderWidth: 2,
                },
              ],
            };

          const optionsx = {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          redraw: false,
          };

          const datax2 = {
            labels: labelsOkArea,
              datasets: [
                {
                  label: '# horas por área',
                  data: dataOkArea,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                  ],
                  borderWidth: 2,
                },
              ],
            };

          const optionsx2 = {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          redraw: false,
          };

          const datax3 = {
            labels: labelsOkEmpleado,
              datasets: [
                {
                  label: '# horas por área',
                  data: dataOkEmpleado,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                  ],
                  borderWidth: 2,
                },
              ],
            };

          const optionsx3 = {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          redraw: false,
          };

        //-------------- end data para gráficas --------------------

          const handleChange2 = (e) => {
            setInputCliente(e.target.value);
          };

          const handleChange22 = (e) => {
            setInputArea(e.target.value);
          };

          const handleChange23 = (e) => {
            setInputEmpleado(e.target.value);
          };

          const handleChange = (event, newValue) => {
              setValue(newValue);
          };

          const handleSubmit = () => {
            setPosts([]);
            //console.log(moment(checkInDate).format("YYYY-MM-DD")+" - "+moment(checkOutDate).format("YYYY-MM-DD")+" - "+inputCliente);

            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
              baseURL: global.config.server_endpoint.url,
              timeout: 10000,
              headers: {'Authorization': 'Bearer '+userData.token}
            });

            const f1 = moment(checkInDate).format("YYYY-MM-DD");
            const f2 = moment(checkOutDate).format("YYYY-MM-DD");

            ///------- new -------
            let ApiCallPage     = null;
            let ApiCallPageG    = null;

            if(f1 !== "Invalid date" && f2 !== "Invalid date"){
                if(inputCliente){
                    ApiCallPage       = '/maestro-cliente/'+inputCliente+'/'+f1+'/'+f2;
                    ApiCallPageG      = '/get-graph-marca-cliente/'+inputCliente+'/'+f1+'/'+f2;
                }
                else{
                    ApiCallPage       = '/maestro-cliente/null/'+f1+'/'+f2;
                    ApiCallPageG      = '/get-graph-marca/'+f1+'/'+f2;
                }
            }
            else{
                if(inputCliente){
                    ApiCallPage       = '/maestro-cliente/'+inputCliente;
                    ApiCallPageG      ='/get-graph-marca-cliente/'+inputCliente
                }
                else{
                    ApiCallPage       = '/maestro-cliente';  
                    ApiCallPageG      = '/get-graph-marca';
                }
            }
            
            trackPromise(
              instance.get(ApiCallPage)
              .then(response => {
                  setPosts(response.data.data.data);
                  setTotalRows(response.data.data.total);
                })
                .catch(e => {
                  console.log(e);
                })
            );

            trackPromise(
                    instance.get(ApiCallPageG)
                    .then(response => {
                      setDataOk(response.data.data[0].data);
                      setLabelsOk(response.data.data[1].labels);
                    })
                    .catch(error => console.log(error))   
            );

           
          }

          const handleSubmit2 = () => {

              setPosts2([]);
              setTotalRows2(0);
              setPageArea(0);

              let ud       = sessionStorage.getItem('userData');
              let userData = JSON.parse(ud);
  
              const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
              });
  
              const f1 = moment(checkInDateArea).format("YYYY-MM-DD");
              const f2 = moment(checkOutDateArea).format("YYYY-MM-DD");

              ///------- new -------
              let ApiCallPage     = null;
              let ApiCallPageG    = null;

              if(f1 !== "Invalid date" && f2 !== "Invalid date"){
                  if(inputArea){
                      ApiCallPage       = '/maestro-area/'+inputArea+'/'+f1+'/'+f2;
                      ApiCallPageG      = '/get-graph-marca-area/'+inputArea+'/'+f1+'/'+f2;
                  }
                  else{
                      ApiCallPage       = '/maestro-area/null/'+f1+'/'+f2;
                      ApiCallPageG      = '/get-graph-area/'+f1+'/'+f2;
                  }
              }
              else{
                  if(inputArea){
                      ApiCallPage       = '/maestro-area/'+inputArea;
                      ApiCallPageG      ='/get-graph-marca-area/'+inputArea
                  }
                  else{
                      ApiCallPage       = '/maestro-area';  
                      ApiCallPageG      = '/get-graph-area';
                  }
              }

              trackPromise(
                instance.get(ApiCallPage)
                .then(response => {
                    setPosts2(response.data.data.data);
                    setTotalRows2(response.data.data.total);
                  })
                  .catch(e => {
                    console.log(e);
                  })
              );

              trackPromise(
                      instance.get(ApiCallPageG)
                      .then(response => {
                        setDataOkArea(response.data.data[0].data);
                        setLabelsOkArea(response.data.data[1].labels);
                      })
                      .catch(error => console.log(error))   
              );
              
          }

          const handleSubmit3 = () => {
            
            setPosts3([]);
            setTotalRows3(0);
            setPageEmpleado(0);
            //alert(page);
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
              baseURL: global.config.server_endpoint.url,
              timeout: 10000,
              headers: {'Authorization': 'Bearer '+userData.token}
            });

            const f1 = moment(checkInDateEmpleado).format("YYYY-MM-DD");
            const f2 = moment(checkOutDateEmpleado).format("YYYY-MM-DD");

            ///------- new -------
            let ApiCallPage     = null;
            let ApiCallPageG    = null;

            if(f1 !== "Invalid date" && f2 !== "Invalid date"){
                if(inputEmpleado){
                    ApiCallPage       = '/maestro-empleado/'+inputEmpleado+'/'+f1+'/'+f2;
                    ApiCallPageG      = '/get-graph-marca-empleado/'+inputEmpleado+'/'+f1+'/'+f2;
                }
                else{
                    ApiCallPage       = '/maestro-empleado/null/'+f1+'/'+f2;
                    ApiCallPageG      = '/get-graph-empleado/'+f1+'/'+f2;
                }
            }
            else{
                if(inputEmpleado){
                    ApiCallPage       = '/maestro-empleado/'+inputEmpleado;
                    ApiCallPageG      ='/get-graph-marca-empleado/'+inputEmpleado
                }
                else{
                    ApiCallPage       = '/maestro-empleado';  
                    ApiCallPageG      = '/get-graph-empleado';
                }
            }

            trackPromise(
              instance.get(ApiCallPage)
              .then(response => {
                  setPosts3(response.data.data.data);
                  setTotalRows3(response.data.data.total);
                })
                .catch(e => {
                  console.log(e);
                })
            );

            trackPromise(
                    instance.get(ApiCallPageG)
                    .then(response => {
                      setDataOkEmpleado(response.data.data[0].data);
                      setLabelsOkEmpleado(response.data.data[1].labels);
                    })
                    .catch(error => console.log(error))   
            );
            
        }
  
          const updateHoras = () => {
          setPosts([]);
          let ud       = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
  
          const instance = axios.create({
              baseURL: global.config.server_endpoint.url,
              timeout: 10000,
              headers: {'Authorization': 'Bearer '+userData.token}
          });
  
          trackPromise(
  
              instance.get('/horas')
              .then(response => {
                setIsAuthorized(true);
                setPosts(response.data.data.data);
              })
              .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
              })
  
          );
          }   

          const handleDelete = (id) => {
          setVisible(true);  
          setWillDelete(id);
          //setIdCliente(e.target.value);  
          }

          const handleDeleteTrue = (id) => {
          let ud       = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
  
          const instance = axios.create({
              baseURL: global.config.server_endpoint.url,
              timeout: 10000,
              headers: {'Authorization': 'Bearer '+userData.token}
          });
  
          trackPromise(
              instance.delete('/horas/'+id)
              .then(response => {
                  if(response.status === 200){
                      updateHoras();
                      setVisible(false);
                  }
              })
              .catch(e => {
                  console.log(e);
              })
          );
          }
  
          const handleDeleteFalse = () => {
          setVisible(false);
          }

          const NextPage = (page) => {

            setLoading(true);
            setPosts([]);
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });

            ///------- new -------
            const f1            = moment(checkInDate).format("YYYY-MM-DD");
            const f2            = moment(checkOutDate).format("YYYY-MM-DD");
            let ApiCallPage     = null;
            //let ApiCallPageG    = null;

            if(f1 !== "Invalid date" && f2 !== "Invalid date"){
                if(inputCliente)
                    ApiCallPage       = '/maestro-cliente/'+inputCliente+'/'+f1+'/'+f2;
                else
                    ApiCallPage       = '/maestro-cliente/null/'+f1+'/'+f2;
            }
            else{
                if(inputCliente)
                    ApiCallPage       = '/maestro-cliente/'+inputCliente;
                else
                    ApiCallPage       = '/maestro-cliente';  
            }
            
            trackPromise(
              instance.get(ApiCallPage+"?page="+page)
              .then(response => {
                  setPosts(response.data.data.data);
                  setTotalRows(response.data.data.total);
                  setLoading(false);
                })
                .catch(e => {
                  console.log(e);
                })
            );

            //old -----

            // trackPromise(

            //     instance.get('/horas?page='+page)
            //     .then(response => {
            //         setPosts(response.data.data.data);
            //         setTotalRows(response.data.data.total);
            //         setLoading(false);
            //     })
            //     .catch(e => {
            //       console.log(e);
            //     })

            // );

          }

          const NextPageArea = (page) => {

            setLoading(true);
            setPosts2([]);
            setTotalRows2(0);

            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });

            //fechas
            const f1            = moment(checkInDateArea).format("YYYY-MM-DD");
            const f2            = moment(checkOutDateArea).format("YYYY-MM-DD");
            let ApiCallPage     = null;
            
            if(f1 !== "Invalid date" && f2 !== "Invalid date"){
                if(inputArea)
                    ApiCallPage   = '/maestro-area/'+inputArea+'/'+f1+'/'+f2;
                else
                    ApiCallPage   = '/maestro-area/null/'+f1+'/'+f2;
            }
            else{
                if(inputArea)
                    ApiCallPage   = '/maestro-area/'+inputArea;
                else
                    ApiCallPage   = '/maestro-area';  
            }

            trackPromise(
              instance.get(ApiCallPage+"?page="+page)
              .then(response => {
                  setPosts2(response.data.data.data);
                  setTotalRows2(response.data.data.total);
                  setLoading(false);
                })
                .catch(e => {
                  console.log(e);
                })
            );
        
          }

          const NextPageEmpleado = (page) => {
            
            setLoading(true);
            setPosts3([]);
            setTotalRows3(0);

            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });

            //fechas
            const f1            = moment(checkInDateEmpleado).format("YYYY-MM-DD");
            const f2            = moment(checkOutDateEmpleado).format("YYYY-MM-DD");
            let ApiCallPage     = null;
            
            if(f1 !== "Invalid date" && f2 !== "Invalid date"){
                if(inputEmpleado)
                    ApiCallPage   = '/maestro-empleado/'+inputEmpleado+'/'+f1+'/'+f2;
                else
                    ApiCallPage   = '/maestro-empleado/null/'+f1+'/'+f2;
            }
            else{
                if(inputEmpleado)
                    ApiCallPage   = '/maestro-empleado/'+inputEmpleado;
                else
                    ApiCallPage   = '/maestro-empleado';  
            }

            trackPromise(
              instance.get(ApiCallPage+"?page="+page)
              .then(response => {
                  setPosts3(response.data.data.data);
                  setTotalRows3(response.data.data.total);
                  setLoading(false);
                })
                .catch(e => {
                  console.log(e);
                })
            );
        
          }

          

          if (loading2 !== false) {
            return <>
                  <Header />
                    <div className="centerDots text-center" >
                      <Loader type="ThreeDots"  color="#3765f6" height="100" width="100" />
                      <h1 className="loading-final">Cargando...</h1>
                    </div>
            </>
          }

          ///-------------

          const TabsComponent = () =>{
            return (
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="Por cliente" {...a11yProps(0)} />
                          <Tab label="Por área" {...a11yProps(1)} />
                          <Tab label="Por empleado" {...a11yProps(2)} />
                      </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                {/* - - - - - - [  P O R   C L I E N T E  ]- - - - - - -*/}
                    <Container className="mt-2 pt-4">
                            
                          <div className="row mb-2">
                                
                                <div className="col-md-6 col-12">
                                  <div className="div-calendar text-center">
                                    <div className="row">
                                      <div className="col-md-6 float-right mt-2">
                                        <DatePicker
                                          selected={checkInDate}
                                          // minDate={new Date()}
                                          onChange={handleCheckInDate}
                                          className="form-control inpFecha"
                                          placeholderText="Fecha inicio"
                                          dateFormat="yyyy-MM-dd"
                                          locale="es"
                                        />
                                      </div>
                                      <div className="col-md-6 float-right mt-2">
                                        <DatePicker
                                          selected={checkOutDate}
                                          minDate={checkInDate}
                                          onChange={handleCheckOutDate}
                                          className="form-control inpFecha"
                                          placeholderText="Fecha fin"
                                          dateFormat="yyyy-MM-dd"
                                          locale="es"
                                        />
                                      </div>
                                     
                                    </div>
                                    
                                  </div>
                                </div> 

                        <div className="col-md-3 col-12 mt-2">
                            <select name="id_cliente_select" id="inp_id_cliente_select" onChange={handleChange2} value={inputCliente} className="form-control select_filtro">
                                  <option key="0" value="">Ver todos los clientes</option>
                                  {
                                    clientes && 
                                    clientes.map(post => {
                                        const { id, nombre_empresa } = post;
                                          return (
                                            <option key={id} value={id}>{nombre_empresa}</option>
                                          );
                                        })
                                  }
                                  
                            </select>
                          
                        </div>
                        <div className="col-md-3 col-12 mt-2">
                          <Button variant="contained" color="success" className="form-control" id="btn_enviar_clientes"  onClick={ handleSubmit }  >
                            Filtrar
                          </Button>
                        </div>
                      </div>

                      {/* Aqui va el data grid */}
                      <div className="row mt-1">
                        <div className="col-12">
                            <div className="areas_datagrid_int">
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        //setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>
                          <h1 className="titulo_login mt-5 titulo_celular">Gráfica de horas por cliente</h1>
                          <Bar data={datax} options={optionsx} className="graph1" />
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                {/* - - - - - - [  P O R   Á R E A  ]- - - - - - -*/}
                <Container className="mt-2 pt-4">
                            
                            <div className="row mb-2">
                                <div className="col-md-6 col-12 mt-2">
                                  <div className="div-calendar text-center">
                                    <div className="row">
                                      <div className="col-md-6 float-right">
                                        <DatePicker
                                          selected={checkInDateArea}
                                          // minDate={new Date()}
                                          onChange={handleCheckInDateArea}
                                          className="form-control inpFecha"
                                          placeholderText="Fecha inicio"
                                          dateFormat="yyyy-MM-dd"
                                          locale="es"
                                        />
                                      </div>
                                      <div className="col-md-6 float-right">
                                        <DatePicker
                                          selected={checkOutDateArea}
                                          minDate={checkInDateArea}
                                          onChange={handleCheckOutDateArea}
                                          className="form-control inpFecha"
                                          placeholderText="Fecha fin"
                                          dateFormat="yyyy-MM-dd"
                                          locale="es"
                                        />
                                      </div>
                                      
                                    </div>
                                    
                                  </div>
                                </div> 

                        <div className="col-md-3 col-12 mt-2">
                            <select name="id_cliente_select" id="inp_id_cliente_select" onChange={handleChange22} value={inputArea} className="form-control select_filtro">
                                  <option key="0" value="">Ver todas las áreas</option>
                                  {
                                    areas && 
                                    areas.map(post => {
                                        const { id, nombre_area } = post;
                                          return (
                                            <option key={id} value={id}>{nombre_area}</option>
                                          );
                                        })
                                  }
                                  
                            </select>
                          
                        </div>
                        <div className="col-md-3 col-12 mt-2">
                          <Button variant="contained" color="success" className="form-control" id="btn_enviar_areas"  onClick={ handleSubmit2 }  >
                            Filtrar
                          </Button>
                        </div>
                      </div>

                      {/* Aqui va el data grid */}
                      <div className="row mt-1">
                        <div className="col-12">
                            <div className="areas_datagrid_int">
                                <DataGrid
                                    rows={rows2}
                                    columns={columns2}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows2}
                                    loading={loading}
                                    page={pageArea}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        setPageArea(newPage);
                                        NextPageArea(newPage+1);
                                    }} 
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>
                          <h1 className="titulo_login mt-5 titulo_celular">Gráfica de horas por área</h1>
                          <Bar data={datax2} options={optionsx2} className="graph1" />
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={2}>
                {/* - - - - - - [  P O R   E M P L E A D O  ]- - - - - - -*/}
                <Container className="mt-2 pt-4">
                            
                            <div className="row mb-2">
                                <div className="col-md-6 col-12 mt-2">
                                  <div className="div-calendar text-center">
                                    <div className="row">
                                      <div className="col-md-6 float-right">
                                        <DatePicker
                                          selected={checkInDateEmpleado}
                                          // minDate={new Date()}
                                          onChange={handleCheckInDateEmpleado}
                                          className="form-control inpFecha"
                                          placeholderText="Fecha inicio"
                                          dateFormat="yyyy-MM-dd"
                                          locale="es"
                                        />
                                      </div>
                                      <div className="col-md-6 float-right">
                                        <DatePicker
                                          selected={checkOutDateEmpleado}
                                          minDate={checkInDateEmpleado}
                                          onChange={handleCheckOutDateEmpleado}
                                          className="form-control inpFecha"
                                          placeholderText="Fecha fin"
                                          dateFormat="yyyy-MM-dd"
                                          locale="es"
                                        />
                                      </div>
                                      
                                    </div>
                                    
                                  </div>
                                </div> 

                        <div className="col-md-3 col-12 mt-2">
                            <select name="id_empleado_select" id="inp_id_empleado_select" onChange={handleChange23} value={inputEmpleado} className="form-control select_filtro">
                                  <option key="0" value="">Ver todos los empleados</option>
                                  {
                                    empleados && 
                                    empleados.map(post => {
                                        const { id, nombres, apellido_paterno } = post;
                                          return (
                                            <option key={id} value={id}>{nombres} {apellido_paterno}</option>
                                          );
                                        })
                                  }
                                  
                            </select>
                          
                        </div>
                        <div className="col-md-3 col-12 mt-2">
                          <Button variant="contained" color="success" className="form-control" id="btn_enviar_empleados"  onClick={ handleSubmit3 }  >
                            Filtrar
                          </Button>
                        </div>
                      </div>

                      {/* Aqui va el data grid */}
                      <div className="row mt-1">
                        <div className="col-12">
                            <div className="areas_datagrid_int">
                                <DataGrid
                                    rows={rows3}
                                    columns={columns3}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows3}
                                    loading={loading}
                                    page={pageEmpleado}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        setPageEmpleado(newPage);
                                        NextPageEmpleado(newPage+1);
                                    }} 
                                    onSelectionModelChange={(newSelectionModel) => {
                                      setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>
                          
                          <h1 className="titulo_login mt-5 titulo_celular">Gráfica de horas por empleado</h1>
                          <Bar data={datax3} options={optionsx3} className="graph1" />
                    </Container>
                </TabPanel>
                
            </Box>
          )
          }

          const columns   = [
        {
          field: 'id_cliente',
          headerName: 'Cliente',
          minWidth: 180,
          flex: .5,
          headerClassName: 'letal',
          renderCell: (params) => {
              let name_empresa;
              if(clientes){
                let found = clientes.find(element => element.id === params.row.id_cliente);
                if(found)
                  name_empresa = found.nombre_empresa;
              }
              return name_empresa 
          },
        },
        {
          field: 'nombre_proyecto',
          headerName: 'Nombre proyecto',
          minWidth: 200,
          flex: .6,
          headerClassName: 'letal',
          renderCell: (params) => {
              let name_proyecto;
              if(proyectos){
                let foundp = proyectos.find(element => element.id === params.row.id_proyecto);
                if(foundp){
                  name_proyecto = foundp.nombre_proyecto;
                }
              }
              return name_proyecto
          },
        },
        {
          field: 'cantidad',
          headerName: 'Cantidad (horas)',
          minWidth: 120,
          flex: .3,
          headerClassName: 'letal',
        },
        {
          field: 'id_empleado',
          headerName: 'Empleado',
          minWidth: 150,
          flex: .4,
          headerClassName: 'letal',
          renderCell: (params) => {
              let name_empleado;
              if(empleados){
                let foundx = empleados.find(element => element.id === params.row.id_empleado);
                if(foundx){
                  name_empleado = foundx.nombres+" "+foundx.apellido_paterno;
                }
              }
              return name_empleado
          },
        },
        {
          field: 'fecha',
          flex: 0.3,
          headerName: 'Fecha',
          headerClassName: 'letal',
          minWidth: 100,
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 80,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                  <Button
                    variant="text"
                    color="error"
                    size="large"
                    className="ml-3"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(params.id)}
                  />
                </strong>
            ),
        }
          ];
    
          const columns2  = [
        {
          field: 'id_cliente',
          headerName: 'Cliente',
          minWidth: 180,
          flex: .5,
          headerClassName: 'letal',
          renderCell: (params) => {
              let name_empresa;
              if(clientes){
                let found = clientes.find(element => element.id === params.row.id_cliente);
                if(found)
                  name_empresa = found.nombre_empresa;
              }
              return name_empresa 
          },
        },
        {
          field: 'nombre_proyecto',
          headerName: 'Nombre proyecto',
          minWidth: 200,
          flex: .6,
          headerClassName: 'letal',
          renderCell: (params) => {
              let name_proyecto;
              if(proyectos){
                let foundp = proyectos.find(element => element.id === params.row.id_proyecto);
                if(foundp){
                  name_proyecto = foundp.nombre_proyecto;
                }
              }
              return name_proyecto
          },
        },
        {
          field: 'cantidad',
          headerName: 'Cantidad (horas)',
          minWidth: 120,
          flex: .3,
          headerClassName: 'letal',
        },
        {
          field: 'nombre_area',
          headerName: 'Nombre área',
          minWidth: 120,
          flex: .3,
          headerClassName: 'letal',
          renderCell: (params) => {
            let name_area;
            if(areas){
              let found = areas.find(element => element.id === params.row.id_area);
              if(found)
                name_area = found.nombre_area;
            }
            return name_area
          },
        },
        {
          field: 'fecha',
          flex: 0.3,
          headerName: 'Fecha',
          headerClassName: 'letal',
          minWidth: 100,
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 80,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                  <Button
                    variant="text"
                    color="error"
                    size="large"
                    className="ml-3"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(params.id)}
                  />
                </strong>
            ),
        }
          ];

          const columns3   = [
        {
          field: 'id_cliente',
          headerName: 'Cliente',
          minWidth: 180,
          flex: .5,
          headerClassName: 'letal',
          renderCell: (params) => {
              let name_empresa;
              if(clientes){
                let found = clientes.find(element => element.id === params.row.id_cliente);
                if(found)
                  name_empresa = found.nombre_empresa;
              }
              return name_empresa 
          },
        },
        {
          field: 'nombre_proyecto',
          headerName: 'Nombre proyecto',
          minWidth: 200,
          flex: .6,
          headerClassName: 'letal',
          renderCell: (params) => {
              let name_proyecto;
              if(proyectos){
                let foundp = proyectos.find(element => element.id === params.row.id_proyecto);
                if(foundp){
                  name_proyecto = foundp.nombre_proyecto;
                }
              }
              return name_proyecto
          },
        },
        {
          field: 'cantidad',
          headerName: 'Cantidad (horas)',
          minWidth: 120,
          flex: .3,
          headerClassName: 'letal',
        },
        {
          field: 'id_empleado',
          headerName: 'Empleado',
          minWidth: 150,
          flex: .4,
          headerClassName: 'letal',
          renderCell: (params) => {
              let name_empleado;
              if(empleados){
                let foundx = empleados.find(element => element.id === params.row.id_empleado);
                if(foundx){
                  name_empleado = foundx.nombres+" "+foundx.apellido_paterno;
                }
              }
              return name_empleado
          },
        },
        {
          field: 'fecha',
          flex: 0.3,
          headerName: 'Fecha',
          headerClassName: 'letal',
          minWidth: 100,
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 80,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                  <Button
                    variant="text"
                    color="error"
                    size="large"
                    className="ml-3"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(params.id)}
                  />
                </strong>
            ),
        }
          ];

          const rows      = posts;
          const rows2     = posts2;
          const rows3     = posts3;

          if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized ){

          return(
              <>
              <Header />
              <Container className="mr-inf">
      
                <div className="row">
                  <div className="col-12">
                    <div className="titulo_login">Rendimiento horas</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {TabsComponent()}
                  </div>
                </div>
                

          </Container>


                <Modal size="lg" show={visible} onHide={handleDeleteFalse} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>¿Estas seguro que deseas eliminarlo?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col className="text-center">
                                <Button onClick={() => handleDeleteTrue(willDelete)} variant="success" className="mt-3 float-left" >
                                    Confirmar
                                </Button>
                                <Button onClick={() => handleDeleteFalse()} variant="danger" className="mt-3 float-right" >
                                    Cancelar
                                </Button> 
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
              </>)

          }
          else{
              return <Redirect to="/logout"  />;
          }
  }

export default RendimientoHoras; 
