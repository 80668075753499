import React, {useState, useEffect } from 'react';
import { Container, Modal } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import moment from "moment";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { getDay } from 'date-fns';
registerLocale('es', es)

const MisVacaciones = () =>{

    const [misDatos, setMisDatos]                                   = useState([]);
    const [visible , SetVisible]                                    = useState(false);

    const [showDatesInput, setShowDatesInput]                       = useState(true);
    const [diasDisponiblesSelect, setDiasDisponiblesSelect]         = useState("-");
    const [vigenciaVacaciones, setVigenciaVacaciones]               = useState("");
    const [tipoVacacion, setTipoVacacion]                           = useState();   
    const [listadoVacacionesNuevas, setListadoVacacionesNuevas ]    = useState("");    
    const [listadoVacacionesPasadas, setListadoVacacionesPasadas ]  = useState("");         
    const [showBtn, setShowBtn]                                     = useState(true);
    const [dataGo, setDataGo]                                       = useState([]);
    
    const [isAuthorized, setIsAuthorized]                           = useState(true);

    const [startDate, setStartDate]                                 = useState();
    const [endDate, setEndDate]                                     = useState(null);

    //-------------------------- [ calendario ] -------------------------

    //const [startDate, setStartDate] = useState(null);
    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };

    const getDaysArray = (start, end) => {
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt));
        }
        return arr;
    };

    const onChangeVacationsType = (e) => {
        
        let hoy = moment(new Date()).format("YYYY-MM-DD");

        setTipoVacacion(e.target.value)
        if(e.target.value === "pasado"){
            if(misDatos.vacaciones_pasadas && (misDatos.vacaciones_pasadas.fecha_final>hoy)){
                setDiasDisponiblesSelect(misDatos.vacaciones_pasadas.vacaciones_libres);
                if(misDatos.vacaciones_pasadas.vacaciones_libres <1){
                    setShowDatesInput(true);
                }
                else{
                    setShowDatesInput(false);
                }
                setVigenciaVacaciones(misDatos.vacaciones_pasadas.fecha_final);
            }
            else{
                setShowDatesInput(true);
            }
            
            
        }
        else if(e.target.value === "actual"){
            if(misDatos.vacaciones_nuevas.fecha_final>hoy)
                setDiasDisponiblesSelect(misDatos.vacaciones_nuevas.vacaciones_libres);

            if(misDatos.vacaciones_nuevas.vacaciones_libres <1){
                setShowDatesInput(true);
            }
            else{
                setShowDatesInput(false);
            }
            setVigenciaVacaciones(misDatos.vacaciones_nuevas.fecha_final);
        }

    };

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        let total_vacaciones;
        if(tipoVacacion === "pasado")
            total_vacaciones = misDatos.vacaciones_pasadas.vacaciones_libres;
        else
            total_vacaciones = misDatos.vacaciones_nuevas.vacaciones_libres;
       

        if(start && end){
            
            let array = getDaysArray(start,end);
            let laborables = 0;
            for (let index = 0; index < array.length; index++) {
                if( array[index].getDay()>0 && array[index].getDay()<6 )
                    laborables++
            }

            alert("seleccionaste "+laborables+" días de vacaciones y tienes "+total_vacaciones+" días disponibles");
            if(total_vacaciones>=laborables){
                setDataGo({end: end, start: start, laborables: laborables})
                setShowBtn(false)
            }
            else{
                setShowBtn(true)
            }
                
        }
    };
 
    //------------------------ [  operations ] --------------------------

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    //-------------------[  main data  ]--------------------------
    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia>3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
            const retrieveData = () => {
            
                let anio;

                trackPromise(

                    instance.get('/mis-vacaciones/get')
                    .then(response => {
                        
                        //console.log(response.data.data);
                        //console.log(response.data.data)
                        if(response.data.data){
                            setIsAuthorized(true);
                            setMisDatos(response.data.data);
                            //console.log(response.data.data);
                            anio = response.data.data.anios_laborando
                        }
                        else{
                            setIsAuthorized(false);
                        }
                        
                        // -------------------------- [ vacaciones nuevas ]
                        trackPromise(
                        
                            instance.get('/mis-vacaciones/list/'+anio)
                            .then(response => {
                                setIsAuthorized(true);
                                setListadoVacacionesNuevas(response.data.data.data);
                            })
                            .catch(e => {
                                console.log("Expired token - Error #45435");
                                setIsAuthorized(false);
                            })
                
                        );
                        // -------------------------- [ vacaciones pasadas ]
                        trackPromise(
                        
                            instance.get('/mis-vacaciones/list/'+(anio-1))
                            .then(response => {
                                setIsAuthorized(true);
                                setListadoVacacionesPasadas(response.data.data.data);
                            })
                            .catch(e => {
                                console.log("Expired token - Error #45435");
                                setIsAuthorized(false);
                            })
                
                        );
                        // ---------------------------



                    })
                    .catch(e => {
                        console.log("Expired token - Error #45435");
                        setIsAuthorized(false);
                    })
    
                );
                

            }

            retrieveData();
        }
        else{
            setIsAuthorized(false);
        }
        

    },[]);

    const retrieveDataRefresh = () => {
                
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        
        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/mis-vacaciones/get')
            .then(response => {
                setIsAuthorized(true);
                setMisDatos(response.data.data);
            })
            .catch(e => {
                console.log("Expired token - Error #45435");
                setIsAuthorized(false);
            })

        );
        
        
        if(misDatos){
            
            let anio = misDatos.anios_laborando
            trackPromise(
                            
                instance.get('/mis-vacaciones/list/'+anio)
                .then(response => {
                    setIsAuthorized(true);
                    setListadoVacacionesNuevas(response.data.data.data);
                })
                .catch(e => {
                    console.log("Expired token - Error #45435");
                    setIsAuthorized(false);
                })

            );

            trackPromise(
                            
                instance.get('/mis-vacaciones/list/'+(anio-1))
                .then(response => {
                    setIsAuthorized(true);
                    setListadoVacacionesPasadas(response.data.data.data);
                })
                .catch(e => {
                    console.log("Expired token - Error #45435");
                    setIsAuthorized(false);
                })

            );
        }

    
    }

    const handleSubmit = () => {
        setShowBtn(true);
        if(endDate){
            
            //---------------- call api ---------------- 
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });

            trackPromise(

                instance.post('/mis-vacaciones/add',{
                    cantidad_dias: dataGo.laborables,
                    fecha_inicio: dataGo.start,
                    fecha_fin: dataGo.end,
                    tipo: tipoVacacion
                })
                .then(response => {
                    setIsAuthorized(true);
                    setMisDatos([])
                    SetVisible(false)
                    retrieveDataRefresh()
                })
                .catch(e => {
                    console.log("Expired token - Error #45435");
                    setIsAuthorized(false);
                })

            );
            
            //---------------- end call api ---------------- 
        }   
        else
            alert("Por favor seleccione un rango de fechas válido");

    }

    const openModal = (id) => {
        SetVisible(true);
        setStartDate("");
        setEndDate("");
        setDiasDisponiblesSelect("-");
        setShowDatesInput(true);
    }

    const totales = (tipo) => {

            let total_vacaciones = misDatos.vacaciones_nuevas.vacaciones_libres;
            if(misDatos.vacaciones_pasadas != null)
                total_vacaciones = misDatos.vacaciones_nuevas.vacaciones_libres + misDatos.vacaciones_pasadas.vacaciones_libres;
            
            let hoy = moment(new Date()).format("YYYY-MM-DD");
            //console.log(misDatos.vacaciones_nuevas.fecha_final+" - "+hoy)
            if(misDatos.vacaciones_nuevas.fecha_final<hoy)
                total_vacaciones = 0;

            return (
                <>
                    <h2 className="mt-4 titulo_login">Total de días disponibles</h2>
                    <div className="row">
                        <div className="col-6">
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <span className="txt_listado_bold">Días disponibles</span>
                                </div>
                                <div className="col-md-6">
                                    <span className="txt_listado">{total_vacaciones}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            &nbsp;
                        </div>
                    </div>
                    
                </>
            )
    }
        
    const vacacionesNuevas = () => {
        
        if (misDatos.vacaciones_nuevas) {
          return (
              <>
                <h2 className="mt-4 titulo_login">Vacaciones del periodo actual</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días de vacaciones</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_nuevas.vacaciones_reales }</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días usados</span>
                            </div>
                            <div className="col-md-6">
                                    <span className="txt_listado">{misDatos.vacaciones_nuevas.vacaciones_usadas}</span>
                            </div>
                        </div>
               
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días disponibles</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_nuevas.vacaciones_libres}</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Vigencia</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_nuevas.fecha_final}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="row mt-2">
                            <div className="col-md-3"><span className="txt_listado_bold">Cantidad días</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Fecha inicio</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Fecha fin</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Estatus</span></div>
                        </div>
                    
                        {
                                listadoVacacionesNuevas &&
                                listadoVacacionesNuevas.map(post => {
                                
                                    const { id, cantidad_dias, fecha_inicio, fecha_fin, status} = post;
                                    let txt_status;
                                    if( status === 1){
                                        txt_status="En proceso"
                                    }
                                    else if( status === 2){
                                        txt_status="Autorizadas"
                                    }
                                    else if( status === 3){
                                        txt_status="Rechazadas"
                                    }
                                    return (
                                        <div className="row mt-2" key={id}>
                                            <div className="col-md-3"><span className="txt_listado">{cantidad_dias}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{fecha_inicio}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{fecha_fin}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{txt_status}</span></div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
              </>
          )
        } 
    }

    const vacacionesPasadas = () => {
        if (misDatos.vacaciones_pasadas) {
          return (
              <>
                <h2 className="mt-4 titulo_login">Vacaciones vigentes del periodo anterior</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días de vacaciones</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_pasadas.vacaciones_reales }</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días usados</span>
                            </div>
                            <div className="col-md-6">
                                    <span className="txt_listado">{misDatos.vacaciones_pasadas.vacaciones_usadas}</span>
                            </div>
                        </div>
               
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días disponibles</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_pasadas.vacaciones_libres}</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Vigencia</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_pasadas.fecha_final}</span>
                                <span className="red_alert"></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mt-2">
                            <div className="col-md-3"><span className="txt_listado_bold">Cantidad días</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Fecha inicio</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Fecha fin</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Estatus</span></div>
                        </div>

                        {
                                listadoVacacionesPasadas &&
                                listadoVacacionesPasadas.map(post => {
                                
                                    const { id, cantidad_dias, fecha_inicio, fecha_fin, status} = post;
                                    let txt_status;
                                    if( status === 1){
                                        txt_status="En proceso"
                                    }
                                    else if( status === 2){
                                        txt_status="Autorizadas"
                                    }
                                    else if( status === 3){
                                        txt_status="Rechazadas"
                                    }
                                    return (
                                        <div className="row mt-2" key={id}>
                                            <div className="col-md-3"><span className="txt_listado">{cantidad_dias}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{fecha_inicio}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{fecha_fin}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{txt_status}</span></div>
                                        </div>
                                    )
                                })
                        }

                    </div>
                </div>
              </>
          )
        } 
    }


    if(sessionStorage.getItem('userData') != null && isAuthorized ){

        if(misDatos.vacaciones_nuevas){
           
            return(<>
                <Header />
                <Container className="mb-5">
				
                <h1 className="titulo_login mt-4">Mis vacaciones</h1>
                <div className="row mt-2">
                            <div className="col-12">
                                <button className="float-right btn btn-info" onClick={() => openModal()}>Agregar vacaciones</button>  
                            </div> 
                        </div>
                <div className="row">
                    <div className="col-6">
                        
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Fecha ingreso</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{ misDatos.fecha_ingreso }</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días en la empresa</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.dias_laborando || null}</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Años en la empresa</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.anios_laborando || null}</span>
                            </div>
                        </div>

                    </div>
                    <div className="col-6">
                        &nbsp;
                    </div>
                </div>
				

                
                
                
                {vacacionesNuevas()}

                {vacacionesPasadas()}

                {totales()}

				<div className="row mt-3 mb-5">
					<div className="col-md-2">
						<span>&nbsp;</span>
					</div>
					
				</div>

				<div className="row mb-2">
					<div className="col-md-2">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-10">
						<span>&nbsp;</span>
					</div>
				</div>

			</Container>

            <Modal size="lg" show={visible} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title className="titulo_login_plain">Agregar Vacaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="row mt-3">
					    <div className="col-md-4">
						    <span className="txt_listado">Días disponibles</span>
					    </div>
					    <div className="col-md-8">
						    <span><select className="form-control" onChange={onChangeVacationsType}>
                                    <option value=''>Seleccione...</option>
                                    {
                                        misDatos.anios_laborando>1 &&
                                        misDatos.vacaciones_pasadas &&
                                            <option value='pasado'>Vacaciones (periodo pasado)</option>
                                    }
                                    <option value='actual'>Vacaciones (periodo actual)</option>
                            </select></span>
					    </div>
				    </div>
                    <div className="row mt-3">
					    <div className="col-md-4">
						    <span className="txt_listado">Días disponibles</span>
					    </div>
					    <div className="col-md-8">
						    <span>{diasDisponiblesSelect}</span>
					    </div>
				    </div>
                    <div className="row mt-3">
					    <div className="col-md-4">
						    <span className="txt_listado">Fecha</span>
					    </div>
					    <div className="col-md-8">
						    <span><DatePicker
                                    filterDate={isWeekday}
                                    //selected={checkInDate}
                                    minDate={new Date()}
                                    //maxDate={}
                                    selectsRange
                                    //onChange={handleCheckInDate}
                                    className="form-control w-100"
                                    placeholderText="Fecha inicio"
                                    dateFormat="yyyy-MM-dd"
                                    locale="es"
                                    selected={startDate}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    disabled={showDatesInput}
                                    // por hacer
                                    maxDate={moment(vigenciaVacaciones)._d}
                                /></span>
					    </div>
				    </div>

				
                <div className="row mt-3 mb-5">
					<div className="col-md-4">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-8">
						<span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmit}  /></span>
					</div>
				</div>
                    </Container>
                </Modal.Body>
            </Modal>
            </>)

        }
        else{
            return(
            <>
            <Header />
            <h4 className="text-center mt-5">Loading...</h4>
            </>)
        }
    }
    else{
        return <Redirect to="/logout"  />;
    }

}


export default MisVacaciones;