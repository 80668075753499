import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
//import {PostData} from './services/PostData';
import './css/Login.css';
import './css/bienvenido.css';
// import Header from './header';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';

const Login = () =>{

    const [email, setEmail]                             = useState("");
    const [password, setPassword]                       = useState("");
    const [errorCredenciales, setErrorCredenciales]     = useState();
    const [redirectTrue, setRedirectTrue]               = useState();
    const [showLoading, setShowLoading]                 = useState({display: "none"});

    const LoadingIndicator = props => {
        const { promiseInProgress } = usePromiseTracker();
            return promiseInProgress && 
                <div className="centerDots text-center mt-6" >
                      <Loader type="ThreeDots"  color="#0f44d6" height="100" width="100" />
                      <h3 style={{color: "#FFF", marginTop: "-20px"}}>Loading...</h3>
                </div>        
    };

    const OnChangeEmail = (e) =>{
        setEmail(e.target.value);        
    }

    const OnChangePassword = (e) =>{
        setPassword(e.target.value);        
    }

    const handleSubmit = () => {
        
        if(email && password){
            setShowLoading({display: "block"});
                const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000
                });
    
                trackPromise(
    
                    instance.post('/login',{
                        email: email,
                        password: password
                    })
                    .then(response => {
                        //console.log(response);
                        let token = response.data.data.token;
                        let id_cuenta = response.data.data.id_cuenta;
    
                        if(token && id_cuenta){
                            console.log("👉 Acceso correcto");
                            sessionStorage.setItem('userData',JSON.stringify(response.data.data));
                            setRedirectTrue(true);
                        }
                        else{
                            console.log("👉 Acceso incorrecto");
                            setErrorCredenciales("Error en credenciales, por favor verifique la información.");
                            setShowLoading({display: "none"});
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        setPassword(null);
                        setErrorCredenciales("Error en credenciales, por favor verifique la información.");
                        setShowLoading({display: "none"});
                    })
    
                );
                    
        }


    }


if(!redirectTrue){
    document.body.style = 'background: #1C2833 !important;';
	return (
        <>
        <div className="loading-div text-center" style={showLoading}>
            <LoadingIndicator/> 
        </div>
		{/* <Header /> */}
		<Container>
            <div className="row justify-content-center mt-4" >
                <div className="col-md-6 MainLogin">
                        <img alt="Logo" src='logo.png' className="logo_bienvenido" />
                    <h1 className="titulo_login_new text-center">Bienvenido a tu empresa digital</h1>
                    <h3 className="error-credenciales mb-3 mt-3">{errorCredenciales}</h3>

                    <Form.Group className="mt-4" controlId="formBasicEmail">
                        <Form.Label className="label_login">Usuario</Form.Label>
                        <Form.Control type="text" name="email" placeholder="Usuario" onKeyUp={OnChangeEmail} />
                        
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label className="label_login">Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" defaultValue={password} name="password" onChange={OnChangePassword} />
                    </Form.Group>
                    <Button className="btn float-right form-control2"  type="submit" onClick={() => handleSubmit()} >
                        Enviar
                    </Button>
                    <div className="row text-center" style={{ marginBottom: "0px" }}>
                        <div className="col-md-12" style={{ marginBottom: "0px" }}>
                            <a href="/forgot"><p className="mt-5 text-center forgot-link">Olvidé mi contraseña</p></a>
                        </div>
                    </div>
                </div>
            </div>
		</Container>
        </>
	);

    }
    else{
        return <Redirect to="/home"  />;
    }

}

export default Login;