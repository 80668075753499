import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js';
import React, {Component} from 'react';
import './css/Footer.css';
//import  { Redirect } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap';
import './config';
//import  { Link } from 'react-router-dom'

class Header extends Component {

  
render() {
  
if(!sessionStorage.getItem('userData')){
	return (
	<>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="#home"><img alt="Logo" src='logo.png' className="logo_top" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="/login">Login</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

	</>);
}
else{

  let ud = sessionStorage.getItem('userData');
  let userData = JSON.parse(ud);
  let avatarok;
  if(userData.avatar === null){
      avatarok = "user.png"
  }
  else{
      avatarok = "https://d2sx5pnhyjgoui.cloudfront.net/"+userData.avatar;
  }

  if(userData.jerarquia === 1){
        return (
      <>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark sticky-top backdrop">
          <a className="navbar-brand" href="../home"><img src="./../logo.png" className="logo-menu" alt="Tonino" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                      <a className="nav-link menu-react" href="../home">Home <span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../empleados">Empleados</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../clientes">Clientes</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../proyectos">Proyectos</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../catalogo">Catálogo</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../rendimiento-horas">Rendimiento horas</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../parrillas">Social Media</a>
                  </li>
              </ul>
              <ul className="navbar-nav">r
                  <li className="nav-item backdrop">
                    <div className="dropdown">
                      <button type="button" className="btn dropdown-toggle btn-cuenta" data-toggle="dropdown">
                        <span className="mi-cuenta">Mi cuenta</span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right backdrop">
                          <a className="dropdown-item interior_menu text-center" href="../mi-cuenta">
                            <img src={avatarok || "user.png"} className="user-pic" alt="" />
                            <p className="user_name">{userData.name}</p>
                          </a>
                        <div className="dropdown-divider"></div>
                        {
                          userData.id === 1 &&
                          <>
                            <a className="dropdown-item interior_menu" href="../add-empresa">Agregar empresa</a>
                          </>
                        }
                          <a className="dropdown-item interior_menu" href="../mi-empresa">Mi empresa</a>
                          <a className="dropdown-item interior_menu" href="../vacaciones">Vacaciones</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item interior_menu" href="../logout">Logout</a>
                      </div>
                    </div>
                  </li>
              </ul>
          </div>
      </nav>
      </>);
  }
  else if(userData.jerarquia === 2){

	return (
	<>
    <nav className="navbar navbar-expand-md navbar-dark bg-dark sticky-top backdrop">
          <a className="navbar-brand" href="../home"><img src="./../logo.png" className="logo-menu" alt="Tonino" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                      <a className="nav-link menu-react" href="../home">Home <span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../empleados">Empleados</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../clientes">Clientes</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../proyectos">Proyectos</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../catalogo">Catálogo</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../rendimiento-horas">Rendimiento horas</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../parrillas">Social Media</a>
                  </li>
              </ul>
              <ul className="navbar-nav">r
                  <li className="nav-item backdrop">
                    <div className="dropdown">
                      <button type="button" className="btn dropdown-toggle btn-cuenta" data-toggle="dropdown">
                        <span className="mi-cuenta">Mi cuenta</span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right backdrop">
                          <a className="dropdown-item interior_menu text-center" href="../mi-cuenta">
                            <img src={avatarok || "user.png"} className="user-pic" alt="" />
                            <p className="user_name">{userData.name}</p>
                          </a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item interior_menu" href="../mi-empresa">Mi empresa</a>
                          <a className="dropdown-item interior_menu" href="../vacaciones">Vacaciones</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item interior_menu" href="../logout">Logout</a>
                      </div>
                    </div>
                  </li>
              </ul>
          </div>
      </nav>
	</>);
}
else if(userData.jerarquia === 3){

  return (
  <>
  
  </>);
}
else if(userData.jerarquia === 4){

  return (
  <>
    <nav className="navbar navbar-expand-md navbar-dark bg-dark sticky-top backdrop">
          <a className="navbar-brand" href="../home"><img src="./../logo.png" className="logo-menu" alt="Tonino" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                      <a className="nav-link menu-react" href="../home">Home <span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../mis-vacaciones">Vacaciones</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../carga-horas">Mis horas</a>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link menu-react" href="../parrillas">Social Media</a>
                  </li>
                 
              </ul>
              <ul className="navbar-nav">r
                  <li className="nav-item backdrop">
                    <div className="dropdown">
                      <button type="button" className="btn dropdown-toggle btn-cuenta" data-toggle="dropdown">
                        <span className="mi-cuenta">Mi cuenta</span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right backdrop">
                          <a className="dropdown-item interior_menu text-center" href="../mi-cuenta">
                            <img src={avatarok || "user.png"} className="user-pic" alt="" />
                            <p className="user_name">{userData.name}</p>
                          </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item interior_menu" href="../logout">Logout</a>
                      </div>
                    </div>
                  </li>
              </ul>
          </div>
      </nav>
  </>);
}
}

}
}

export default Header;