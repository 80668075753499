import React, {useState, useEffect, useRef, useCallback } from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
//import { Link } from 'react-router-dom';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import {  faTrash } from "@fortawesome/free-solid-svg-icons"; //faHome,
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
//-------------- [ files ] ------------------
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; // eslint-disable-line
import {useDropzone} from 'react-dropzone'

import Button  from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
//------------
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
//---------
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

//---------

const Clientes = () =>{

    //-------- hooks
    const [clientes, setClientes]                           = useState([]);
    const [visible , SetVisible]                            = useState({ show: false, id: null });
    const [visible2 , SetVisible2]                          = useState(false);
    const [visible3 , SetVisible3]                          = useState(false);
    const [nombreEmpresa, setNombreEmpresa]                 = useState("");
    const [categoria, setCategoria]                         = useState("");
    const [RFC, setRFC]                                     = useState("");
    const [fechaIngreso, setFechaIngreso]                   = useState("");
    const [status, setStatus]                               = useState("");
    const [contactoNombre, setContactoNombre]               = useState("");
    const [contactoEmail, setContactoEmail]                 = useState("");
    const [contactoPuesto, setContactoPuesto]               = useState("");
    const [contactoCelular, setContactoCelular]             = useState("");
    const [isAuthorized, setIsAuthorized]                   = useState(true);

    const [errorNombreEmpresa, setErrorNombreEmpresa]       = useState(null);
    const [errorStatus, setErrorStatus]                     = useState(null);
    const [errorRFC, setErrorRFC]                           = useState(null);
    const [errorCategoria, setErrorCategoria]               = useState(null);
    const [errorFechaIngreso, setErrorFechaIngreso]         = useState(null);
    const [errorContactoNombre, setErrorContactoNombre]     = useState(null);
    const [errorContactoEmail, setErrorContactoEmail]       = useState(null);
    const [errorContactoPuesto, setErrorContactoPuesto]     = useState(null);
    const [errorContactoCelular, setErrorContactoCelular]   = useState(null);
    //   files
    const [loadingUploading, setLoadingUploading]           = useState(false);
    const [activeFiles, setActiveFiles ]                    = useState([]);    
    const [btnDisabled, setBtnDisabled]                     = useState(false);
    const [idToChange, setIdToChange]                       = useState("");

    const [showBtn, setShowBtn]                             = useState(false);
    const [dataArea, setDataArea]                           = useState([]);

    //-------------------- nuevo data grid -------------------
    const [loading, setLoading]                             = useState(false);
    const [page, setPage]                                   = useState(0);
    const [selectionModel, setSelectionModel]               = useState([]);
    const prevSelectionModel                                = useRef(selectionModel);    
    const [totalRows, setTotalRows]                         = useState(0);
    const [value, setValue]                                 = React.useState(0);
    const [image, setImage]                                 = useState({ preview: "", raw: "" });

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const OnChangeNombreEmpresa = (e) =>{
        setNombreEmpresa(e.target.value);        
    }

    const OnChangeCategoria = (e) =>{
        setCategoria(e.target.value);        
    }

    const OnChangeRFC = (e) =>{
        setRFC(e.target.value);        
    }

    const OnChangeFechaIngreso = (e) =>{
        setFechaIngreso(e.target.value);        
    }

    const OnChangeContactoNombre = (e) =>{
        setContactoNombre(e.target.value);        
    }

    const OnChangeContactoEmail = (e) =>{
        setContactoEmail(e.target.value);        
    }

    const OnChangeContactoPuesto= (e) =>{
        setContactoPuesto(e.target.value);        
    }

    const OnChangeContactoCelular= (e) =>{
        setContactoCelular(e.target.value);        
    }

    const OnChangeStatus = (e) =>{
        console.log(e.target.value);
        setStatus(e.target.value);          
    }

    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        
        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
        const retrieveData = () => {

            trackPromise(

                instance.get('/clientes')
                .then(response => {
                    setIsAuthorized(true);
                    setClientes(response.data.data.data);
                    setTotalRows(response.data.data.total);
                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })
  
            );
            
        }

        retrieveData();

        }
        

    },[]);

   
    const updateAreas = () => {
        setClientes([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/clientes')
            .then(response => {
                setIsAuthorized(true);
                setClientes(response.data.data.data);
                setTotalRows(response.data.data.total);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const handleDeleteTrue = (id) => {
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.delete('/clientes/'+id)
            .then(response => {
                if(response.status === 200){
                    updateAreas();
                    NextPage(page);
                    SetVisible({
                        visible: false,
                        id: null,
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    const handleDeleteFalse2 = () => {
        SetVisible2(false);
    }

    const handleDeleteFalse3 = () => {
        SetVisible3(false);
    }

    const openModal = (id) => {
        SetVisible2(true);
        setNombreEmpresa(""); 
        setCategoria(""); 
        setRFC(""); 
        setFechaIngreso(""); 
        setContactoNombre(""); 
        setContactoEmail(""); 
        setContactoPuesto(""); 
        setContactoCelular(""); 
        setStatus("");
        setShowBtn(false); 
        setIdToChange(id);
        UpdateFilesFromServer(id);

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        console.log(id);

        trackPromise(
            instance.get('clientes/'+id)
            .then(response => {
                setDataArea(response.data.data);
                setNombreEmpresa(response.data.data.nombre_empresa);
                setCategoria(response.data.data.categoria);
                setRFC(response.data.data.RFC);
                setFechaIngreso(response.data.data.fecha_ingreso);
                setContactoNombre(response.data.data.contacto_nombre);
                setContactoEmail(response.data.data.contacto_email);
                setContactoPuesto(response.data.data.contacto_puesto);
                setContactoCelular(response.data.data.contacto_celular);
                setStatus(response.data.data.status);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const openModalNew = (id) => {
        SetVisible3(true);
        setShowBtn(false)
        setNombreEmpresa("");
        setCategoria("");
        setContactoNombre("");
        setContactoEmail("");
        setContactoPuesto("");
        setFechaIngreso("");
        setStatus("");

    }

    const handleSubmit = (e) => {
        
        let errorsForm = false;
        setShowBtn(true);
        e.preventDefault();
        
            /* **************************    VALIDACIONES    ****************************** */

            //let regex_letras = /^[a-zA-Z]+(\s*[a-zA-Z]*)*[a-zA-Z]+$/;
            //-------------------------------------------------------------------------------------------
            let regex_letras = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            let regex_rfc = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
            let regex_fecha 	= /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
            
            //--------------------------[		validate inp_nombre_empresa		]-------------------------
            if(nombreEmpresa.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreEmpresa("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorNombreEmpresa("");
            }

            //-------------------[		validate categoria		]----------------
            if(categoria.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorCategoria("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorCategoria("");
            }

            //-------------------[		validate RFC		]----------------
            if(RFC.match(regex_rfc) == null){
                //console.log("Error: solo letras");
                setErrorRFC("Error: RFC No válido");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorRFC("");
            }

            //-------------------[  	Fecha ingreso	 ]----------------
            if(fechaIngreso.match(regex_fecha) == null){
                //console.log("Error: solo letras");
                setErrorFechaIngreso("Error: RFC No válido");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorFechaIngreso("");
            }


            //-------------------[		validate inp_contacto_nombre	]----------------
            if(contactoNombre.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorContactoNombre("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorContactoNombre("");
            }


            //-------------------[		validate email pt 1		]----------------
            if(!contactoEmail){
                setErrorContactoEmail("Error: email vacío");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorContactoEmail("");
            }
        
            //-------------------[		validate email pt 2		]----------------
            if(typeof contactoEmail !== "undefined"){
                let lastAtPos = contactoEmail.lastIndexOf('@');
                let lastDotPos = contactoEmail.lastIndexOf('.');
        
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && contactoEmail.indexOf('@@') === -1 && lastDotPos > 2 && (contactoEmail.length - lastDotPos) > 2)) {
                setErrorContactoEmail("Error: email no válido");
                setShowBtn(false);
                errorsForm = true;
                }
                else{
                    setErrorContactoEmail("");
                }
            }  


            //-------------------[		validate inp_contacto_puesto	]----------------
            if(contactoPuesto.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorContactoPuesto("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorContactoPuesto("");
            }


            //-------------------[				celular 			]----------------

            if(contactoCelular.match(/^[0-9]+$/) == null){
                //console.log("Error: solo letras");
                setErrorContactoCelular("Error: solo números");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorContactoCelular("");
            }

            
        /* **************************  END VALIDACIONES  ****************************** */


                if(!status){
                    //console.log("Error: solo letras");
                    setErrorStatus("Por favor seleccione una opción válida");
                    setShowBtn(false);
                    errorsForm = errorsForm + 1;
                    console.log(errorsForm);
                }
                else{
                    setErrorStatus("");
                }

                if(!errorsForm){

                    // ------------------  Inicio if errorsForm  --------------

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    if(image){

                        e.preventDefault();

                        trackPromise(
                            instance.put('clientes/'+dataArea.id, { 
                                nombre_empresa: nombreEmpresa, 
                                categoria: categoria, 
                                contacto_nombre: contactoNombre, 
                                contacto_email: contactoEmail, 
                                contacto_puesto: contactoPuesto, 
                                contacto_celular: contactoCelular, 
                                RFC: RFC, 
                                logo: image,
                                fecha_ingreso: fechaIngreso,
                                status: status
                                })
                            .then(response => {
                            
                            if(response.data.success === true){
                                    //alert("El área se guardó exitosamente")
                                    setClientes([]);
                                    //updateData();
                                    setNombreEmpresa(""); 
                                    setCategoria(""); 
                                    setRFC(""); 
                                    setFechaIngreso(""); 
                                    setContactoNombre(""); 
                                    setContactoEmail(""); 
                                    setContactoPuesto(""); 
                                    setContactoCelular(""); 
                                    setErrorContactoEmail("");
                                    setErrorContactoNombre("");
                                    setErrorContactoPuesto("");
                                    setErrorContactoCelular("");
                                    setStatus("");
                                    SetVisible2(false);
                                    //updateData();
                                    NextPage(page);
                            }
                            
                            })
                            .catch(e => {
                                console.log(e);
                            })
                        );


                    }
                    else{

                    
                        trackPromise(
                            instance.put('clientes/'+dataArea.id, { 
                                nombre_empresa: nombreEmpresa, 
                                categoria: categoria, 
                                contacto_nombre: contactoNombre, 
                                contacto_email: contactoEmail, 
                                contacto_puesto: contactoPuesto, 
                                contacto_celular: contactoCelular, 
                                RFC: RFC, 
                                fecha_ingreso: fechaIngreso,
                                status: status
                                })
                            .then(response => {
                            
                            if(response.data.success === true){
                                    //alert("El área se guardó exitosamente")
                                    setClientes([]);
                                    //updateData();
                                    setNombreEmpresa(""); 
                                    setCategoria(""); 
                                    setRFC(""); 
                                    setFechaIngreso(""); 
                                    setContactoNombre(""); 
                                    setContactoEmail(""); 
                                    setContactoPuesto(""); 
                                    setContactoCelular(""); 
                                    setErrorContactoEmail("");
                                    setErrorContactoNombre("");
                                    setErrorContactoPuesto("");
                                    setErrorContactoCelular("");
                                    setStatus("");
                                    SetVisible2(false);
                                    //updateData();
                                    NextPage(page);
                            }
                            
                            })
                            .catch(e => {
                                console.log(e);
                            })
                        );
                    }


                    // ---------------- end if erroresForm ---------
                }
                else{
                    alert("Existen errores en los datos.");
                }
                
    }

    const handleSubmitNew = (e) => {

        let errorsForm = false;
        setShowBtn(true);
        e.preventDefault();
        
            /* **************************    VALIDACIONES    ****************************** */

            //let regex_letras = /^[a-zA-Z]+(\s*[a-zA-Z]*)*[a-zA-Z]+$/;
            //-------------------------------------------------------------------------------------------
            let regex_letras = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            let regex_rfc = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
            let regex_fecha 	= /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
            
            //--------------------------[		validate inp_nombre_empresa		]-------------------------
            if(nombreEmpresa.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreEmpresa("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorNombreEmpresa("");
            }

            //-------------------[		validate categoria		]----------------
            if(categoria.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorCategoria("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorCategoria("");
            }

            //-------------------[		validate RFC		]----------------
            if(RFC.match(regex_rfc) == null){
                //console.log("Error: solo letras");
                setErrorRFC("Error: RFC No válido");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorRFC("");
            }

            //-------------------[  	Fecha ingreso	 ]----------------
            if(fechaIngreso.match(regex_fecha) == null){
                //console.log("Error: solo letras");
                setErrorFechaIngreso("Error: RFC No válido");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorFechaIngreso("");
            }


            //-------------------[		validate inp_contacto_nombre	]----------------
            if(contactoNombre.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorContactoNombre("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorContactoNombre("");
            }


            //-------------------[		validate email pt 1		]----------------
            if(!contactoEmail){
                setErrorContactoEmail("Error: email vacío");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorContactoEmail("");
            }
        
            //-------------------[		validate email pt 2		]----------------
            if(typeof contactoEmail !== "undefined"){
                let lastAtPos = contactoEmail.lastIndexOf('@');
                let lastDotPos = contactoEmail.lastIndexOf('.');
        
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && contactoEmail.indexOf('@@') === -1 && lastDotPos > 2 && (contactoEmail.length - lastDotPos) > 2)) {
                setErrorContactoEmail("Error: email no válido");
                setShowBtn(false);
                errorsForm = true;
                }
                else{
                    setErrorContactoEmail("");
                }
            }  


            //-------------------[		validate inp_contacto_puesto	]----------------
            if(contactoPuesto.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorContactoPuesto("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorContactoPuesto("");
            }


            //-------------------[				celular 			]----------------

            if(contactoCelular.match(/^[0-9]+$/) == null){
                //console.log("Error: solo letras");
                setErrorContactoCelular("Error: solo números");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorContactoCelular("");
            }

            
        /* **************************  END VALIDACIONES  ****************************** */


                if(!status){
                    //console.log("Error: solo letras");
                    setErrorStatus("Por favor seleccione una opción válida");
                    setShowBtn(false);
                    errorsForm = errorsForm + 1;
                    console.log(errorsForm);
                }
                else{
                    setErrorStatus("");
                }

                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    trackPromise(
                        instance.post('/clientes', { 
                            nombre_empresa: nombreEmpresa, 
                            categoria: categoria, 
                            contacto_nombre: contactoNombre, 
                            contacto_email: contactoEmail, 
                            contacto_puesto: contactoPuesto, 
                            contacto_celular: contactoCelular, 
                            RFC: RFC, 
                            fecha_ingreso: fechaIngreso,
                            status: status
                            })
                        .then(response => {
                          
                          if(response.data.success === true){
                                //alert("El área se guardó exitosamente")
                                setClientes([]);
                                //updateData();
                                setNombreEmpresa(""); 
                                setCategoria(""); 
                                setRFC(""); 
                                setFechaIngreso(""); 
                                setContactoNombre(""); 
                                setContactoEmail(""); 
                                setContactoPuesto(""); 
                                setContactoCelular(""); 
                                setErrorContactoEmail("");
                                setErrorContactoNombre("");
                                setErrorContactoPuesto("");
                                setErrorContactoCelular("");
                                setStatus("");
                                SetVisible3(false);
                                //updateData();
                                NextPage(page);
                          }
                          
                        })
                          .catch(e => {
                            console.log(e);
                          })
                      );
                }
                else{
                    alert("Existen errores en los datos.");
                }
                
    }

    const showConfirm = (id) => {
        SetVisible({visible: true, id: id});
    }


    //------------------------- [ files ] ---------------------------------

    const UpdateFilesFromServer = (id) =>{
        //alert(id);
        setLoadingUploading(true);
        setActiveFiles([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

          trackPromise(
            instance.get('/files/show/clientes/'+id)
            .then(response => {
                //console.log(response.data.data.data);
                //console.log(response.data);
                setActiveFiles(response.data.data.data);
                setLoadingUploading(false);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const deleteFile = (id) => {

        setBtnDisabled(true);

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
             instance.delete('files/delete/'+id)
            .then(response => {
                //console.log(response.data.data);
                if(response.status === 200){
                    setBtnDisabled(false);
                    UpdateFilesFromServer(idToChange);
                }
            })
              .catch(e => {
                console.log(e);
              })
        );

    }

    const onChangeLogo = e => {
        
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
        files = e.dataTransfer.files;
        } else if (e.target) {
        files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            //setRecortaDisabled(false);
        };
        reader.readAsDataURL(files[0]);

    };

    const TabsComponent = () =>{
        
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Información" {...a11yProps(0)} />
                        <Tab label="Archivos" {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Container>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <span className="txt_listado">Nombre empresa</span>
                                </div>
                                <div className="col-md-9">
                                    <span><input type="text" className="form-control" id="inp_nombre_empresa" name="nombre_empresa" defaultValue={nombreEmpresa || ''} onChange={OnChangeNombreEmpresa} required="required" /></span>
                                    <span className="red_alert">{ errorNombreEmpresa }</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <span className="txt_listado">Categoría</span>
                                </div>
                                <div className="col-md-9">
                                    <span><input type="text" className="form-control" id="inp_categoria" name="categoria" defaultValue={categoria || ''} onKeyUp={OnChangeCategoria} required="required" /></span>
                                    <span className="red_alert">{ errorCategoria }</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <span className="txt_listado">RFC</span>
                                </div>
                                <div className="col-md-9">
                                    <span><input type="text" className="form-control" id="inp_nombre_proyecto" name="RFC" defaultValue={RFC || ''} onKeyUp={OnChangeRFC} required="required" /></span>
                                    <span className="red_alert">{ errorRFC }</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <span className="txt_listado">Fecha ingreso</span>
                                </div>
                                <div className="col-md-9">
                                    <span><input type="date" className="form-control" id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={fechaIngreso || ''}  onKeyUp={OnChangeFechaIngreso} required="required" /></span>
                                    <span className="red_alert">{ errorFechaIngreso }</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <span className="txt_listado">Status</span>
                                </div>
                                <div className="col-md-9">
                                    <span>
                                        <select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
                                            <option value="">Seleccione...</option>
                                            <option value="1">Activo</option>
                                            <option value="2">Inactivo</option>
                                        </select>
                                    </span>
                                    <span className="red_alert">{ errorStatus }</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <span className="txt_listado">Logo</span>
                                </div>
                                <div className="col-md-9">
                                    <span>
                                        <input type="file" className="form-control" id="inp_logo" name="nombre_empresa" onChange={onChangeLogo} required="required" /></span>
                                    <span className="red_alert"></span>
                                </div>
                            </div>

                            <h1 className='subtitulo'>Contacto comercial</h1>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <span className="txt_listado">Nombre</span>
                                </div>
                                <div className="col-md-9">
                                    <span><input type="text" className="form-control" id="inp_contacto_nombre" name="contacto_nombre" defaultValue={contactoNombre || ''}  onKeyUp={OnChangeContactoNombre}  required="required" /></span>
                                    <span className="red_alert">{ errorContactoNombre }</span>
                                </div>
                            </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <span className="txt_listado">Email</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_contacto_email" name="contacto_email" defaultValue={contactoEmail || ''}  onKeyUp={OnChangeContactoEmail} required="required" /></span>
                                <span className="red_alert">{ errorContactoEmail }</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <span className="txt_listado">Puesto</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="email" className="form-control" id="inp_contacto_puesto" name="contacto_puesto" defaultValue={contactoPuesto || ''}  onKeyUp={OnChangeContactoPuesto} required="required" /></span>
                                <span className="red_alert">{ errorContactoPuesto}</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-3">
                                <span className="txt_listado">Celular</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" maxLength="10" className="form-control" id="inp_contacto_celular" name="contacto_celular" defaultValue={contactoCelular || ''}  onKeyUp={OnChangeContactoCelular} required="required" /></span>
                                <span className="red_alert">{ errorContactoCelular }</span>
                            </div>
                        </div>
                        
                        <div className="row mt-3 mb-5">
                            <div className="col-md-3">
                                <span>&nbsp;</span>
                            </div>
                            <div className="col-md-9">
                                <span><Button variant="contained" color="success" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmit}>Guardar</Button></span>
                            </div>
                        </div>
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Container className="mb-5">
                        <div className="row mt-4">
                            <div className="col-md-5">
                                <span className="txt_listado"><strong>Nombre archivo</strong></span>
                            </div>
                            <div className="col-md-3">
                                <span className="txt_listado"><strong>Fecha</strong></span>
                            </div>
                            <div className="col-md-3">
                                <span className="txt_listado"><strong>Subido por</strong></span>
                            </div>
                            <div className="col-md-1">
                                <span className="txt_listado"><strong>&nbsp;</strong></span>
                            </div>
                        </div>
                        {
                            loadingUploading &&
                                <>
                                <div className="text-center">
                                    <Loader type="ThreeDots"  color="#2c63ff" height="100" width="100" />
                                    <h1 className="loading-final">Cargando...</h1>
                                </div>
                                </>
                        }
                        {
                            activeFiles &&
                            activeFiles.length<1 &&
                                <h4 className="text-center mt-4 no_hay">No hay documentos disponibles</h4>
                        }
                        {
                            activeFiles &&
                            activeFiles.map(post => {
                                
                                    const { id, filename, created_at, name} = post;
                                    //let cut = created_at.split("T");
                                    let donde = "https://d2sx5pnhyjgoui.cloudfront.net/files/"+filename;
                                
                                    return (
                                        <div className="row mt-2" key={id}>
                                            <div className="col-md-5">
                                                <span className="txt_listado"><a href={donde} target="_blank" rel="noreferrer">{filename}</a></span>
                                            </div>
                                            <div className="col-md-3">
                                                <span className="txt_listado">{created_at}</span>
                                            </div>
                                            <div className="col-md-3">
                                                <span className="txt_listado">{name}</span>
                                            </div>
                                            <div className="col-md-1">
                                                <Button variant="contained" color="error" onClick={() => deleteFile(id)} disabled={btnDisabled}><FontAwesomeIcon icon={faTrash} className="icono_trash"  /></Button>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </Container>
                    <MyDropzone />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                Item Three
                </TabPanel> */}
            </Box>
          )
    }
    
    const MyDropzone = () =>{

        const onDrop = useCallback(acceptedFiles => {

            // ------------- Logica de AWS ----------------
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);
        
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 100000,
                headers: {'Authorization': 'Bearer '+userData.token, "content-type": 'multipart/form-data'}
            });
            
            let formd = new FormData();
            formd.append("filename", acceptedFiles[0], acceptedFiles[0].name);
            formd.append("id_cliente",idToChange);
            trackPromise(
                
                instance.post('/upload-files', formd)
                .then(response => {
                    if(response.data.success === true){
                        UpdateFilesFromServer(idToChange);
                    }
                    else{
                        alert("Error #44344");
                    }
    
                })
                .catch(e => {
                    alert(e);
                })
    
            );

            // ----------- END Logica de AWS --------------
          
        }, [])

        
        const { getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
        //console.log(activeFiles);
        if(activeFiles.length < global.config.server_endpoint.maxFilesEmployee){

            return (
            <div {...getRootProps()} style={{border: "3px dashed #2c63ff", marginTop: "30px", paddingTop: "20px", textAlign: "center"}}>
                <input {...getInputProps()} />
                {
                isDragActive ?
                    <p className="limite_empleado">Dejelos caer aquí</p> :
                    <p className="limite_empleado mt-3">Para subir un archivo nuevo, por favor arrastre los archivos aquí.</p>
                }
            </div>
            )
        }
        else{
            return(
                <div className="text-center limite_empleado">Se ha alcanzado el límite de archivos por empleado</div>
            )
        }
    }

    //------------------------ [ end files ] -------------------------------

    const NextPage = (page) => {

        setLoading(true);
        setClientes([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/clientes?page='+page)
            .then(response => {
                setClientes(response.data.data.data);
                setTotalRows(response.data.data.total);
                setLoading(false);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const columns = [
        // { field: 'id', 
        //     headerName: 'ID', 
        //     headerClassName: 'letal',
        //     width: 90 },
        {
          field: 'logo',
          headerName: 'Logo',
          minWidth: 30,
          flex: .06,
          headerClassName: 'letal',
          renderCell: (params) => {
                let imagen_web;
                if(params.row.logo)
                    imagen_web = "https://d2sx5pnhyjgoui.cloudfront.net/images/"+params.row.logo;
                else
                    imagen_web = "https://app.tonino.mx/gris.png";

                return <img alt="logo" className='img-rounded' src={imagen_web} />
          },
        },
        {
            field: 'nombre_empresa',
            headerName: 'Nombre empresa',
            minWidth: 200,
            flex: .24,
            headerClassName: 'letal',
          },
        {
            field: 'categoria',
            headerName: 'Categoría',
            minWidth: 150,
            flex: .21,
            headerClassName: 'letal',
        },
        {
            field: 'fecha_ingreso',
            headerName: 'Fecha ingreso',
            minWidth: 150,
            flex: .1,
            headerClassName: 'letal',
        },
        {
          field: 'status',
          flex: 0.1,
          headerName: 'Estatus',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
                let txt_status = "";
                //console.log(params.row.status);
                switch (params.row.status) {
                    case 1:
                        txt_status = "Activo"
                        break;
                    case 2:
                            txt_status = "Inactivo"
                        break;
                    default:
                        txt_status = "-"
                        break;
                }

                return txt_status;
          },
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 150,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                    <Tooltip title="Borrar">
                        <Button
                            variant="text"
                            color="error"
                            size="large"
                            className="pr-1 mr-1 btn_emp"
                            startIcon={<DeleteIcon />}
                            onClick={() => showConfirm(params.id)}
                        />
                    </Tooltip>

                    <Tooltip title="Editar">
                        <Button
                            variant="text"
                            color="primary"
                            size="large"
                            className="mr-1"
                            startIcon={<EditIcon />}
                            onClick={() => openModal(params.id)}
                        />
                    </Tooltip>
                </strong>
            ),
        }
        
    
    ];

    const rows = clientes;

    ///----------------------[  main return  ]---------------------------
      
     if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized ){
        return(<>
                <Header />

                <Container className="mr-inf">
                    <div className="row">
                        <div className="col-10">
                            <div className="titulo_login">Clientes</div>
                        </div>
                        <div className="col-2">
                        <IconButton aria-label="delete" className="mt-2 float-right" color="error" size="large" onClick={() => openModalNew()}>
                            <AddCircle fontSize="inherit" className="new-icon"  />
                            <h1 className="add-new ml-2 mt-2">Agregar</h1>
                        </IconButton>
                        {/* <h1 className="addEmpleado handy" onClick={() => openModalNew()}>
                                <FontAwesomeIcon icon={faPlus} className="icono_blanco" />
                            </h1> */}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="areas_datagrid">
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>

            </Container>

            <Modal size="lg" show={visible.visible} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton className="no_linea_inferior">
                    <Modal.Title className="titulo_login">¿Estás seguro que deseas eliminarlo?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrue(visible.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalse()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible2} onHide={handleDeleteFalse2} animation={false}>
                <Modal.Header closeButton className="no_linea_inferior">
                    <Modal.Title className="titulo_login">Editar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {TabsComponent()}
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible3} onHide={handleDeleteFalse3} animation={false}>
                <Modal.Header closeButton className="no_linea_inferior">
                    <Modal.Title className="titulo_login">Agregar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Nombre empresa</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_nombre_empresa" name="nombre_empresa" defaultValue={nombreEmpresa || ''} onKeyUp={OnChangeNombreEmpresa} required="required" /></span>
						    <span className="red_alert">{ errorNombreEmpresa }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Categoría</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_categoria" name="categoria" defaultValue={categoria || ''} onKeyUp={OnChangeCategoria} required="required" /></span>
						    <span className="red_alert">{ errorCategoria }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">RFC</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_nombre_proyecto" name="RFC" defaultValue={RFC || ''} onKeyUp={OnChangeRFC} required="required" /></span>
						    <span className="red_alert">{ errorRFC }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Fecha ingreso</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="date" className="form-control" id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={fechaIngreso || ''}  onChange={OnChangeFechaIngreso} required="required" /></span>
						    <span className="red_alert">{ errorFechaIngreso }</span>
					    </div>
				    </div>

                    <div className="row mt-3">
                        <div className="col-md-3">
                            <span className="txt_listado">Status</span>
                        </div>
                        <div className="col-md-9">
                            <span>
                                <select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
                                    <option value="">Seleccione...</option>
                                    <option value="1">Activo</option>
                                    <option value="2">Inactivo</option>
                                </select>
                            </span>
                            <span className="red_alert">{ errorStatus }</span>
                        </div>
                    </div>
                    <h1 className='subtitulo'>Contacto comercial</h1>

                    <div className="row mt-3">
					    <div className="col-md-3">
						    <span className="txt_listado">Nombre</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_contacto_nombre" name="contacto_nombre" defaultValue={contactoNombre || ''}  onKeyUp={OnChangeContactoNombre}  required="required" /></span>
						    <span className="red_alert">{ errorContactoNombre }</span>
					    </div>
				    </div>

				<div className="row mt-3">
					<div className="col-md-3">
						<span className="txt_listado">Email</span>
					</div>
					<div className="col-md-9">
						<span><input type="text" className="form-control" id="inp_contacto_email" name="contacto_email" defaultValue={contactoEmail || ''}  onKeyUp={OnChangeContactoEmail} required="required" /></span>
						<span className="red_alert">{ errorContactoEmail }</span>
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-3">
						<span className="txt_listado">Puesto</span>
					</div>
					<div className="col-md-9">
						<span><input type="email" className="form-control" id="inp_contacto_puesto" name="contacto_puesto" defaultValue={contactoPuesto || ''}  onKeyUp={OnChangeContactoPuesto} required="required" /></span>
						<span className="red_alert">{ errorContactoPuesto}</span>
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-3">
						<span className="txt_listado">Celular</span>
					</div>
					<div className="col-md-9">
						<span><input type="text" maxLength="10" className="form-control" id="inp_contacto_celular" name="contacto_celular" defaultValue={contactoCelular || ''}  onKeyUp={OnChangeContactoCelular} required="required" /></span>
						<span className="red_alert">{ errorContactoCelular }</span>
					</div>
				</div>



                    <div className="row mt-3 mb-5">
                        <div className="col-md-3">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-md-9">
                            <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmitNew}  /></span>
                        </div>
                    </div>
                    </Container>
                </Modal.Body>
            </Modal>

        </>)
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default Clientes;