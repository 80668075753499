import React, {useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from 'react-loader-spinner';
//------------

//---------------------------------------------------------------

const Parrillas = () =>{

    //-------- hooks

    const [isAuthorized, setIsAuthorized]                   = useState(true)
    const [listadoClientes, setListadoClientes]             = useState([])
    const [loading2, setLoading2]                           = useState(true);
    //-------------------- nuevo data grid -------------------
    
    const [open, setOpen]                                   = useState(false);

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
      return;
      }
      setOpen(false);
    }


    useEffect(() => {

        document.body.style = 'background: #DFDFDF !important;';

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia >0){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
            const retrieveData = () => {
            
                

                trackPromise(

                    instance.get("/clientes-parrillas")
                    .then(response => {
                        //console.log(response.data.data);
                        setIsAuthorized(true);
                        setListadoClientes(response.data.data);
                        
                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
    
                );

               

                
        }

        retrieveData();
        setTimeout(() => setLoading2(false), 1000)

        }
        

    },[])


 
   
    //------------------------ [ end files ] -------------------------------


    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    ///----------------------[  main return  ]---------------------------
    
    if (loading2 !== false) {
        return <>
              <Header />
                <div className="centerDots text-center mt-5" >
                  <Loader type="ThreeDots"  color="#3765f6" height="100" width="100" />
                  <h1 className="loading-final">Cargando...</h1>
                </div>
        </>
      }

     if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized){
        return(
                <>
                <Header />
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        La información se guardo exitosamente.
                    </Alert>
                </Snackbar>
                
                <Container>
                    <div className="row justify-content-center mt-4 mb-5">
                        {
                            listadoClientes.map(post => {
                                const { id, nombre_empresa, logo, parrillas } = post;

                                return (
                                    
                                        <div className="col-lg-4 col-md-6 col-11 rounded py-4" key={id}>
                                            <div className="col-md-11 main-data rounded py-4">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <img src={"https://d2sx5pnhyjgoui.cloudfront.net/images/"+ logo} alt="logo" className="logo-parrillas" />
                                                    </div>
                                                    <div className="col-md-10">
                                                        <h1 className="align-middle txt_name">{nombre_empresa}</h1>
                                                    </div>
                                                </div>
                                                <div className="row text-center">
                                                    <hr className='divide' />
                                                </div>
                                                <div className="row justify-content-center mt-4">
                                                    <div className="col-md-10 nueva-camp py-3 text-center">
                                                        <a className="deco-none" href={"create-parrilla/"+id}><span className="txt_nva_camp"> <AddIcon className="icono_add" /> Crear nueva campaña</span></a>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <ul className="history">
                                                            
                                                            {
                                                                    parrillas && parrillas.map( post => {
                                                                        //const { id, nombre_campana } = post;
                                                                        return (
                                                                            <a className="deco-none" href={"get-parrilla/"+post.id} key={post.id}><li>{post.nombre_campana}</li></a>
                                                                        )
                                                                    })
                                                                    
                                                            }
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                );
                                
                                })
                        }
                    </div>
                </Container>
                </>
                )
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default Parrillas;