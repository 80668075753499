import 'bootstrap/dist/css/bootstrap.min.css'
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
//import {PostData} from './services/PostData';
import './css/Login.css';
import './css/bienvenido.css';
// import Header from './header';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';

const Forgot = () =>{

    const params = useParams();
    const [password, setPassword]                           = useState("");
    const [password2, setPassword2]                         = useState("");
    const [errorCredenciales, setErrorCredenciales]         = useState();
    //const [redirectTrue, setRedirectTrue]                 = useState();
    const [showLoading, setShowLoading]                     = useState({display: "none"});
    const [autorizado, setAutorizado]                       = useState({status: false, mensaje: ""});

    const LoadingIndicator = props => {
        const { promiseInProgress } = usePromiseTracker();
            return promiseInProgress && 
                <div className="centerDots text-center" >
                      <Loader type="ThreeDots"  color="#0f44d6" height="100" width="100" />
                      <h3 style={{color: "#FFF", marginTop: "-20px"}}>Loading...</h3>
                </div>        
    };

    const OnChangePassword = (e) =>{
        setPassword(e.target.value);        
    }

    const OnChangePassword2 = (e) =>{
        setPassword2(e.target.value);        
    }

    const handleSubmit = () => {
        
        if(password){
                
                setShowLoading({display: "block"});
                const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000
                });
    
                trackPromise(
    
                    instance.post('/restore/'+params.email+'/'+params.hash,{
                        password: password,
                        c_password: password2
                    })
                    .then(response => {
                        //console.log(response);
                        if(response.data.success === true){
                            setShowLoading({display: "none"});
                            window.location.href = '/login';
                        }
                        
                    })
                    .catch(e => {
                        setErrorCredenciales("Error en credenciales, por favor verifique la información 🙏");
                        setShowLoading({display: "none"});
                    })
    
                );
                    
        }
        else{
            alert("Error.")
        }


    }


    useEffect(() => {
        
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000
            });

                trackPromise(

                    instance.get('restore/'+params.email+'/'+params.hash)
                    .then(response => {
                        //console.log(response.data.data.status);
                        if(response.data.data.status === 200){
                            setAutorizado({status: true, mensaje: "Auth"})
                        }
                        else{
                            setAutorizado({status: false, mensaje: "Token no válido. Acceso Denegado"})
                        }

                    })
                    .catch(e => {
                        console.log("Expired token - Error #45435");
                        //setIsAuthorized(false);
                    })
    
                );
           
    },[params]);


    if(autorizado.status === true){
        document.body.style = 'background: #1C2833 !important;';
        return (
            <>
            <div className="loading-div text-center" style={showLoading}>
                <LoadingIndicator/> 
            </div>
            {/* <Header /> */}
            <Container>
                <div className="row justify-content-center mt-4" >
                    <div className="col-md-6 MainLogin">
                            <a href="../../"><img alt="Logo" src='../../logo.png' className="logo_bienvenido" /></a>
                        <h1 className="titulo_login_new text-center">Bienvenido a tu empresa digital</h1>
                        <h3 className="error-credenciales mb-3 mt-3">{errorCredenciales}</h3>
                        
                        <h1 className="titulo_login_new text-center mt-5">Cambiar password</h1>

                        <Form.Group className="mt-4" controlId="formBasicPassword">
                            <Form.Label className="label_login">Password</Form.Label>
                            <Form.Control type="password" name="password" placeholder="Password" onKeyUp={OnChangePassword} />
                        </Form.Group>

                        <Form.Group className="mt-4" controlId="formBasicPassword2">
                            <Form.Label className="label_login">Confirmar password</Form.Label>
                            <Form.Control type="password" name="c_password" placeholder="Password" onKeyUp={OnChangePassword2} />
                        </Form.Group>

                        <Button className="btn float-right form-control2"  type="submit" onClick={() => handleSubmit()} >
                            Enviar
                        </Button>
                    </div>
                </div>
            </Container>
            </>
        );

    }
    else{
        
        document.body.style = 'background: #FFF !important;';
        return(
            <>
                <h1 className="text-center mt-5">{autorizado.mensaje}</h1>
            </>
        )
        //return <Redirect to="/login"  />;
    }

   

}

export default Forgot;