import React, {useState, useEffect, useCallback } from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import './css/Login.css';
import Header from './headerInterior';
import DatePicker, {registerLocale} from "react-datepicker";
import es from 'date-fns/locale/es';
import axios from 'axios';
import  { Redirect, useParams } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import {useDropzone} from 'react-dropzone'
import {  faTrash } from "@fortawesome/free-solid-svg-icons"; //faHome,
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
//import Loader from 'react-loader-spinner';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import Button  from '@mui/material/Button';


registerLocale('es', es);

const { DateTime } = require("luxon");

const GetParilla = () =>{

    // ---------------------------------------- [ hooks ] ----------------------------------------
    const weekNumber = DateTime.now().weekNumber;
    const yearNumber = DateTime.now().year;
    
    const { id_parrilla }                                   = useParams()
    const [isAuthorized, setIsAuthorized]                   = useState(true)
    const [logoCliente, setLogoCliente]                     = useState(null)
    const [visible , setVisible]                            = useState(false)
    const [visibleEditar , setVisibleEditar]                = useState(false)
    const [visibleEliminar , setVisibleEliminar]            = useState(false)
    const [btnDisabled, setBtnDisabled]                     = useState(false)
    const [selectedWeek, setSelectedWeek]                   = useState(weekNumber)
    const [selectedYear, setSelectedYear]                   = useState(yearNumber)
    const [showRange, setShowRange]                         = useState();
    const [arrayDays]                                       = useState([])
    const [actualPosts, setActualPosts]                     = useState("")
    const [loading2, setLoading2]                           = useState(true);

    const [loadingUploading, setLoadingUploading]           = useState(false)
    const [activeFiles, setActiveFiles ]                    = useState([]); 

    const [copyOut, setCopyOut]                             = useState("")
    const [copyIn, setCopyIn]                               = useState("")
    const [descripcion, setDescripcion]                     = useState("")
    const [fechaPublicacion, setFechaPublicacion]           = useState("")
    const [horaPublicacion, setHoraPublicacion]             = useState("")
    const [visibilidad, setVisibilidad]                     = useState("all")

    // ------ edit
    const [copyOutEdit, setCopyOutEdit]                     = useState("")
    const [copyInEdit, setCopyInEdit]                       = useState("")
    const [descripcionEdit, setDescripcionEdit]             = useState("")
    const [fechaPublicacionEdit, setFechaPublicacionEdit]   = useState("")
    const [horaPublicacionEdit, setHoraPublicacionEdit]     = useState("")
    const [visibilidadEdit, setVisibilidadEdit]             = useState("all")
    const [idEdit, setIdEdit]                               = useState("")
    const [random]                                          = useState(Math.round((Math.random()*100000)+1))

    //---- snackbar
    const [open, setOpen]                                   = useState(false);
    
    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    }

    const handleDeleteFalse = () => {
        setVisibleEliminar(false);
    }

    const handleDeleteTrue = () => { 
        //alert(idEdit+" se va a la berga")

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.delete('/posteo/'+idEdit)
            .then(response => {
                //console.log(response)
                if(response.status === 200){
                    
                    setActualPosts([])
                    // ---------------------------------------------------------------
                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);
                    
                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    trackPromise(
                        instance.get('/get-rango-fechas/'+yearNumber+'/'+weekNumber+'/'+id_parrilla) //get-rango-fechas/2022/4/4
                        .then(response => {
                            //console.log(response.data.data)
                            setActualPosts(response.data.data)
                            setLoading2(false)
                        })
                        .catch(e => {
                            setIsAuthorized(false);
                            console.log("Expired token - Error #45435");
                        })
                
                    )
                    // ---------------------------------------------------------------

                    setVisible(false)
                    setVisibleEliminar(false)
                    setVisibleEditar(false)
                }
            })
            .catch(e => {
                console.log(e);
            })
        );

    }

    const openNewModal = () => {
        setVisible(true)
    }

    const openDeleteModal = (id) => {
        setVisibleEliminar(true)
    }

    const handleCheckDateEdit = (date) => {
        setFechaPublicacionEdit(date.getFullYear()+"-"+date.getMonth()+1+"-"+date.getDate())
    }

    const handleCheckDate = (date) => {
        setFechaPublicacion(date.getFullYear()+"-"+date.getMonth()+1+"-"+date.getDate())
    }

    // ----------

    const OnChangeCopyOut = (e) =>{
        setCopyOut(e.target.value);        
    }

    const OnChangeCopyIn = (e) =>{
        setCopyIn(e.target.value);        
    }

    const OnChangeDescripcion = (e) =>{
        setDescripcion(e.target.value);        
    }

    const OnChangeHoraPublicacion = (e) =>{
        setHoraPublicacion(e.target.value);        
    }

    const OnChangeVisibilidad = (e) =>{
        setVisibilidad(e.target.value);        
    }

    // ---------- end

    // --------------------- edit

    const OnChangeCopyOutEdit = (e) =>{
        setCopyOutEdit(e.target.value);        
    }

    const OnChangeCopyInEdit = (e) =>{
        setCopyInEdit(e.target.value);        
    }

    const OnChangeDescripcionEdit = (e) =>{
        setDescripcionEdit(e.target.value);        
    }

    const OnChangeHoraPublicacionEdit = (e) =>{
        setHoraPublicacionEdit(e.target.value);        
    }

    const OnChangeVisibilidadEdit = (e) =>{
        setVisibilidadEdit(e.target.value);        
    }

    // --------------------------

    const closeNewModal = () => {
        setVisible(false)
    }

    const closeEditModal = () => {
        setVisibleEditar(false)
    }

    const openEditModal = (id) => {
        
        // ------
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });
            
        trackPromise(

            instance.get('/posteo/'+id)
            .then(response => {
                //console.log(response.data.data)
                //setPosteoActual(response.data.data)
                setIdEdit(id)
                setCopyInEdit(response.data.data.copy_in)
                setCopyOutEdit(response.data.data.copy_out)
                setDescripcionEdit(response.data.data.referencias)
                
                //console.log(DateTime.fromISO(response.data.data.fecha_publicacion))
                //console.log(DateTime.fromISO(response.data.data.fecha_publicacion))
                //console.log(DateTime.fromISO(response.data.data.fecha_publicacion).toFormat('yyyy-MM-dd'))
                //console.log(new Date())
                //setFechaPublicacionEdit(response.data.data.fecha_publicacion)
                var ini = response.data.data.fecha_publicacion;
                var dateParts = ini.split("-")
                var jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0,2))
                var year = jsDate.getFullYear()
                var month = jsDate.getMonth()+1
                month = month.toString().padStart(2, "0")
                var day = jsDate.getDate()
                var all = year + "-" + month + "-" + day
                //console.log(all)
                setFechaPublicacionEdit(all)
                setHoraPublicacionEdit(response.data.data.hora_publicacion)
                setVisibleEditar(true)
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })
    
        )
    }

    const uploadPost = () => {

        //alert(fechaPublicacion)
        setActualPosts([])

        let payload = {
            id_parrilla: id_parrilla,
            plataforma: "facebook",
            tipo: "regular_post",
            copy_out: copyOut,
            copy_in: copyIn,
            referencias: descripcion,
            fecha_publicacion: fechaPublicacion,
            hora_publicacion: horaPublicacion,
            visibilidad: visibilidad,
            random: random,
            status: 1
        }
        
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.post('/posteos', payload)
            .then(response => {
                if(response.status === 200){
                    //console.log(response.data.data.id)
                    setActualPosts([])
                    // ---------------------------------------------------------------
                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);
                    
                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    trackPromise(
                        instance.get('/get-rango-fechas/'+yearNumber+'/'+weekNumber+'/'+id_parrilla) //get-rango-fechas/2022/4/4
                        .then(response => {
                            //console.log(response.data.data)
                            setActualPosts(response.data.data)
                            setLoading2(false)
                        })
                        .catch(e => {
                            setIsAuthorized(false);
                            console.log("Expired token - Error #45435");
                        })
                
                    )
                    // ---------------------------------------------------------------

                    setVisible(false)
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const updatePost = () => {
        

        //alert(random)
        setActualPosts([])

        let payload = {
            id_parrilla: id_parrilla,
            plataforma: "facebook",
            tipo: "regular_post",
            copy_out: copyOutEdit,
            copy_in: copyInEdit,
            referencias: descripcionEdit,
            fecha_publicacion: fechaPublicacionEdit,
            hora_publicacion: horaPublicacionEdit,
            visibilidad: visibilidadEdit,
            status: 1
        }

        
        
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.put('/posteo/'+idEdit, payload)
            .then(response => {
                if(response.status === 200){
                    setOpen(true)
                    
                    setVisibleEditar(false)
                    // ---------
                    // ---------------------------------------------------------------
                    
                    trackPromise(
                        instance.get('/get-rango-fechas/'+yearNumber+'/'+DateTime.fromISO(fechaPublicacionEdit).weekNumber+'/'+id_parrilla) //get-rango-fechas/2022/4/4
                        .then(response => {
                            //console.log(response.data.data)
                            //setActualPosts([])
                            setActualPosts(response.data.data)
                            setLoading2(false)
                        })
                        .catch(e => {
                            setIsAuthorized(false);
                            console.log("Expired token - Error #45435");
                        })
                
                    )
                    // ---------------------------------------------------------------
                    // ---------
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
        
    }

    const renderWeekInitial = () => {
        
        //setActualPosts([])
        const yearNumber = selectedYear;
        const weekNumber = selectedWeek;
        //console.log(selectedWeek-1)
        const dt = DateTime.fromObject({
            weekYear: yearNumber,
            weekNumber: weekNumber
        });
        
        const dateFromStr = dt.startOf('week');
        const dateToStr = dt.endOf('week');

        setShowRange(capitalizeFirstLetter(dateFromStr.monthShort) + " " + dateFromStr.day + " - "+capitalizeFirstLetter(dateToStr.monthShort) + " " + dateToStr.day + ", " + dateToStr.year); 

        // Lllenado De Días ------------------------------------------
        
        for (let index = 1; index <= 7; index++) {
            const dt = DateTime.fromObject({
                weekYear: yearNumber,
                weekNumber: weekNumber,
                weekday: index
            });
            arrayDays.push(capitalizeFirstLetter(dt.weekdayLong) + " " + dt.day);
        }
        //console.log(arrayDays)

    }
    
    useEffect(() => {

        setActualPosts([])

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
            const retrieveData = () => {

                //renderWeekInitial()
                trackPromise(

                    instance.get('/get-parrilla/'+id_parrilla)
                    .then(response => {
                        
                        // --------- init
                        trackPromise(

                            instance.get('/clientes/'+response.data.data.id_cliente)
                            .then(response2 => {
                                
                                setLogoCliente(response2.data.data.logo)
                                //console.log(response.data.id_cliente)
            
                            })
                            .catch(e => {
                                setIsAuthorized(false);
                                console.log("Expired token - Error #45435");
                            })
            
                        );
                        // ---------- end

                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
    
                )

                trackPromise(
                    instance.get('/files/show/posts/'+random)
                    .then(response => {
                        //console.log(response.data.data.data);
                        //console.log(response.data);
                        setActiveFiles(response.data.data.data);
                        setLoadingUploading(false);
                    })
                    .catch(e => {
                        console.log(e);
                    })
                );

                trackPromise(
                    instance.get('/get-rango-fechas/'+selectedYear+'/'+selectedWeek+'/'+id_parrilla) //get-rango-fechas/2022/4/4
                    .then(response => {
                        //console.log(response.data.data)
                        setActualPosts(response.data.data)
                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
            
                )

                
                
            }

            renderWeekInitial()
            retrieveData();
            setTimeout(() => setLoading2(false), 1000)

        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id_parrilla, random]);


    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    })

    //------------------------- [ files ] ---------------------------------

    const UpdateFilesFromServer = () =>{
        //alert(id);
        setLoadingUploading(true);
        setActiveFiles([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

          trackPromise(
            instance.get('/files/show/posts/'+random)
            .then(response => {
                //console.log(response.data.data.data);
                //console.log(response.data);
                setActiveFiles(response.data.data.data);
                setLoadingUploading(false);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const deleteFile = (id) => {

        setBtnDisabled(false)
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
             instance.delete('files/delete/'+id)
            .then(response => {
                //console.log(response.data.data);
                if(response.status === 200){
                    UpdateFilesFromServer()
                    setBtnDisabled(true)
                }
            })
              .catch(e => {
                console.log(e);
              })
        );

    }

    const MyDropzone = () =>{

        const onDrop = useCallback(acceptedFiles => {

            // ------------- Logica de AWS ----------------
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);
        
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 100000,
                headers: {'Authorization': 'Bearer '+userData.token, "content-type": 'multipart/form-data'}
            });
            
            let formd = new FormData();
            formd.append("filename", acceptedFiles[0], acceptedFiles[0].name);
            formd.append("id_post",random);
            trackPromise(
                
                instance.post('/upload-files', formd)
                .then(response => {
                    if(response.data.success === true){
                        UpdateFilesFromServer();
                    }
                    else{
                        alert("Error #44344");
                    }
    
                })
                .catch(e => {
                    alert(e);
                })
    
            );

            // ----------- END Logica de AWS --------------
          
        }, [])

        
        const { getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
        //console.log(activeFiles);
        if(activeFiles.length < global.config.server_endpoint.maxFilesEmployee){

            return (
            <>
                <span className='title_plataforma'>Subir imagen</span>
                <div {...getRootProps()} style={{border: "2px dashed #DFDFDF", borderRadius: "20px", marginTop: "10px", paddingTop: "20px", textAlign: "center"}}>
                    <input {...getInputProps()} />
                    {
                    isDragActive ?
                        <p className="limite_empleado">Dejelos caer aquí</p> :
                        <p className="title_plataforma mt-3 mb-5">Arrastra una imágen o<br /> súbela desde tu escritorio</p>
                    }
                </div>
            </>
            )
        }
        else{
            return(
                <div className="text-center limite_empleado">Se ha alcanzado el límite de archivos por empleado</div>
            )
        }
    }

    const nextWeek = () => {
        
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()

        //console.log(selectedWeek)

        if(selectedWeek<52){
            setSelectedWeek(selectedWeek+1)
        }
        else{
            setSelectedWeek(1)
            setSelectedYear(selectedYear+1)
        }

        RenderWeekNext()
        //console.log(arrayDays)
    }

    const PrevWeek = () => {
        
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        arrayDays.shift()
        //console.log(selectedWeek)

        if(selectedWeek === 1){
            setSelectedWeek(52)
            setSelectedYear(selectedYear-1)
        }
        else{
            setSelectedWeek(selectedWeek-1)
        }
        //alert(selectedWeek.current)
        
        //()
        RenderWeekPrev()

    }

    function capitalizeFirstLetter(string) {
        if(string){
            string = string.replace('.','');
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
            
    }

    const RenderWeekNext = () => {
        
        setLoading2(true)
        setActualPosts([])

        let yearNumber = selectedYear;
        let weekNumber = selectedWeek+1;

        const ff = DateTime.fromObject({
            //year: yearNumber,
            year: selectedYear,
            month: 12,
            day: 31
        });

        if(weekNumber-1 === ff.weekNumber){
            yearNumber = selectedYear+1;
            weekNumber = 1;
        }


        // ---------------- UPDATE POSTS 

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);
        
        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('/get-rango-fechas/'+yearNumber+'/'+weekNumber+'/'+id_parrilla) //get-rango-fechas/2022/4/4
            .then(response => {
                //console.log(response.data.data)
                setActualPosts(response.data.data)
                setLoading2(false)
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })
    
        )
        // ------------------------ END UPDATE POSTS


        const dt = DateTime.fromObject({
            weekYear: yearNumber,
            weekNumber: weekNumber
        });
        
        const dateFromStr = dt.startOf('week');
        const dateToStr = dt.endOf('week');

        setShowRange(capitalizeFirstLetter(dateFromStr.monthShort) + " " + dateFromStr.day + " - "+capitalizeFirstLetter(dateToStr.monthShort) + " " + dateToStr.day + ", " + dateToStr.year); 

        // Lllenado De Días ------------------------------------------
        
        for (let index = 1; index <= 7; index++) {
            const dt = DateTime.fromObject({
                weekYear: yearNumber,
                weekNumber: weekNumber,
                weekday: index
            });
            arrayDays.push(capitalizeFirstLetter(dt.weekdayLong) + " " + dt.day);
        }
        //console.log(arrayDays)

    }

    const RenderWeekPrev = () => {
        
        setLoading2(true)
        setActualPosts([])
        //console.log(selectedWeek)
        let yearNumber = selectedYear;
        let weekNumber = selectedWeek-1;
        
        if(weekNumber === 0){
            yearNumber = selectedYear-1;
            weekNumber = 52;
        }

        // ---------------- UPDATE POSTS 

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);
        
        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('/get-rango-fechas/'+yearNumber+'/'+weekNumber+'/'+id_parrilla) //get-rango-fechas/2022/4/4
            .then(response => {
                //console.log(response.data.data)
                setActualPosts(response.data.data)
                setLoading2(false)
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })
    
        )
        // ------------------------ END UPDATE POSTS
            

        //console.log(selectedWeek-1)
        const dt = DateTime.fromObject({
            weekYear: yearNumber,
            weekNumber: weekNumber
        });
        
        const dateFromStr = dt.startOf('week');
        const dateToStr = dt.endOf('week');

        setShowRange(capitalizeFirstLetter(dateFromStr.monthShort) + " " + dateFromStr.day + " - "+capitalizeFirstLetter(dateToStr.monthShort) + " " + dateToStr.day + ", " + dateToStr.year); 

        // Lllenado De Días ------------------------------------------
        
        for (let index = 1; index <= 7; index++) {
            const dt = DateTime.fromObject({
                weekYear: yearNumber,
                weekNumber: weekNumber,
                weekday: index
            });
            arrayDays.push(capitalizeFirstLetter(dt.weekdayLong) + " " + dt.day);
        }
        //console.log(arrayDays)

        

    }

    const Loading = () => {
        if (loading2 !== false) {
            return (<div className='col-md-12'>
                            <div className="centerDots text-center" >
                                <Loader type="ThreeDots"  color="#3765f6" height="100" width="100" />
                                <h1 className="loading-final">Cargando...</h1>
                            </div>
                        </div>)
          }
    }

    const PrintCal = () =>{

        return(
                <>
                <div className='row calendario_slo'>
                    <div className='col-12 pt-3 pb-3 text-center'>
                            <div className='fecha_calendar'>
                            <ArrowBackIosIcon className='arrow_left_cal' onClick={() => PrevWeek()} /> { showRange } <ArrowForwardIosIcon className='arrow_right_cal' onClick={() => nextWeek()} />
                            </div> 
                    </div>
                </div>
               <div className='row calendario_main'>
               {
                    arrayDays && arrayDays.map(post => {
                        
                        return (
                            <div className='col pt-3 pb-3 text-center' key={post}>
                                <div className='fecha_calendar text-center'>
                                    {post}
                                </div> 
                            </div>
                        );
                        
                    })
                }    
                </div>
                <div className='row pb-5 calendario_content foot_tbl'>
                {Loading()}
                {
                    actualPosts && actualPosts.map( postM => {
                        
                        return(
                                <div className='col pt-3 pb-3 text-center linea_lateral pb-5' key={Math.random()}>
                                    <div className='fecha_calendar text-center mt-3'>
                                        {
                                            postM && postM.map(element => {
                                                
                                                if(element.plataforma === "facebook"){
                                                    console.log()
                                                    if(element.files[0]){
                                                        return (
                                                            <div className='container_post' key={element.id} onClick={() => openEditModal(element.id)}>
                                                                <img src={"https://d2sx5pnhyjgoui.cloudfront.net/files/"+element.files[0].filename || "user.png"} alt="posteo" className='posteo' />
                                                                <br />
                                                                <FacebookIcon className="icon_fb" />
                                                            </div>
                                                            )
                                                    }
                                                    else{
                                                        return (
                                                            <div className='container_post' key={element.id} onClick={() => openEditModal(element.id)}>
                                                                <img src="./../user.png" alt="posteo" className='posteo' />
                                                                <br />
                                                                <FacebookIcon className="icon_fb" />
                                                            </div>
                                                            )
                                                    }
                                                    
                                                }
                                                else if(element.plataforma === "instagram"){
                                                    return (
                                                            <div className='container_post' key={element.id}>
                                                                <img src={"https://d2sx5pnhyjgoui.cloudfront.net/files/"+element.files[0].filename || "user.png"} alt="posteo" className='posteo' />
                                                                <br />{element.copy_out}
                                                                <InstagramIcon className="icon_ig" />
                                                            </div>
                                                            )
                                                }
                                                else{
                                                    return( <>
                                                        <h3>none</h3>
                                                    </>)
                                                }
                                                
                                            })
                                        }
                                        {
                                            postM<1 && (
                                                <>
                                                <h4 className='nohaycontenido'>No hay contenido</h4>
                                                </>
                                                
                                            )
                                        }
                                    </div> 
                                </div>
                            )   
                    })
                }

                
                   
                </div>
                </>
        )
    }

    ///----------------------[  main return  ]---------------------------
    
     if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized){
        return(<>
                <Header />
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        La información se guardo exitosamente.
                    </Alert>
                </Snackbar>
                <Container fluid className="mr-inf macro">
                    <div className="row">
                        <div className="col-6">
                            <div className="titulo_login_regresar">
                                <a href="../parrillas">
                                    <ArrowBackIosIcon className='arrow_left' /> Regresar a todas tus campañas
                                </a>
                            </div>
                        </div>
                        <div className="col-6 text-right">
                            <div className="titulo_login_fechas pr-4">
                                <DateRangeIcon /> Nov 25, 2021
                                <FilterAltIcon className='icono_left' /> Filtros
                                <SearchIcon className='icono_left' />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-2">
                            <div className="row">
                                <div className='col-md-12 text-center mt-5 mb-5'>
                                {
                                    logoCliente && (
                                        <img src={"https://d2sx5pnhyjgoui.cloudfront.net/images/"+logoCliente} alt="logo_cliente" className='img-rounded_main' />
                                    )
                                }
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-12'>
                                        <h1 className='parrilla_name'>VIVANUNCIOS MEXICO</h1>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className='col-md-10 mt-3 mb-3'>
                                        <h1 className='parrilla_pages'>Tus páginas</h1>
                                        <ul className='laterale'>
                                            <li><img src="./../iconos/fb.png" className='img-rounded3' alt="facebook" /> <span className="">Vivanuncios</span></li>
                                            <li><img src="./../iconos/ig.png" className='img-rounded3' alt="instagram" /> <span className="">@Vivanuncios</span></li>
                                            <li><img src="./../iconos/tt.png" className='img-rounded3' alt="tiktok" /> <span className="">@Vivanuncios</span></li>
                                        </ul>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className='col-md-10 mt-3'>
                                        <h1 className='parrilla_pages'>Usuarios</h1>
                                        <ul className='laterale'>
                                            <li><img src="./../user.jpg" className='img-rounded2' alt="facebook" /><img src="./../user.png" className='img-rounded2' alt="facebook" /></li>
                                        </ul>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-3">
                                <div className='col-md-10 mt-3 mb-5'>
                                        <button className='btn nvo_contenido' onClick={() => openNewModal()} >NUEVO CONTENIDO</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-10">
                            
                            { PrintCal() }
                              
                        </div>
                    </div>

            </Container>
            
            {/* Modal nuevo post */}
            <Modal size="xl" show={visible} onHide={closeNewModal} animation={false}>
                <Modal.Header closeButton className="no-line-footer backed_light">
                    <Modal.Title className="titulo_nvo_posteo">Nueva publicación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="col-md-12 mt-3">
                                <span className='title_plataforma'>Elige para que Red social es tu contenido </span>
                                <img src="./../iconos/fb.png" className='img-rounded3 ml-3' alt="facebook" />
                                <img src="./../iconos/ig.png" className='img-rounded3 ml-3 disabled_icon' alt="instagram" />
                                <img src="./../iconos/tt.png" className='img-rounded3 ml-3 mr-4 disabled_icon' alt="Tik Tok" />
                                |
                                <span className='ml-4 title_plataforma'>Post</span>
                                <span className='ml-4 title_plataforma'>Story</span>
                                <span className='ml-4 title_plataforma'>Album</span>
                                <span className='ml-4 title_plataforma'>Carrusel</span>

                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-12 mt-3">
                                <hr></hr>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-8">
                                <Row>
                                    <Col className='col-md-12'>
                                        <label className='title_plataforma' htmlFor="copy_out_inp">Copy out</label>
                                        <input type='text' id="copy_out_inp" className='form-control texto_form' placeholder="Escribe el copy out de tu publicación" defaultValue={copyOut || ''} onKeyUp={OnChangeCopyOut} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-12 mt-3'>
                                        <label className='title_plataforma' htmlFor="copy_in_inp">Copy in</label>
                                        <input type='text' id="copy_in_inp" className='form-control texto_form' placeholder="Escribe el copy in de tu publicación" defaultValue={copyIn || ''} onKeyUp={OnChangeCopyIn} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-12 mt-3'>
                                        <label className='title_plataforma' htmlFor="copy_in_inp">Descripción y referencias</label>
                                        <input type='text' id="copy_in_inp" className='form-control texto_form' placeholder="https://drive.google.com" defaultValue={descripcion || ''} onKeyUp={OnChangeDescripcion} />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col className='col-md-12'>
                                        <MyDropzone />

                                        <Row className="justify-content-center mt-4 mb-3">
                                            <Col className='col-md-12'>
                                            <h2 className='title_plataforma'>Imágenes subidas</h2>
                                            {
                                                loadingUploading &&
                                                    <>
                                                    <div className="text-center">
                                                        <Loader type="ThreeDots"  color="#2c63ff" height="100" width="100" />
                                                        <h1 className="loading-final">Cargando...</h1>
                                                    </div>
                                                    </>
                                            }
                                            {
                                                activeFiles &&
                                                activeFiles.length<1 &&
                                                    <h4 className="text-center mt-4 no_hay">No hay imágenes disponibles</h4>
                                            }
                                            {
                                                activeFiles &&
                                                activeFiles.map(post => {
                                                    
                                                        const { id, filename, created_at} = post;
                                                        //let cut = created_at.split("T");
                                                        let donde = "https://d2sx5pnhyjgoui.cloudfront.net/files/"+filename;
                                                    
                                                        return (
                                                            <div className="row mt-2" key={id}>
                                                                <div className="col-md-6">
                                                                    <span className="txt_listado"><a href={donde} target="_blank" rel="noreferrer">{filename}</a></span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span className="txt_listado">{created_at}</span>
                                                                </div>
                                                                <div className="col-md-3 text-right">
                                                                    <Button variant="contained" color="error" onClick={() => deleteFile(id)} disabled={btnDisabled}><FontAwesomeIcon icon={faTrash} className="icono_trash"  /></Button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                            }
                                            </Col>
                                        </Row>
                                    
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col-md-4">
                                <Row>
                                    <Col className='col-md-12'>
                                        <label className='title_plataforma' htmlFor="copy_out_inp">Fecha de publicación propuesta</label>
                                        <DatePicker
                                          //minDate={new Date()}
                                          onChange={handleCheckDate}
                                          placeholderText={fechaPublicacion}
                                          className="form-control texto_form"
                                          dateFormat="yyyy-MM-dd"
                                          locale="es"
                                        />
                                        {/* <input type='text' id="copy_out_inp" className='form-control texto_form' placeholder="Escribe el copy out de tu publicación" defaultValue={fechaPublicacion || ''} onKeyUp={OnChangeFechaPublicacion} /> */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-12 mt-3'>
                                        <label className='title_plataforma' htmlFor="copy_out_inp">Hora de publicación propuesta</label>
                                        <input type='text' id="copy_out_inp" className='form-control texto_form' placeholder="Hora de publicación" defaultValue={horaPublicacion || ''} onKeyUp={OnChangeHoraPublicacion} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-12 mt-3'>
                                        <span className='title_plataforma'>Visibilidad</span>
                                        <br />
                                        <input type='radio' name="visibilidad" value="all" defaultChecked onClick={ OnChangeVisibilidad} /> <span className='title_plataforma_light'>Visible para todos</span>
                                        <br />
                                        <input type='radio' name="visibilidad" value="members_only" onClick={ OnChangeVisibilidad} /> <span className='title_plataforma_light'>Solo miembros del equipo</span>
                                        <Row>
                                            <div className='col-md-12 mt-3 mb-5'>
                                                {/* <button className='btn nvo_contenido btn-block' onClick={() => openNewModal()} >NUEVO CONTENIDO</button> */}
                                                <button className='btn nvo_contenido btn-block' onClick={() => uploadPost()} >AGREGAR PUBLICACIÓN</button>
                                                <button className='btn nvo_contenido_bco btn-block' ><RemoveRedEyeIcon className="icon_eye" /> VISTA PREVIA</button>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            {/* Modal editar post */}
            <Modal size="xl" show={visibleEditar} onHide={closeEditModal} animation={false}>
                <Modal.Header closeButton className="no-line-footer backed_light">
                    <Modal.Title className="titulo_nvo_posteo">Editar publicación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="col-md-12 mt-3">
                                <span className='title_plataforma'>Elige para que Red social es tu contenido </span>
                                <img src="./../iconos/fb.png" className='img-rounded3 ml-3' alt="facebook" />
                                <img src="./../iconos/ig.png" className='img-rounded3 ml-3 disabled_icon' alt="instagram" />
                                <img src="./../iconos/tt.png" className='img-rounded3 ml-3 mr-4 disabled_icon' alt="Tik Tok" />
                                |
                                <span className='ml-4 title_plataforma'>Post</span>
                                <span className='ml-4 title_plataforma'>Story</span>
                                <span className='ml-4 title_plataforma'>Album</span>
                                <span className='ml-4 title_plataforma'>Carrusel</span>

                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-12 mt-3">
                                <hr></hr>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-8">
                                <Row>
                                    <Col className='col-md-12'>
                                        <label className='title_plataforma' htmlFor="copy_out_inp">Copy out</label>
                                        <input type='text' id="copy_out_inp" className='form-control texto_form' placeholder="Escribe el copy out de tu publicación" defaultValue={copyOutEdit || ''} onKeyUp={OnChangeCopyOutEdit} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-12 mt-3'>
                                        <label className='title_plataforma' htmlFor="copy_in_inp">Copy in</label>
                                        <input type='text' id="copy_in_inp" className='form-control texto_form' placeholder="Escribe el copy in de tu publicación" defaultValue={copyInEdit || ''} onKeyUp={OnChangeCopyInEdit} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-12 mt-3'>
                                        <label className='title_plataforma' htmlFor="copy_in_inp">Descripción y referencias</label>
                                        <input type='text' id="copy_in_inp" className='form-control texto_form' placeholder="https://drive.google.com" defaultValue={descripcionEdit || ''} onKeyUp={OnChangeDescripcionEdit} />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col className='col-md-12'>
                                        <MyDropzone />

                                        <Row className="justify-content-center mt-4 mb-3">
                                            <Col className='col-md-12'>
                                            <h2 className='title_plataforma'>Imágenes subidas</h2>
                                            {
                                                loadingUploading &&
                                                    <>
                                                    <div className="text-center">
                                                        <Loader type="ThreeDots"  color="#2c63ff" height="100" width="100" />
                                                        <h1 className="loading-final">Cargando...</h1>
                                                    </div>
                                                    </>
                                            }
                                            {
                                                activeFiles &&
                                                activeFiles.length<1 &&
                                                    <h4 className="text-center mt-4 no_hay">No hay imágenes disponibles</h4>
                                            }
                                            {
                                                activeFiles &&
                                                activeFiles.map(post => {
                                                    
                                                        const { id, filename, created_at} = post;
                                                        //let cut = created_at.split("T");
                                                        let donde = "https://d2sx5pnhyjgoui.cloudfront.net/files/"+filename;
                                                    
                                                        return (
                                                            <div className="row mt-2" key={id}>
                                                                <div className="col-md-6">
                                                                    <span className="txt_listado"><a href={donde} target="_blank" rel="noreferrer">{filename}</a></span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span className="txt_listado">{created_at}</span>
                                                                </div>
                                                                <div className="col-md-3 text-right">
                                                                    <Button variant="contained" color="error" onClick={() => deleteFile(id)} disabled={btnDisabled}><FontAwesomeIcon icon={faTrash} className="icono_trash"  /></Button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                            }
                                            </Col>
                                        </Row>
                                    
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col-md-4">
                                <Row>
                                    <Col className='col-md-12'>
                                        <label className='title_plataforma' htmlFor="copy_out_inp">Fecha de publicación propuesta</label>
                                        <DatePicker
                                          //selected={DateTime.now()}
                                          //minDate={new Date()}
                                          onChange={handleCheckDateEdit}
                                          className="form-control texto_form"
                                          //placeholderText="Fecha de publicación"
                                          placeholderText={fechaPublicacionEdit}
                                          dateFormat="yyyy-MM-dd"
                                          locale="es"
                                        />
                                        {/* <input type='text' id="copy_out_inp" className='form-control texto_form' placeholder="Escribe el copy out de tu publicación" defaultValue={fechaPublicacionEdit || ''} onKeyUp={OnChangeFechaPublicacionEdit} /> */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-12 mt-3'>
                                        <label className='title_plataforma' htmlFor="copy_out_inp">Hora de publicación propuesta</label>
                                        <input type='text' id="copy_out_inp" className='form-control texto_form' placeholder="Escribe el copy out de tu publicación" defaultValue={horaPublicacionEdit || ''} onKeyUp={OnChangeHoraPublicacionEdit} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-12 mt-3'>
                                        <span className='title_plataforma'>Visibilidad</span>
                                        <br />
                                        <input type='radio' name="visibilidad" value="all" defaultChecked={visibilidadEdit !== "members_only"}  onClick={ OnChangeVisibilidadEdit } /> <span className='title_plataforma_light'>Visible para todos</span>
                                        <br />
                                        <input type='radio' name="visibilidad" value="members_only" defaultChecked={visibilidadEdit === "members_only"} onClick={ OnChangeVisibilidadEdit } /> <span className='title_plataforma_light'>Solo miembros del equipo</span>
                                        <Row>
                                            <div className='col-md-12 mt-3 mb-5'>
                                                {/* <button className='btn nvo_contenido btn-block' onClick={() => openNewModal()} >NUEVO CONTENIDO</button> */}
                                                <button className='btn nvo_contenido btn-block' onClick={() => updatePost()} >GUARDAR PUBLICACIÓN</button>
                                                <button className='btn nvo_contenido_bco btn-block' ><RemoveRedEyeIcon className="icon_eye" /> VISTA PREVIA</button>
                                                <button className='btn nvo_contenido_rjo btn-block' onClick={() => openDeleteModal()} ><DeleteIcon className="icon_trash"/> ELIMINAR</button>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            <Modal size="lg" show={visibleEliminar} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">¿Estas seguro que deseas eliminarlo?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrue(visible.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalse()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


        </>)
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default GetParilla;