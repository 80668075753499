import 'bootstrap/dist/css/bootstrap.min.css'
import React, {Component} from 'react';
import './css/Somos.css';
import Header from './header';
import  { Redirect } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import axios from 'axios';
//import Alert from '@material-ui/lab/Alert';
import { Alert } from '@mui/material';
//import Button from '@material-ui/core/Button';
//import { useParams } from "react-router";

class AddUser extends Component {

constructor(){
	super();
	this.state = {
		id_cuenta: '',
		name: '',
		email: '',
		password: '',
		jerarquia: '',
		redirectToReferrer: false,
		windowState: false,
		errorNombre: '',
		errorPaterno: '',
		errorMaterno: '',
		errorEmail: '',
		errorCelular: '',
		redirectClientes: false,
		noAuth: false
	};
	
	this.enviarForm 	= this.enviarForm.bind(this);
	this.onChange 		= this.onChange.bind(this);
	this.changeStatus 	= this.changeStatus.bind(this);
	this.clearForm 		= this.clearForm.bind(this);
}


/*checkJerarquia(){
	let ud       			= sessionStorage.getItem('userData');
   let userData 			= JSON.parse(ud);
   let id_empresa_ok 	= userData.id_cuenta;
   let jerarquia_sys 	= userData.jerarquia;

   if(jerarquia_sys>2){
   	this.setState({noAuth: true});
   }
}
*/


async enviarForm() {

	let erroresForm = false;

	/* **************************    VALIDACIONES    ****************************** */

	
	//-------------------[		validate inp_nombres	]----------------
	//if(this.state.name.match(/^[a-zA-Z ]+$/) == null){
	if(this.state.name.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u) == null){
		//console.log("Error: solo letras");
		this.setState( { errorNombre: "Error: solo letras" } );
		erroresForm = true;
	}
	else{
		this.setState( { errorNombre: "" } );
	}


	//-------------------[		validate email pt 1		]----------------
	if(!this.state.email){
       this.setState( { errorEmail: "Error: email vacío" } );
       erroresForm = true;
    }
    else{
    	this.setState( { errorEmail: "" } );
    }

    //-------------------[		validate email pt 2		]----------------
    if(typeof this.state.email !== "undefined"){
       let lastAtPos = this.state.email.lastIndexOf('@');
       let lastDotPos = this.state.email.lastIndexOf('.');

       if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
          this.setState( { errorEmail: "Error: email no válido" } );
          erroresForm = true;
        }
        else{
        	this.setState( { errorEmail: "" } );
        }
   }  


   //-------------------[		validate jerarquia	]----------------
	if(this.state.jerarquia !== ""){
			this.setState( { errorJerarquia: "" } );
	}
	else{
		//console.log("Error: solo letras");

		this.setState( { errorJerarquia: "Error: por favor seleccionar algun concepto válido" } );
		erroresForm = true;

	}


	//-------------------[		validate password 	]----------------
	if(this.state.password !== "" && this.state.password.length>7){
		this.setState( { errorPassword: "" } );
	}
	else{
		//console.log("Error: solo letras");
		this.setState( { errorPassword: "Error: campo vacio o con menos de 8 caracteres" } );
		erroresForm = true;
	}


	//-------------------[		validate password 2 	]----------------
	if(this.state.password2 !== "" && (this.state.password === this.state.password2)){
		this.setState( { errorPassword2: "" } );
	}
	else{
		//console.log("Error: solo letras");
		this.setState( { errorPassword2: "Error: el password no coincide con el password de arriba" } );
		erroresForm = true;
	}

   /* **************************  END VALIDACIONES  ****************************** */
   
    

	//if( this.state.nombres && this.state.apellido_paterno && this.state.apellido_materno && this.state.email && this.state.celular && this.state.puesto && this.state.tipo_sangre && this.state.fecha_nacimiento && this.state.tel_emergencia && this.state.RFC && this.state.password ){
	if(!erroresForm){

		let ud       				= sessionStorage.getItem('userData');
  		let userData 				= JSON.parse(ud);
		let id_cuenta_previo 	= userData.id_cuenta;
		let jerarquia_sys 		= userData.jerarquia;
		this.setState({ id_cuenta: id_cuenta_previo });
	
		try {


			const instance = axios.create({
			baseURL: global.config.server_endpoint.url,
			timeout: 1000,
			headers: {'Authorization': 'Bearer '+userData.token}
			});

			//if para bloquear jerarquias 4
			if(this.state.jerarquia !== 4 && (jerarquia_sys>=this.state.jerarquia) ){

				instance.post('/usuarios', { 
						id_cuenta: this.state.id_cuenta, 
						name: this.state.name, 
						email: this.state.email, 
						password: this.state.password, 
						c_password: this.state.password2, 
						jerarquia: this.state.jerarquia
				    })
				.then(response => {
				
					
					if(response.status === 200){
						alert("Agregado exitosamente");
						//this.clearForm();
						this.setState( { redirectClientes: true } );
					}
					else{
						alert("error");
					}
					
					
				
				})
				.catch(error => {
					this.setState({ error, isLoading: false });
					alert("Error: ese email ya figura en nuestra base de datos.");
				});
			}
			else{
				alert("Error #3434 - Jerarquía no válida");
			}

		//----

  
		} catch (e) {
  			console.log("Email repetido.");
  			
  			//console.log(r)
			console.log(this.state);
		}

	}
	else{
		console.log("Error: Información incompleta");
	}
}


onChange(e){
	this.setState({[e.target.name]:e.target.value});
}

clearForm(e){
	this.setState({ id_cuenta: '' });
	this.setState({ name: '' });
	this.setState({ email: '' });
	this.setState({ password: '' });
	this.setState({ password2: '' });
	this.setState({ jerarquia: '' });

}


async changeStatus(){
	this.setState({"windowState": false});
}

render() {


	if(this.state.redirectClientes !== true){

	var loginbu;

	if(this.state.windowState){
		loginbu = <Alert show={this.state.windowState} onClose={ this.changeStatus }>Se guardo exitosamente el empleado</Alert>;
	}
	else{
		loginbu = "";
	}

	let ud       			= sessionStorage.getItem('userData');
   let userData 			= JSON.parse(ud);
	let jerarquia_sys 	= userData.jerarquia;


	if(sessionStorage.getItem('userData') != null && jerarquia_sys<=2){
	return (
		<>
		<Header />
		<Container className="mb-5">
			<h1 className="titulo_login mt-4">Agregar usuario</h1>

			{loginbu}
			
			<div className="row mt-4">
				<div className="col-md-2">
					<span>Nombre completo</span>
				</div>
				<div className="col-md-10">
					<span><input type="text" className="form-control" id="inp_name" name="name" value={this.state.name} onChange={this.onChange} required="required" autocomplete="nope" /></span>
					<span className="red_alert">{ this.state.errorNombre }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Email</span>
				</div>
				<div className="col-md-10">
					<span><input type="email" className="form-control" id="inp_email" name="email" value={this.state.email} onChange={this.onChange} required="required" autocomplete="off" /></span>
					<span className="red_alert">{ this.state.errorEmail }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Jerarquía</span>
				</div>
				<div className="col-md-10">
					<span>
							<select className="form-control" id="inp_jerarquia" name="jerarquia" onChange={this.onChange} required="required">
								<option value="">Seleccione...</option>
								{
									jerarquia_sys === 1 &&
									<option value="1">Superadministrador</option>
								}
								<option value="2">Administrador</option>
								<option value="3">Vista</option>
							</select>
					</span>
					<span className="red_alert">{ this.state.errorJerarquia }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Password</span>
				</div>
				<div className="col-md-10">
					<span><input type="password" className="form-control" id="inp_password" name="password" value={this.state.password} onChange={this.onChange} required="required" autocomplete="nope" /></span>
					<span className="red_alert">{ this.state.errorPassword }</span>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-2">
					<span>Password</span>
				</div>
				<div className="col-md-10">
					<span><input type="password" className="form-control" id="inp_password2" name="password2" value={this.state.password2} onChange={this.onChange} required="required" autocomplete="nope" /></span>
					<span className="red_alert">{ this.state.errorPassword2 }</span>
				</div>
			</div>

			<div className="row mt-3 mb-5">
				<div className="col-md-2">
					<span>&nbsp;</span>
				</div>
				<div className="col-md-10">
					<span><input type="submit" value="Guardar" className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={this.enviarForm}  /></span>
				</div>
			</div>

			{ /* espacio de seguridad */ }
			<div className="row mb-2">
				<div className="col-md-2">
					<span>&nbsp;</span>
				</div>
				<div className="col-md-10">
					<span>&nbsp;</span>
				</div>
			</div>

		</Container>
		</>)
	}
	else{
		return <Redirect to="/"  />;
	}


	}
	else{
		return <Redirect to="/users"  />;
	}	




}

}
export default AddUser;