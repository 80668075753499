import React, {useState, useEffect, useRef } from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import { DataGrid } from '@mui/x-data-grid';
import Button  from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
//------------

import Tooltip from '@mui/material/Tooltip';


//---------------------------------------------------------------

const Catalogo = () =>{

    //-------- hooks
    const [catalogo, setCatalogo]                           = useState([]);
    const [listadoStatus, setListadoStatus]                 = useState([]);
    const [listadoUnidades, setListadoUnidades]             = useState([]);
    const [visible , SetVisible]                            = useState({ show: false, id: null });
    const [visible2 , SetVisible2]                          = useState(false);
    const [visible3 , SetVisible3]                          = useState(false);
    const [nombreProducto, setNombreProducto]               = useState("");
    const [idUnidad, setIdUnidad]                           = useState("");
    const [fechaIngreso, setFechaIngreso]                   = useState("");
    const [valorMin, setValorMin]                           = useState("");
    const [valorMax, setValorMax]                           = useState("");
    const [status, setStatus]                               = useState("");
    const [idToChange, setIdToChange]                       = useState("");
    const [isAuthorized, setIsAuthorized]                   = useState(true);
    const [idBusqueda, setIdBusqueda]                       = useState();

    const [errorNombreProducto, setErrorNombreProducto]     = useState(null);
    const [errorValorMin, setErrorValorMin]                 = useState(null);
    const [errorValorMax, setErrorValorMax]                 = useState(null);
    const [errorStatus, setErrorStatus]                     = useState(null);
    const [showBtn, setShowBtn]                             = useState(false);
    //-------------------- nuevo data grid -------------------
    const [loading, setLoading]                             = useState(false);
    const [page, setPage]                                   = useState(0);
    const [selectionModel, setSelectionModel]               = useState([]);
    const prevSelectionModel                                = useRef(selectionModel);
    const [totalRows, setTotalRows]                         = useState(0);
    const [open, setOpen]                                   = useState(false);

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
      return;
      }
      setOpen(false);
    }

    const OnChangeNombreProducto = (e) =>{
        setNombreProducto(e.target.value);        
    }

    const OnChangeIdUnidad = (e) =>{
        setIdUnidad(e.target.value);        
    }

    const OnChangeValorMin = (e) =>{
        setValorMin(e.target.value);        
    }

    const OnChangeValorMax = (e) =>{
      setValorMax(e.target.value);        
    }

    const OnChangeStatus = (e) =>{
        setStatus(e.target.value);          
    }

    const updateData = () => {

        setCatalogo([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        let url                 = window.location.pathname;
        var por                 = url.split('/');
        let id_cliente_search, url_search;

        if(por.length>2){
            let get_number          = (por.length)-1;
            id_cliente_search       = por[get_number]
            setIdBusqueda(id_cliente_search);
        }
        
        
        if(!id_cliente_search)
            url_search = '/productos/list';
        else
            url_search = '/productos/list/status/'+id_cliente_search;
           

        trackPromise(
            instance.get(url_search)
            .then(response => {
                setIsAuthorized(true);
                setCatalogo(response.data.data.data);
                setTotalRows(response.data.data.total);
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })

        );

        trackPromise(
          instance.get('/productos/list/get-statuses')
          .then(response => {
              setListadoStatus(response.data.data);
              if(response.data.data.length<2){
                window.location = "/catalogo";
              }
          })
          .catch(e => {
              console.log(e);
          })
      );
        
    }

    const onChangeStatus = (valor) => {
        //console.log(valor);
        window.location = "/catalogo/"+valor;
    }

    useEffect(() => {


        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
            const retrieveData = () => {
            
                let url                 = window.location.pathname;
                var por                 = url.split('/');
                let id_cliente_search, url_search;

                if(por.length>2){
                    let get_number          = (por.length)-1;
                    id_cliente_search       = por[get_number]
                    setIdBusqueda(id_cliente_search);
                }
                
                
                if(!id_cliente_search)
                    url_search = '/productos/list';
                else
                    url_search = '/productos/list/status/'+id_cliente_search;
                   

                trackPromise(

                    instance.get(url_search)
                    .then(response => {
                        setIsAuthorized(true);
                        setCatalogo(response.data.data.data);
                        setTotalRows(response.data.data.total);
                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
    
                );

                
                trackPromise(


                  instance.get('/unidades/list-active')
                  .then(response => {
                      setIsAuthorized(true);
                      setListadoUnidades(response.data.data);
                  })
                  .catch(e => {
                      setIsAuthorized(false);
                      console.log("Expired token - Error #45435");
                  })
  
              );


                // trackPromise(
                //     instance.get('/clientes')
                //     .then(response => {
                //         setClientes(response.data.data.data);
                //     })
                //     .catch(e => {
                //         console.log(e);
                //     })
                // );

                trackPromise(
                    instance.get('/productos/list/get-statuses')
                    .then(response => {
                        setListadoStatus(response.data.data);
                    })
                    .catch(e => {
                        console.log(e);
                    })
                );

        }

        retrieveData();

        }
        

    },[])


    const NextPage = (page) => {

        setLoading(true);
        setCatalogo([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        // -------------- init new -----------------
        let url                 = window.location.pathname;
        var por                 = url.split('/');
        let id_catalogo_search, url_search;

        if(por.length>2){
            let get_number          = (por.length)-1;
            id_catalogo_search       = por[get_number]
            setIdBusqueda(id_catalogo_search);
        }
        
        if(!id_catalogo_search)
            url_search = '/productos/list?page='+page;
        else
            url_search = '/productos/list/status/'+id_catalogo_search+'?page='+page;
        // --------------- end new ------------------

        trackPromise(

            instance.get(url_search)
            .then(response => {
                setCatalogo(response.data.data.data);
                setTotalRows(response.data.data.total);
                setLoading(false);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const updateProyectos = () => {
        setCatalogo([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/productos/list')
            .then(response => {
                setCatalogo(response.data.data.data);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const handleDeleteTrue = (id) => {
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.delete('/productos/delete/'+id)
            .then(response => {
                if(response.status === 200){
                    updateProyectos();
                    NextPage(page);
                    setOpen(true);
                    SetVisible({
                        visible: false,
                        id: null,
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    const handleDeleteFalse2 = () => {
        SetVisible2(false);
    }

    const handleDeleteFalse3 = () => {
        SetVisible3(false);
    }

    const openModal = (id) => {
        SetVisible2(true);
        setNombreProducto(""); 
        setIdUnidad(""); 
        setValorMin("");
        setValorMax("");
        setStatus("");
        setShowBtn(false);
        setIdToChange(id);
        

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('/productos/show/'+id)
            .then(response => {
                
                setNombreProducto(response.data.data.nombre_producto || null);
                setIdUnidad(response.data.data.id_unidad || null);
                setFechaIngreso(response.data.data.created_at || null);
                setValorMin(response.data.data.valor_min || null);
                setValorMax(response.data.data.valor_max || null);
                setStatus(response.data.data.status || null);
            })
              .catch(e => {
                console.log(e);
              })
          );

          trackPromise(
            instance.get('/productos/list/get-statuses')
            .then(response => {
                setListadoStatus(response.data.data);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const openModalNew = (id) => {
        SetVisible3(true);
        setShowBtn(false)
        setNombreProducto(""); 
        setIdUnidad(""); 
        setFechaIngreso("");
        setValorMin("");
        setValorMax("");
        setStatus("");

        // let ud       = sessionStorage.getItem('userData');
        // let userData = JSON.parse(ud);

        // const instance = axios.create({
        //     baseURL: global.config.server_endpoint.url,
        //     timeout: 10000,
        //     headers: {'Authorization': 'Bearer '+userData.token}
        // });

        // trackPromise(
        //     instance.get('/productos/list')
        //     .then(response => {
        //         setIsAuthorized(true);
        //         setListadoCatalogo(response.data.data.data);
        //     })
        //       .catch(e => {
        //             setIsAuthorized(false);
        //             console.log(e);
        //       })
        //   );

    }

    const handleSubmitNew = (id) => {

      let errorsForm = 0;
      setShowBtn(true)
      //const regex_letras 	  = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
      const regex_letras     = /^[A-Za-z0-9\s]+$/g;
      const regex_numeros 	= /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
      //const regex_fecha 	  = /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
      
          //---------------------- [		validaciones		] --------------------------
          if(nombreProducto.match(regex_letras) == null){
              //console.log("Error: solo letras");
              setErrorNombreProducto("Sólo letras");
              errorsForm = errorsForm + 1;
              console.log(errorsForm);
              setShowBtn(false)
          }
          else{
              setErrorNombreProducto("");
          }

          if(valorMin.toString().match(regex_numeros) == null){
              //console.log("Error: solo letras");
              setErrorValorMin("Sólo números");
              errorsForm = errorsForm + 1;
              //console.log(errorsForm);
              setShowBtn(false)
          }
          else{
              setErrorValorMin("");
          }

          if(valorMax.toString().match(regex_numeros) == null){
            //console.log("Error: solo letras");
            setErrorValorMax("Sólo números");
            errorsForm = errorsForm + 1;
            //console.log(errorsForm);
            setShowBtn(false)
          } 
          else{
            setErrorValorMax("");
          }

          if(!status){
              //console.log("Error: solo letras");
              setErrorStatus("Por favor seleccione una opción válida");
              errorsForm = errorsForm + 1;
              console.log(errorsForm);
              setShowBtn(true)
          }
          else{
              setErrorStatus("");
          }
                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });
                    
                    trackPromise(
                        instance.post('productos/add', { 
                            nombre_producto: nombreProducto, 
                            id_unidad: idUnidad,
	                          valor_min: valorMin, 
                            valor_max: valorMax, 
                            status: status
                            })
                        .then(response => {
                          
                          if(response.data.success === true){
                                //alert("El área se guardó exitosamente")
                                setCatalogo([]);
                                updateData();
                                setNombreProducto(""); 
                                setValorMin(""); 
                                setValorMax(""); 
                                setFechaIngreso("");
                                setIdUnidad(""); 
                                setStatus("");
                                SetVisible3(false);
                                NextPage(page);
                                setOpen(true);
                          }
                          
                        })
                          .catch(e => {
                            console.log(e);
                          })
                      );
                } 
                else{
                    setShowBtn(false)
                }
    }

    const handleSubmit = (e) => {

      
        let errorsForm = false;
        setShowBtn(true)
        e.preventDefault();
        //const regex_letras 	  = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
        const regex_letras     = /^[A-ZÀ-úa-z0-9\s]+$/g;
        const regex_numeros 	= /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
        //const regex_fecha 	  = /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
        
            //---------------------- [		validaciones		] --------------------------
            if(nombreProducto.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreProducto("Sólo letrass");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn(false)
            }
            else{
                setErrorNombreProducto("");
            }

            if(valorMin.toString().match(regex_numeros) == null){
                //console.log("Error: solo letras");
                setErrorValorMin("Sólo números");
                errorsForm = errorsForm + 1;
                //console.log(errorsForm);
                setShowBtn(false)
            }
            else{
                setErrorValorMin("");
            }

            if(valorMax.toString().match(regex_numeros) == null){
              //console.log("Error: solo letras");
              setErrorValorMax("Sólo números");
              errorsForm = errorsForm + 1;
              //console.log(errorsForm);
              setShowBtn(false)
            } 
            else{
              setErrorValorMax("");
            }

            if(!status){
                //console.log("Error: solo letras");
                setErrorStatus("Por favor seleccione una opción válida");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
                setShowBtn(true)
            }
            else{
                setErrorStatus("");
            }

            if(!errorsForm){

                let ud       = sessionStorage.getItem('userData');
                let userData = JSON.parse(ud);

                const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000,
                    headers: {'Authorization': 'Bearer '+userData.token}
                });
                //console.log(idToChange);
                trackPromise(
                    instance.put('/productos/update/'+idToChange, { 
                        nombre_producto: nombreProducto, 
                        id_unidad: idUnidad,
                        valor_min: valorMin, 
                        valor_max: valorMax, 
                        status: status
                        })
                    .then(response => {
                      
                      if(response.data.success === true){
                            //alert("El área se guardó exitosamente")
                            setCatalogo([]);
                            //updateData();
                            setNombreProducto(""); 
                            setValorMin(""); 
                            setValorMax(""); 
                            setFechaIngreso("");
                            setIdUnidad(""); 
                            setStatus("");
                            setOpen(true);
                            SetVisible2(false);
                            NextPage(page);
                      }
                      
                    })
                      .catch(e => {
                        console.log(e);
                      })
                  );
            } 
            else{
                setShowBtn(false)
            }
    }

    const showConfirm = (id) => {
        SetVisible({visible: true, id: id});
    }

    const EditProducto = () =>{
        return (
          <Container className="mt-2">
          <div className="row mt-4">
              <div className="col-md-3">
                  <span className="txt_listado">Nombre producto</span>
              </div>
              <div className="col-md-9">
                  <span><input type="text" className="form-control" id="inp_nombre_proyecto" name="nombre_proyecto" defaultValue={nombreProducto || ''} onChange={OnChangeNombreProducto} required="required" /></span>
                  <span className="red_alert">{ errorNombreProducto }</span>
              </div>
          </div>
          <div className="row mt-3">
              <div className="col-md-3">
                  <span className="txt_listado">Unidad</span>
              </div>
              <div className="col-md-9">
                  <span>
                      <select className="form-control" id="inp_id_unidad" name="id_unidad" value={idUnidad} onChange={OnChangeIdUnidad} required="required">
                          <option value="">Seleccione...</option>
                          {
                              listadoUnidades.map(post => {
                                  const { id, nombre_unidad } = post;
                                  
                                  return (
                                      <option key={id} value={id}>{nombre_unidad}</option>
                                  );
                                  
                                  })
                          }
                      </select>
                  </span>
                  <span className="red_alert">{ errorStatus }</span>
              </div>
          </div>
          <div className="row mt-3">
              <div className="col-md-3">
                  <span className="txt_listado">Valor mínimo</span>
              </div>
              <div className="col-md-9">
                  <span><input type="text" className="form-control" id="inp_valor_min" name="valor_min" defaultValue={valorMin || ''} onChange={OnChangeValorMin} required="required" /></span>
                  <span className="red_alert">{ errorValorMin }</span>
              </div>
          </div>

          <div className="row mt-3">
					    <div className="col-md-3">
						    <span className="txt_listado">Valor Máximo</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_valor_max" name="valor_max" defaultValue={valorMax || ''} onChange={OnChangeValorMax} required="required" /></span>
						    <span className="red_alert">{ errorValorMax }</span>
					    </div>
				  </div>

          <div className="row mt-3">
              <div className="col-md-3">
                  <span className="txt_listado">Fecha ingreso</span>
              </div>
              <div className="col-md-9">
                  <span><input type="date" className="form-control" id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={fechaIngreso || ''} required="required" disabled /></span>
                  <span className="red_alert"></span>
              </div>
          </div>
          
          <div className="row mt-3">
              <div className="col-md-3">
                  <span className="txt_listado">Status</span>
              </div>
              <div className="col-md-9">
                  <span>
                      <select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
                          <option value="">Seleccione...</option>
                          <option value="1">Activo</option>
                          <option value="2">Inactivo</option>
                      </select>
                  </span>
                  <span className="red_alert">{ errorStatus }</span>
              </div>
          </div>
      <div className="row mt-3 mb-5">
          <div className="col-md-3">
              <span>&nbsp;</span>
          </div>
          <div className="col-md-9">
              <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmit}  /></span>
          </div>
      </div>
          </Container>
          )
    }

    //------------------------ [ end files ] -------------------------------

    const columns = [
       
        {
          field: 'nombre_producto',
          headerName: 'Nombre producto',
          minWidth: 350,
          flex: .7,
          headerClassName: 'letal',
        },
        {
          field: 'id_unidad',
          headerName: 'Unidad',
          minWidth: 150,
          flex: .2,
          headerClassName: 'letal',
          renderCell: (params) => {

            let name_unidad;
            if(listadoUnidades){ 
            let found = listadoUnidades.find(element => element.id === params.row.id_unidad);
            if(found)
                name_unidad = found.nombre_unidad;
            }

              return name_unidad;
          },
        },
        {
          field: 'valor_min',
          headerName: 'Valor Mínimo',
          minWidth: 120,
          flex: .3,
          headerClassName: 'letal',
        },
        {
          field: 'valor_max',
          headerName: 'Valor Máximo',
          minWidth: 120,
          flex: .3,
          headerClassName: 'letal',
        },
        {
          field: 'status',
          flex: 0.2,
          headerName: 'Estatus',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
                let txt_status = "";
                //console.log(params.row.status);
                switch (params.row.status) {
                    case 1:
                        txt_status = "Activo"
                        break;
                    case 2:
                            txt_status = "Inactivo"
                        break;
                    case 3:
                            txt_status = "Suspendido"
                        break;
                    case 4:
                            txt_status = "Finalizado"
                        break;
                    default:
                        txt_status = "-"
                        break;
                }

                return txt_status;
          },
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 150,
            flex: .4,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                  <Tooltip title="Borrar">
                    <Button
                        variant="text"
                        color="error"
                        size="large"
                        className="pr-1 mr-1 btn_emp"
                        startIcon={<DeleteIcon />}
                        alt="holaaaa"
                        onClick={() => showConfirm(params.id)}
                    />
                  </Tooltip>
                  
                  <Tooltip title="Editar">
                    <Button
                        variant="text"
                        color="primary"
                        size="large"
                        className="mr-1"
                        startIcon={<EditIcon />}
                        onClick={() => openModal(params.id)}
                    />
                  </Tooltip>
                </strong>
            ),
        }
        
    
    ];

    const rows = catalogo;

    

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    ///----------------------[  main return  ]---------------------------
      
     if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized){
        return(<>
                <Header />
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        La información se guardo exitosamente.
                    </Alert>
                </Snackbar>
                <Container className="mr-inf">
                    {/* Original desktop */}
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="titulo_login">Catálogo</div> 
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <h1 className="hello">
                                <select className="form-control buscador-select mt-4" value={idBusqueda || ""} onChange={e => onChangeStatus(e.target.value)}>
                                        <option value="">Filtrar por status...</option>
                                        {
                                        listadoStatus.map(post => {
                                            const { status } = post;
                                            let txt_status;
                                            if(status === 1){
                                              txt_status = "Activo";
                                            }
                                            else{
                                              txt_status = "Inactivo";
                                            }
                                            return (
                                                <option key={status} value={status}>{txt_status}</option>
                                            );
                                            
                                            })
                                        }
                                </select>
                            </h1>
                        </div>
                        <div className="col-md-2 col-lg-2">
                            <IconButton aria-label="delete" className="mt-2 float-right" color="error" size="large" onClick={() => openModalNew()}>
                                <AddCircle fontSize="inherit" className="new-icon"  />
                                <h1 className="add-new ml-2 mt-2">Agregar</h1>
                            </IconButton>
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col-md-12">
                            <div className="areas_datagrid2">
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>
            </Container>

            <Modal size="lg" show={visible.visible} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">¿Estas seguro que deseas eliminarlo?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrue(visible.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalse()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible2} onHide={handleDeleteFalse2} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Editar producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { EditProducto() }
                </Modal.Body>
            </Modal>



            <Modal size="lg" show={visible3} onHide={handleDeleteFalse3} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Agregar producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container className="mt-2">
                    <div className="row mt-4">
                        <div className="col-md-3">
                            <span className="txt_listado">Nombre producto</span>
                        </div>
                        <div className="col-md-9">
                            <span><input type="text" className="form-control" id="inp_nombre_proyecto" name="nombre_proyecto" defaultValue={nombreProducto || ''} onChange={OnChangeNombreProducto} required="required" /></span>
                            <span className="red_alert">{ errorNombreProducto }</span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <span className="txt_listado">Unidad</span>
                        </div>
                        <div className="col-md-9">
                            <span>
                                <select className="form-control" id="inp_id_unidad" name="id_unidad" value={idUnidad} onChange={OnChangeIdUnidad} required="required">
                                    <option value="">Seleccione...</option>
                                    {
                                        listadoUnidades.map(post => {
                                            const { id, nombre_unidad } = post;
                                            
                                            return (
                                                <option key={id} value={id}>{nombre_unidad}</option>
                                            );
                                            
                                            })
                                    }
                                </select>
                            </span>
                            <span className="red_alert">{ errorStatus }</span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <span className="txt_listado">Valor mínimo</span>
                        </div>
                        <div className="col-md-9">
                            <span><input type="text" className="form-control" id="inp_valor_min" name="valor_min" defaultValue={valorMin || ''} onChange={OnChangeValorMin} required="required" /></span>
                            <span className="red_alert">{ errorValorMin }</span>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-3">
                          <span className="txt_listado">Valor Máximo</span>
                        </div>
                        <div className="col-md-9">
                          <span><input type="text" className="form-control" id="inp_valor_max" name="valor_max" defaultValue={valorMax || ''} onChange={OnChangeValorMax} required="required" /></span>
                          <span className="red_alert">{ errorValorMax }</span>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-3">
                            <span className="txt_listado">Status</span>
                        </div>
                        <div className="col-md-9">
                            <span>
                                <select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
                                    <option value="">Seleccione...</option>
                                    <option value="1">Activo</option>
                                    <option value="2">Inactivo</option>
                                </select>
                            </span>
                            <span className="red_alert">{ errorStatus }</span>
                        </div>
                    </div>
                <div className="row mt-3 mb-5">
                    <div className="col-md-3">
                        <span>&nbsp;</span>
                    </div>
                    <div className="col-md-9">
                        <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={() => handleSubmitNew()}  /></span>
                    </div>
                </div>
                    </Container>
                </Modal.Body>
            </Modal>

        </>)
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default Catalogo;