import React, {useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
//import Loader from 'react-loader-spinner';
//import { DataGrid } from '@mui/x-data-grid';
import Button  from '@mui/material/Button';
//import DeleteIcon from '@mui/icons-material/Delete';
//import EditIcon from '@mui/icons-material/Edit';
//import AddCircle from '@mui/icons-material/AddCircle';
//import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Usuarios from './usuarios';
import Areas from './areas';
import Unidades from './unidades';

//--------
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//---------
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const MiEmpresa = () =>{

    const [isAuthorized, setIsAuthorized]                   = useState(true);
    const [value, setValue]                                 = React.useState(0);
    const [nombreEmpresa, setNombreEmpresa]                 = useState("");
    const [logo, setLogo]                                   = useState("");
    const [RFC, setRFC]                                     = useState("");
    const [telefono, setTelefono]                           = useState("");
    const [fechaIngreso, setFechaIngreso]                   = useState("");
    const [vigenciaCuenta, setVigenciaCuenta]               = useState("");
    const [showBtn, setShowBtn]                             = useState(false);
    //----
    const [vacationsData, setVacationsData]                 = useState("");
    
    const [errorNombreEmpresa, setErrorNombreEmpresa]       = useState(null);
    const [errorRFC, setErrorRFC]                           = useState(null);
    //---- snackbar
    const [open, setOpen]                                   = useState(false);
    const [image, setImage]                                 = useState({ preview: "", raw: "" });
    //------------------------------
    const form                                              = useRef(null);
    
    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    }

    const OnChangeNombreEmpresa = (e) =>{
        setNombreEmpresa(e.target.value);        
    }

    const OnChangeA = (e) =>{
        //setNombreEmpresa(e.target.value);    
        if(e.target.value)
            vacationsData[e.target.name] = Number(e.target.value);
        else{
            e.target.value = vacationsData[e.target.name];
        }
           
        //console.log(vacationsData);
    }

    const OnChangeRFC = (e) =>{
        setRFC(e.target.value);
    }

    const handleSubmit = (e) =>{

        
        let errorsForm = false;
        setShowBtn(true)
        /* **************************    VALIDACIONES    ****************************** */

        //-------------------------------------------------------------------------------------------
        let regex_letras    = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        //let regex_tipo      = /^[a-zA-Z+àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        let regex_rfc       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        //let regex_fecha 	= /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
        
        //--------------------------[		validate inp_nombre_empresa		]-------------------------
        if(nombreEmpresa.match(regex_letras) == null){
            //console.log("Error: solo letras");
            setErrorNombreEmpresa("Error: solo letras");
            setShowBtn(false)
            errorsForm = true;
        }
        else{
            setErrorNombreEmpresa("");
        }

        if(RFC.match(regex_rfc) == null){
            //console.log("Error: solo letras");
            setErrorRFC("Error: RFC no válido");
            setShowBtn(false)
            errorsForm = true;
        }
        else{
            setErrorRFC("");
        }

        /* **************************  END VALIDACIONES  ****************************** */
        
        if(!errorsForm){
           
            setShowBtn(false);

            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token, 'Accept': 'multipart/form-data'}
            });

            if(image){

                e.preventDefault();
               
            
                trackPromise(
                    instance.put('/mi-empresa', {
                        logo: image,
                        nombre_empresa: nombreEmpresa,
                        telefono: telefono,
                        RFC: RFC
                    })
                    .then(response => {
                        if(response.data.success === true){
                            setOpen(true);
                            setLogo(response.data.data.logo);
                        }
                        else{
                            setShowBtn(false)
                        }
                    
                    })
                    .catch(e => {
                        console.log(e);
                    })
                );
            }
            else{
                
                trackPromise(
                    instance.put('/mi-empresa', { 
                        nombre_empresa: nombreEmpresa, 
                        RFC: RFC,
                        telefono: telefono,
                    })
                    .then(response => {
                        if(response.data.success === true){
                            //alert("Los datos se guardaron exitosamente")
                            setOpen(true);
                            // setNombreEmpresa(""); 
                            // setRFC("");
                            // setLogo("");
                            // setTelefono("");
                        }
                        else{
                            setShowBtn(false)
                        }
                    
                    })
                    .catch(e => {
                        console.log(e);
                    })
                );

            }

        }
        else{
            setShowBtn(false)
        }
        
    };

    const handleSubmitVacations = () =>{

        let errorsForm = false;

        // let errorsForm = false;
        // setShowBtn(true)
        // /* **************************    VALIDACIONES    ****************************** */

        // //-------------------------------------------------------------------------------------------
        // let regex_letras    = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        // //let regex_tipo      = /^[a-zA-Z+àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        // let regex_rfc       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        // //let regex_fecha 	= /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
        
        // //--------------------------[		validate inp_nombre_empresa		]-------------------------
        // if(nombreEmpresa.match(regex_letras) == null){
        //     //console.log("Error: solo letras");
        //     setErrorNombreEmpresa("Error: solo letras");
        //     setShowBtn(false)
        //     errorsForm = true;
        // }
        // else{
        //     setErrorNombreEmpresa("");
        // }

        // if(RFC.match(regex_rfc) == null){
        //     //console.log("Error: solo letras");
        //     setErrorRFC("Error: RFC no válido");
        //     setShowBtn(false)
        //     errorsForm = true;
        // }
        // else{
        //     setErrorRFC("");
        // }

        /* **************************  END VALIDACIONES  ****************************** */
        
        if(!errorsForm){
           
            setShowBtn(false);
           
            if(userData.jerarquia<3){
                const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000,
                    headers: {'Authorization': 'Bearer '+userData.token}
                });

                trackPromise(

                    instance.put('/update-vacations', vacationsData)
                    .then(response => {
                        //console.log(response);
                        if(response.status === 200)
                            setOpen(true);
                        else
                            alert("Error");
                    })
                    .catch(e => {
                        setIsAuthorized(false);
                        console.log("Expired token - Error #45435");
                    })
    
                );
            }


        }
        else{
            setShowBtn(false)
        }
        
    };

    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        
        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
        const retrieveData = () => {

            trackPromise(

                instance.get('/mi-empresa')
                .then(response => {
                    setIsAuthorized(true);
                    setNombreEmpresa(response.data.data[0].nombre_empresa);
                    setLogo(response.data.data[0].logo);
                    setRFC(response.data.data[0].RFC);
                    setTelefono(response.data.data[0].telefono);
                    setFechaIngreso(response.data.data[0].fecha_ingreso);
                    setVigenciaCuenta(response.data.data[0].vigencia_cuenta);
                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })
  
            );

            trackPromise(

                instance.get('/get-vacations')
                .then(response => {
                    let pasa = response.data.data;
                    console.log(pasa);
                    if(pasa.length === 0){
                        console.log("vacio")
                    }
                    else{
                        console.log(response.data.data[0]);
                        if(response.data){
                            let originalData = response.data.data[0];
                            delete originalData.id;
                            delete originalData.id_cuenta;
                            delete originalData.created_at;
                            delete originalData.updated_at;
                            setVacationsData(originalData);
                        } 
                    }
                    
                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })
  
            );
            
        }

        retrieveData();

    }

    },[]);

    const onChangeLogo = e => {
        
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
        files = e.dataTransfer.files;
        } else if (e.target) {
        files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            //setRecortaDisabled(false);
        };
        reader.readAsDataURL(files[0]);

    };

    const TabsComponent = () =>{
        //console.log(myData['0']);
       
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, width: "100%", borderColor: 'divider' }}>
                    <Tabs value={value} variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="basic tabs example">
                        <Tab className="tabu" label="Información" {...a11yProps(0)} />
                        <Tab className="tabu" label="Usuarios" {...a11yProps(1)} />
                        <Tab className="tabu" label="Áreas" {...a11yProps(2)} />
                        <Tab className="tabu" label="Unidades" {...a11yProps(3)} />
                        <Tab className="tabu" label="Días de vacaciones" {...a11yProps(4)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Container>
                    <form ref={form}>
                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Nombre empresa</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="text" className="form-control" id="inp_nombre_empresa" name="nombre_empresa" defaultValue={nombreEmpresa || ''} onKeyUp={OnChangeNombreEmpresa} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Logo</span>
                            </div>
                            <div className="col-md-10">
                                <span>
                                    {/* <input type="file" className="form-control" onChange={onChange}  style={{display: 'none'}} className="mb-5"/> */}
                                    <input type="file" className="form-control" id="inp_logo" name="nombre_empresa" onChange={onChangeLogo} required="required" /></span>
                                <span className="red_alert"></span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">RFC</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="text" className="form-control" id="inp_rfc" name="rfc" defaultValue={RFC || ''} onKeyUp={OnChangeRFC} required="required" /></span>
                                <span className="red_alert">{ errorRFC }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Inicio de cuenta</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="date" className="form-control" disabled id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={fechaIngreso || ''} required="required" /></span>
                                <span className="red_alert"></span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Vigencia cuenta</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="date" className="form-control" disabled id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={vigenciaCuenta || ''} required="required" /></span>
                                <span className="red_alert"></span>
                            </div>
                        </div>
                        
                        <div className="row mt-3 mb-5">
                            <div className="col-md-2">
                                <span>&nbsp;</span>
                            </div>
                            <div className="col-md-10">
                                <span><Button type="submit" disabled={showBtn} variant="contained" color="success" className="form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmit}>Guardar</Button></span>
                            </div>
                        </div>
                    </form>
                        </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Usuarios />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Areas />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Unidades />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Container>
                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 1</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a1" name="a1" defaultValue={vacationsData.a1 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 2</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a2" name="a2" defaultValue={vacationsData.a2 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 3</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a3" name="a3" defaultValue={vacationsData.a3 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 4</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a4" name="a4" defaultValue={vacationsData.a4 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 5</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a5" name="a5" defaultValue={vacationsData.a5 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 6</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a6" name="a6" defaultValue={vacationsData.a6 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 7</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a7" name="a7" defaultValue={vacationsData.a7 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 8</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a8" name="a8" defaultValue={vacationsData.a8 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 9</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a9" name="a9" defaultValue={vacationsData.a9 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 10</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a10" name="a10" defaultValue={vacationsData.a10 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 11</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a11" name="a11" defaultValue={vacationsData.a11 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 12</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a12" name="a12" defaultValue={vacationsData.a12 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 13</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a13" name="a13" defaultValue={vacationsData.a13 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 14</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a14" name="a14" defaultValue={vacationsData.a14 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 15</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a15" name="a15" defaultValue={vacationsData.a15 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 16</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a16" name="a16" defaultValue={vacationsData.a16 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 17</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a17" name="a17" defaultValue={vacationsData.a17 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 18</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a18" name="a18" defaultValue={vacationsData.a18 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 19</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a19" name="a19" defaultValue={vacationsData.a19 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 20</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a20" name="a20" defaultValue={vacationsData.a20 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 21</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a21" name="a21" defaultValue={vacationsData.a21 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 22</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a22" name="a22" defaultValue={vacationsData.a22 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 23</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a23" name="a23" defaultValue={vacationsData.a23 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 24</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a24" name="a24" defaultValue={vacationsData.a24 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 25</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a25" name="a25" defaultValue={vacationsData.a25 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 26</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a26" name="a26" defaultValue={vacationsData.a26 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 27</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a27" name="a27" defaultValue={vacationsData.a27 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 28</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a28" name="a28" defaultValue={vacationsData.a28 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 29</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a29" name="a29" defaultValue={vacationsData.a29 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2">
                                <span className="txt_listado">Año 30</span>
                            </div>
                            <div className="col-md-10">
                                <span><input type="number" className="form-control" id="inp_a30" name="a30" defaultValue={vacationsData.a30 || ''} onBlur={OnChangeA} required="required" /></span>
                                <span className="red_alert">{ errorNombreEmpresa }</span>
                            </div>
                        </div>

                        <div className="row mt-3 mb-5">
                            <div className="col-md-2">
                                <span>&nbsp;</span>
                            </div>
                            <div className="col-md-10">
                                <span><Button type="submit" disabled={showBtn} variant="contained" color="success" className="form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmitVacations}>Guardar</Button></span>
                            </div>
                        </div>
                    </Container>
                </TabPanel>
                
            </Box>
          )
    }
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    if(sessionStorage.getItem('userData') != null && userData.jerarquia<=2 && isAuthorized){
        
        let  image_logo;
        if(logo)
            image_logo = "https://d2sx5pnhyjgoui.cloudfront.net/images/"+logo;

        return(<>
                <Header />
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            La información se guardo exitosamente.
                        </Alert>
                    </Snackbar>
                    <Container className="mr-inf">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="titulo_login">Mi empresa</div>
                            </div>
                            <div className="col-md-9 text-right mt-3">
                                <img src={image_logo} alt="logo" className="logo-mi-empresa" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            {TabsComponent()}
                        </div>
                    </Container>
                </>)
    }
    else{
        return <Redirect to="/logout"  />;
    }
};

export default MiEmpresa;