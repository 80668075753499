import React, {useState, useEffect, useRef } from 'react';
import { Container, Modal } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
//import moment from "moment";
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button  from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import "react-datepicker/dist/react-datepicker.css";

const ListadoTareas = () =>{


    // const [visible , SetVisible]                                = useState({ show: false, id: null });
    const [visible2 , SetVisible2]                              = useState(false)
    const [visible3 , SetVisible3]                              = useState(false)
    //const [dataArea, setDataArea]                               = useState([]);
    const [titulo, setTitulo]                                   = useState("")
    const [tituloSaved, setTituloSaved]                         = useState("")
    const [errorTitulo, setErrorTitulo]                         = useState(null)
    const [descripcion, setDescripcion]                         = useState("")
    const [descripcionSaved, setDescripcionSaved]               = useState("")
    const [errorDescripcion, setErrorDescripcion]               = useState(null)
    const [comentarioFinal, setComentarioFinal]                 = useState("")
    const [comentarioSaved, setComentarioSaved]                 = useState("")
    const [errorComentarioFinal, setErrorComentarioFinal]       = useState(null)
    const [horas, setHoras]                                     = useState("")
    const [horasSaved, setHorasSaved]                           = useState("")
    const [idModal, setIdModal]                                 = useState(null)
    const [errorHoras, setErrorHoras]                           = useState(null)
    const [listTareas, setListTareas]                           = useState([])
    const [loading, setLoading]                                 = useState(false)
    const [page, setPage]                                       = useState(0)
    const [totalRows, setTotalRows]                             = useState(0)
    const [selectionModel, setSelectionModel]                   = useState([])
    const prevSelectionModel                                    = useRef(selectionModel)
    const [showBtn, setShowBtn]                                 = useState(false)
    const [isAuthorized, setIsAuthorized]                       = useState(true)
    const [open, setOpen]                                       = useState(false)

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    const OnChangeTitulo = (e) =>{
        setTitulo(e.target.value);        
    }

    const OnChangeHoras = (e) =>{
        setHoras(e.target.value);          
    }

    const OnChangeComentarioFinal = (e) =>{
        setComentarioFinal(e.target.value);          
    }

    //------------------------ [  operations ] --------------------------

    const OnChangeDescripcion = (e) =>{
        setDescripcion(e.target.value);        
    }

    const handleDeleteFalse2 = () => {
        SetVisible2(false);
    }

    const handleDeleteFalse3 = () => {
        SetVisible3(false);
    }

    const NextPage = (page) => {

        setLoading(true);
        setListTareas([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('mis-tareas?page='+page)
            .then(response => {
                setListTareas(response.data.data.data);
                setTotalRows(response.data.data.total);
                setLoading(false);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    // const showConfirm = (id) => {
    //     //SetVisible({visible: true, id: id});
    // }

    const openModal = (id) => {
        SetVisible2(true);
        setTitulo(""); 
        setDescripcion(""); 
        setComentarioFinal("")
        setHoras("")
        //setStatus("");
        setShowBtn(false); 
        setIdModal(id);

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('mi-tarea/'+id)
            .then(response => {
                //console.log(response.data.data)
                setTitulo(response.data.data.titulo);
                setDescripcion(response.data.data.descripcion);
                //setStatus(response.data.data.status);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const openModalNoEdit = (id) => {
        SetVisible3(true);
        setTituloSaved(""); 
        setDescripcionSaved(""); 
        setComentarioSaved(""); 
        setHorasSaved(""); 
        //setStatus("");
        setIdModal(id);

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('mi-tarea/'+id)
            .then(response => {
                //console.log(response.data.data)
                setTituloSaved(response.data.data.titulo);
                setDescripcionSaved(response.data.data.descripcion);
                setComentarioSaved(response.data.data.comentarios);
                setHorasSaved(response.data.data.cantidad_horas);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    

    //-------------------[  main data  ]--------------------------
    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia>3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
        const retrieveData = () => {
            
            //-------------------------- vencimiento de sesion ----------------
            trackPromise(
                instance.get('/validate-token')
                .then(response => {
                    setIsAuthorized(true);
                })
                .catch(e => {
                    setIsAuthorized(false);
                })
            );
            //------------------------ end vencimiento de sesion ---------------


            trackPromise(

                instance.get('mis-tareas')
                .then(response => {
                    //console.log(response.data.data.data)
                    setTotalRows(response.data.data.total);
                    setListTareas(response.data.data.data);

                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })
  
            );
            
        }

        retrieveData();

        }
        

    },[]);

    const clearForm = () => {
            
            setTitulo("");
            setDescripcion("");
    }

    const handleSubmit = (id) => {
        
            let errorsForm = 0;
            setShowBtn(true);
       
            //const regex_letras 	        = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
            //const regex_letras2         = /[A-Za-zÄÖÜäöüß -]{2,}/;
            const regex_final           = /^.{6,}$/;
            //let regex_numeros         = /[+-]?([0-9]*[.])?[0-9]+/;
	        //let regex_fecha_old   	= /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
            //let regex_fecha           = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/; 

            //-------------------[		validate inp_nombre_empresa		]----------------
            if(titulo.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorTitulo("Error: solo letras");
                errorsForm = true;
                setShowBtn(false);
            }
            else{
                setErrorTitulo("");
            }

            //-------------------[		validate Descripcion		]----------------
            if(descripcion.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorDescripcion("Error: solo letras");
                errorsForm = true;
                setShowBtn(false);
            }
            else{
                setErrorDescripcion("");
            }

            if(comentarioFinal.match(regex_final) == null){
                //console.log("Error: solo letras");
                setErrorComentarioFinal("Error: solo letras");
                errorsForm = true;
                setShowBtn(false);
            }
            else{
                setErrorComentarioFinal("");
            }

            //console.log(horas);
            if(!horas){
                //console.log("Error: solo letras");
                setErrorHoras("Por favor seleccione una opción válida");
                errorsForm = true;
                console.log(errorsForm);
                setShowBtn(false);
            }
            else{
                setErrorHoras("");
            }

            if(!errorsForm){
                try {

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);
                    
                    const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000,
                    headers: {'Authorization': 'Bearer '+userData.token}
                    });
        
                    instance.put('/mi-tarea/'+idModal, { 
                        comentarios: comentarioFinal, 
                        cantidad: horas
                        })
                    .then(response => {
                        //console.log(response);
                        clearForm()
                        SetVisible2(false)
                        setShowBtn(false)
                        setOpen(true)
                        // ------ update data in main grid
                        trackPromise(

                            instance.get('mis-tareas')
                            .then(response => {
                                //console.log(response)
                                setTotalRows(response.data.data.total);
                                setListTareas(response.data.data.data);
            
                            })
                            .catch(e => {
                                setIsAuthorized(false);
                                console.log("Expired token - Error #45435");
                            })
              
                        );
                        
                    })
                    .catch(error => {console.log(error)});
        
                } catch (e) {
                        console.log("Error #34324");
                        
                }
            }
           
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const columns = [
        {
          field: 'titulo',
          headerName: 'Título',
          minWidth: 200,
          flex: .4,
          headerClassName: 'letal',
        },
        {
            field: 'nombre_proyecto',
            headerName: 'Proyecto',
            minWidth: 200,
            flex: .3,
            headerClassName: 'letal',
          },
        {
            field: 'nombre_cliente',
            headerName: 'Cliente',
            minWidth: 150,
            flex: .3,
            headerClassName: 'letal',
          },
        {
            field: 'descripcion',
            headerName: 'Descripción',
            minWidth: 250,
            flex: 1,
            headerClassName: 'letal',
        },
        {
            field: 'comentarios',
            headerName: 'Comentarios entrega',
            minWidth: 250,
            flex: .8,
            headerClassName: 'letal',
        },
        {
            field: 'fecha_entrega',
            headerName: 'Fecha entrega',
            minWidth: 200,
            flex: .4,
            headerClassName: 'letal',
        },
        {
          field: 'status',
          flex: 0.2,
          headerName: 'Estatus',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
                let txt_status = "";
                //console.log(params.row.status);
                switch (params.row.status) {
                    case 1:
                        txt_status = "Asignada"
                        break;
                    case 2:
                            txt_status = "Enviada"
                        break;
                    case 3:
                            txt_status = "Finalizada"
                        break;
                    case 4:
                            txt_status = "Cancelada"
                        break;
                    default:
                        txt_status = "-"
                        break;
                }

                return txt_status;
          },
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 100,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: params => {
                if (params.row.status === 1) {
                  return <Tooltip title="Editar">
                            <Button
                                variant="text"
                                color="primary"
                                size="large"
                                className="mr-1"
                                startIcon={<EditIcon />}
                                onClick={() => openModal(params.id)}
                            />
                        </Tooltip>;
                }
                else{
                    return <Tooltip title="Editar">
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="large"
                                    className="mr-1"
                                    startIcon={<EditIcon />}
                                    onClick={() => openModalNoEdit(params.id)}
                                />
                            </Tooltip>;
                }
              },
        }
        
    
    ];

    const rows = listTareas;

    //--- return principal

    if(sessionStorage.getItem('userData') != null && userData.jerarquia>3 && isAuthorized ){
        return(<>
                    <Header />

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            La información se guardo exitosamente.
                        </Alert>
                    </Snackbar>
                
                    <Container className="mb-5">
				        <h1 className="titulo_login mt-4">Mis tareas</h1>
                        <div className="row mt-1">
                            <div className="col-12">
                                <div className="areas_datagrid">
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pagination
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        paginationMode="server"
                                        rowCount={totalRows}
                                        loading={loading}
                                        onPageChange={(newPage) => {
                                            prevSelectionModel.current = selectionModel;
                                            NextPage(newPage+1);
                                            setPage(newPage+1);
                                        }}
                                        onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        }}
                                        selectionModel={selectionModel}
                                    />
                                </div>
                            </div>
                        </div>
				
			        </Container>

            <Modal size="lg" show={visible2} onHide={handleDeleteFalse2} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Actualizar tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className="row mt-4">
					        <div className="col-md-3">
						        <span className="txt_listado">Título</span>
					        </div>
					        <div className="col-md-9">
						        <span><input type="text" className="form-control" id="inp_titulo" name="titulo" defaultValue={titulo || ''} onChange={OnChangeTitulo} required="required" readOnly /></span>
						        <span className="red_alert">{ errorTitulo }</span>
					        </div>
				        </div>
                        <div className="row mt-4">
					        <div className="col-md-3">
						        <span className="txt_listado">Descripción</span>
					        </div>
					        <div className="col-md-9">
						        <span><input type="text" className="form-control" id="inp_descripcion" name="descripcion" defaultValue={descripcion || ''} onChange={OnChangeDescripcion} required="required" readOnly /></span>
						        <span className="red_alert">{ errorDescripcion }</span>
					        </div>
				        </div>
                        <div className="row mt-4">
					        <div className="col-md-3">
						        <span className="txt_listado">Comentarios</span>
					        </div>
					        <div className="col-md-9">
						        <span><input type="text" className="form-control" id="inp_comentarios" name="comentarios" defaultValue={comentarioFinal || ''} onChange={OnChangeComentarioFinal} required="required" autoComplete='off' /></span>
						        <span className="red_alert">{ errorComentarioFinal }</span>
					        </div>
				        </div>          
                        
                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">Horas trabajadas</span>
                            </div>
                            <div className="col-md-9">
                                <span>
                                    <select className="form-control" id="inp_horas" name="horas" value={horas} onChange={OnChangeHoras} required="required">
                                        <option>Seleccione...</option>
                                        <option value=".5">30 mins</option>
                                        <option value="1">1 hr</option>
                                        <option value="1.5">1 hr y 30 mins</option>
                                        <option value="2">2 hrs</option>
                                        <option value="2.5">2 hrs y 30 mins</option>
                                        <option value="3">3 hrs</option>
                                        <option value="3.5">3 hrs y 30 mins</option>
                                        <option value="4">4 hrs</option>
                                        <option value="4.5">4 hrs y 30 mins</option>
                                        <option value="5">5 hrs</option>
                                        <option value="5.5">5 hrs y 30 mins</option>
                                        <option value="6">6 hrs</option>
                                        <option value="6.5">6 hrs y 30 mins</option>
                                        <option value="7">7 hrs</option>
                                        <option value="7.5">7 hrs y 30 mins</option>
                                        <option value="8">8 hrs</option>
                                    </select>
                                </span>
                                <span className="red_alert">{ errorHoras }</span>
                            </div>
                        </div>

                <div className="row mt-5 mb-2">
					<div className="col-md-3">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-9">
						<span><Button type="submit" value="Guardar" disabled={showBtn}  variant="contained" color='success' className="w-100" id="inp_enviar" name="inp_enviar" onClick={() => handleSubmit()}>Finalizar tarea</Button></span>
					</div>
				</div>
                    </Container>
                </Modal.Body>
            </Modal>


            <Modal size="lg" show={visible3} onHide={handleDeleteFalse3} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className="row">
					        <div className="col-md-3">
						        <span className="txt_listado"><strong>Título</strong></span>
					        </div>
					        <div className="col-md-9">
                                <span className="txt_listado">{tituloSaved || ''}</span>
					        </div>
				        </div>
                        <div className="row mt-4">
					        <div className="col-md-3">
						        <span className="txt_listado"><strong>Descripción</strong></span>
					        </div>
					        <div className="col-md-9">
						        <span className="txt_listado">{descripcionSaved || ''}</span>
					        </div>
				        </div>
                        <div className="row mt-4">
					        <div className="col-md-3">
						        <span className="txt_listado"><strong>Comentarios</strong></span>
					        </div>
					        <div className="col-md-9">
						        <span><textarea className='full_textarea txt_listado' value={comentarioSaved} readOnly={true}></textarea></span>
					        </div>
				        </div>          
                        <div className="row mt-3 mb-4">
                            <div className="col-md-3">
                                <span className="txt_listado"><strong>Horas trabajadas</strong></span>
                            </div>
                            <div className="col-md-9">
                                <span className="txt_listado">{horasSaved || ''}</span>
					        </div>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>


            </>)
    }
    else{
        return <Redirect to="/logout"  />;
    }

}


export default ListadoTareas;