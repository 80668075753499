import React, {useState, useEffect, useRef } from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import './css/Login.css';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
//-------
import { DataGrid } from '@mui/x-data-grid';
import Button  from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const Usuarios = () =>{

    //---------------- hooks ----------------ja
    const [usuarios, setUsuarios]                           = useState([]);
    const [visible , SetVisible]                            = useState({ show: false, id: null });
    const [visible2 , SetVisible2]                          = useState(false);
    const [visible3 , SetVisible3]                          = useState(false);
    const [nombreCompleto, setNombreCompleto]               = useState("");
    const [email, setEmail]                                 = useState("");
    const [jerarquia, setJerarquia]                         = useState("");
    const [password, setPassword]                           = useState("");
    const [repeatPassword, setRepeatPassword]               = useState("");
    const [idModal, setIdModal]                             = useState("");
    const [isAuthorized, setIsAuthorized]                   = useState(true);
    const [statusUser, setStatusUser]                       = useState("");
    const [errorNombreCompleto, setErrorNombreCompleto]     = useState(null);
    const [errorEmail, setErrorEmail]                       = useState(null);
    const [errorJerarquia, setErrorJerarquia]               = useState(null);
    const [errorStatus, setErrorStatus]                     = useState(null);
    const [errorPassword, setErrorPassword]                 = useState(null);
    const [errorRepeatPassword, setErrorRepeatPassword]     = useState(null);

    const [showBtn, setShowBtn]                             = useState(false);
    //---- data grid
    const [loading, setLoading]                             = useState(false);
    const [page, setPage]                                   = useState(0);
    const [selectionModel, setSelectionModel]               = useState([]);
    const prevSelectionModel                                = useRef(selectionModel);
    const [totalRows, setTotalRows]                         = useState(0);

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    
    const OnChangeNombreCompleto = (e) =>{
        setNombreCompleto(e.target.value);        
    }

    const OnChangeEmail = (e) =>{
        setEmail(e.target.value);        
    }

    const OnChangeJerarquia = (e) =>{
        setJerarquia(e.target.value);        
    }

    const OnChangePassword = (e) =>{
        setPassword(e.target.value);        
    }

    const OnChangeStatus = (e) =>{
        setStatusUser(e.target.value);        
    }

    const OnChangeRepeatPassword = (e) =>{
        setRepeatPassword(e.target.value);        
    }

    const updateData = () => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/usuarios')
            .then(response => {
                setIsAuthorized(true);
                setUsuarios(response.data.data.data);
                setTotalRows(response.data.data.total);
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })

        );
        
    }

    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        

        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
        const retrieveData = () => {

            trackPromise(

                instance.get('/usuarios')
                .then(response => {
                    setIsAuthorized(true);
                    setUsuarios(response.data.data.data);
                    setTotalRows(response.data.data.total);
                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })
  
            );
            
        }

        retrieveData();

        }
        

    },[]);

    const updateUsuarios = () => {
        setUsuarios([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/usuarios')
            .then(response => {
                
                setUsuarios(response.data.data.data);
                setTotalRows(response.data.data.total);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const handleDeleteTrue = (id) => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.delete('/usuarios/'+id)
            .then(response => {
                if(response.status === 200){
                    updateUsuarios();
                    NextPage(page);
                    SetVisible({
                        visible: false,
                        id: null,
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    const handleDeleteFalse2 = () => {
        SetVisible2(false);
    }

    const handleDeleteFalse3 = () => {
        SetVisible3(false);
    }

    const openModal = (id) => {
        SetVisible2(true);
        setNombreCompleto(""); 
        setEmail("");
        setJerarquia(""); 
        setPassword(""); 
        setRepeatPassword(""); 
        setShowBtn(false); 
        setIdModal(id);

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('usuarios/'+id)
            .then(response => {
                setNombreCompleto(response.data.data[0].name); 
                setEmail(response.data.data[0].email);
                setJerarquia(response.data.data[0].jerarquia); 
                setStatusUser(response.data.data[0].status); 
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const openModalNew = () => {
        SetVisible3(true);
        setShowBtn(false)
        setNombreCompleto(""); 
        setEmail("");
        setJerarquia("");
        setPassword("");
        setRepeatPassword("");
    }

    const handleSubmit = (id) => {

            let errorsForm = 0;
            setShowBtn(true)
            /* **************************    VALIDACIONES    ****************************** */

            //-------------------------------------------------------------------------------------------
            let regex_letras    = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            //let regex_tipo      = /^[a-zA-Z+àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            //let regex_rfc       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
            //let regex_fecha 	= /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
            
            //--------------------------[		validate inp_nombre_empresa		]-------------------------
            if(nombreCompleto.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreCompleto("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorNombreCompleto("");
            }

           
            //-------------------[		validate email pt 1		]----------------
            if(!email){
                setErrorEmail("Error: email vacío");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorEmail("");
            }
        
            //-------------------[		validate email pt 2		]----------------
            if(typeof email !== "undefined"){
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');
        
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                setErrorEmail("Error: email no válido");
                setShowBtn(false);
                errorsForm = true;
                }
                else{
                    setErrorEmail("");
                }
            }  

            
            //-------------------[				jerarquia 			]----------------

            if(!jerarquia){
                //console.log("Error: solo letras");
                setErrorJerarquia("Error: solo números");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorJerarquia("");
            }


            if(password !== repeatPassword){
                setErrorRepeatPassword("Passwords no coinciden");
                setErrorPassword("Passwords no coinciden");
                setShowBtn(false);
                errorsForm = true;
            }


            //---------------------[      Status      ]---------------

            if(!statusUser){
                setErrorStatus("Error: por favor seleccione una opción válida")
                setShowBtn(false)
                errorsForm = true;
            }
             else{
                setErrorStatus("");
            }



        /* **************************  END VALIDACIONES  ****************************** */

                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });
                    
                    if(!password){
                        trackPromise(
                            instance.put('usuarios/'+idModal, { 
                                name: nombreCompleto, 
                                email: email,
                                status: statusUser,
                                jerarquia: jerarquia
                                })
                            .then(response => {
                            
                                if(response.data.success === true){
                                        alert("El usuario se actualizó exitosamente")
                                        setUsuarios([]);
                                        updateData();
                                        NextPage(page);
                                        setNombreCompleto("");
                                        SetVisible2(false);
                                }
                            
                            })
                            .catch(e => {
                                console.log(e);
                            })
                        );
                    }
                    else{
                        trackPromise(
                            instance.put('usuarios/'+idModal, { 
                                name: nombreCompleto, 
                                email: email,
                                status: statusUser,
                                jerarquia: jerarquia,
                                password: password,
                                c_password: password
                                })
                            .then(response => {
                            
                                if(response.data.success === true){
                                        alert("El usuario se editó exitosamente")
                                        setUsuarios([]);
                                        updateUsuarios();
                                        NextPage(page);
                                        setNombreCompleto(""); 
                                        setPassword("");
                                        setRepeatPassword("");
                                        SetVisible2(false);
                                }
                            
                            })
                            .catch(e => {
                                console.log(e);
                            })
                        );
                    }
                } 
    }

    const handleSubmitNew = () => {

        let errorsForm = 0;
        setShowBtn(true)
            const regex_letras 	= /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
            
            //---------------------- [		validaciones		] --------------------------
            if(nombreCompleto.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreCompleto("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
            }
            else{
                setErrorNombreCompleto("");
            }

                

                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    if(password){
                        trackPromise(
                            instance.post('/usuarios', { 
                                name: nombreCompleto, 
                                email: email,
                                jerarquia: jerarquia,
                                password: password,
                                c_password:password
                            })
                            .then(response => {
                            
                            if(response.data.success === true){
                                    alert("El usuario se guardó exitosamente")
                                    setUsuarios([]);
                                    updateUsuarios();
                                    NextPage(page);
                                    setNombreCompleto(""); 
                                    setEmail("");
                                    setJerarquia("");
                                    setPassword("");
                                    setRepeatPassword("");
                                    SetVisible3(false);
                            }
                            else{
                                setShowBtn(false)
                            }
                            
                            })
                            .catch(e => {
                                console.log(e);
                            })
                        );
                    }
                    else{
                        trackPromise(
                            instance.post('/usuarios', { 
                                name: nombreCompleto, 
                                email: email,
                                jerarquia: jerarquia
                            })
                            .then(response => {
                            
                            if(response.data.success === true){
                                    alert("El usuario se guardó exitosamente")
                                    setUsuarios([]);
                                    updateUsuarios();
                                    setNombreCompleto(""); 
                                    setEmail("");
                                    setJerarquia("");
                                    setPassword("");
                                    setRepeatPassword("");
                                    SetVisible3(false);
                            }
                            else{
                                setShowBtn(false)
                            }
                            
                            })
                            .catch(e => {
                                console.log(e);
                            })
                        );  
                    }
                } 
                else{
                    setShowBtn(false)
                }
    }

    const showConfirm = (id) => {
        SetVisible({visible: true, id: id});
    }

    //-----------

    const NextPage = (page) => {

        setLoading(true);
        setUsuarios([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/usuarios?page='+page)
            .then(response => {
                setUsuarios(response.data.data.data);
                setTotalRows(response.data.data.total);
                setLoading(false);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const columns = [
        // { field: 'id', 
        //     headerName: 'ID', 
        //     headerClassName: 'letal',
        //     width: 90 },
        {
          field: 'name',
          headerName: 'Nombre',
          minWidth: 200,
          flex: .4,
          headerClassName: 'letal',
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: .5,
            headerClassName: 'letal',
          },
          {
            field: 'jerarquia',
            flex: 0.5,
            headerName: 'Jerarquia',
            headerClassName: 'letal',
            minWidth: 100,
            renderCell: (params) => {
                  let txt_jerarquia = "";
                  //console.log(params.row.status);
                  switch (params.row.jerarquia) {
                        case 1:
                          txt_jerarquia = "Superadministrador"
                          break;
                        case 2:
                              txt_jerarquia = "Administrador"
                          break;
                        case 3:
                            txt_jerarquia = "Vista"
                            break;
                        case 4:
                                txt_jerarquia = "Empleado"
                            break;
                      default:
                          txt_jerarquia = "-"
                          break;
                  }
  
                  return txt_jerarquia;
            },
          },
        {
          field: 'created_at',
          flex: 0.5,
          headerName: 'Fecha creación',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
            let cut;
            
              if(params.row.created_at !== undefined){
                cut = params.row.created_at.split("T");
                return cut[0];
              }
              else{
                  return (<></>);
              }
               
            //return params.created_at;
            
          },
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 250,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                    <Tooltip title="Borrar">
                        <Button
                            variant="text"
                            color="error"
                            size="large"
                            className="pr-1 mr-1 btn_emp"
                            startIcon={<DeleteIcon />}
                            onClick={() => showConfirm(params.id)}
                        />
                    </Tooltip>

                    <Tooltip title="Editar">
                        <Button
                            variant="text"
                            color="primary"
                            size="large"
                            className="mr-1"
                            startIcon={<EditIcon />}
                            onClick={() => openModal(params.id)}
                        />
                    </Tooltip>
                </strong>
            ),
        }
        
    
    ];

    const rows = usuarios;
    
    ///----------------------[  main return  ]---------------------------
      
     if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized){
        return(<>
                <Container className="mr-inf">
                <div className="row">
                        <div className="col-10">
                            <div className="titulo_login">Usuarios</div>
                        </div>
                        <div className="col-2">
                        <IconButton aria-label="delete" className="mt-2 float-right" color="error" size="large" onClick={() => openModalNew()}>
                            <AddCircle fontSize="inherit" className="new-icon"  />
                            <h1 className="add-new ml-2 mt-2">Agregar</h1>
                        </IconButton>
                        {/* <h1 className="addEmpleado handy" onClick={() => openModalNew()}>
                                <FontAwesomeIcon icon={faPlus} className="icono_blanco" />
                            </h1> */}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="areas_datagrid">
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>

            </Container>

            <Modal size="lg" show={visible.visible} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton className="no_linea_inferior">
                    <Modal.Title className="titulo_login">¿Estas seguro que deseas eliminarlo?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrue(visible.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalse()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible2} onHide={handleDeleteFalse2} animation={false}>
                <Modal.Header closeButton className="no_linea_inferior">
                    <Modal.Title className="titulo_login">Editar usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    
                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Nombre completo</span>
					    </div>
					    <div className="col-md-8">
						    <span><input type="text" className="form-control" id="inp_nombre_proyecto" autocomplete="off" name="nombre_area" defaultValue={nombreCompleto || ''} onKeyUp={OnChangeNombreCompleto} required="required" /></span>
						    <span className="red_alert">{ errorNombreCompleto }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Email</span>
					    </div>
					    <div className="col-md-8">
						    <span>
                                <input type="email" className="form-control"  name="email" defaultValue={email || ''} autoComplete="off" onChange={OnChangeEmail} /></span>
						    <span className="red_alert">{ errorEmail }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Jerarquía</span>
					    </div>
					    <div className="col-md-8">
						    <span>
                            

                                {(() => {
                                    if (userData.jerarquia === 1) {
                                    return (
                                        <>
                                        <select name="jerarquia" value={jerarquia || ''} onChange={OnChangeJerarquia} className="form-control" >
                                            <option value="">Seleccione...</option>
                                            <option value="1">Superadministrador</option>
                                            <option value="2">Administrador</option>
                                            <option value="3">Vista</option>
                                            <option value="4">Empleado</option>
                                        </select>
                                        </>
                                    )
                                    } else if (userData.jerarquia === 2) {

                                        if(jerarquia< 2 || jerarquia===4){
                                            return (
                                                    <>
                                                    <select name="jerarquia" value={jerarquia || ''} onChange={OnChangeJerarquia} className="form-control" disabled>
                                                        <option value="">Seleccione...</option>
                                                        <option value="1" disabled>Superadministrador</option>
                                                        <option value="2">Administrador</option>
                                                        <option value="3">Vista</option>
                                                        <option value="4">Empleado</option>
                                                    </select>
                                                    </>
                                                )
                                        }
                                        else{
                                            return (
                                                    <>
                                                    <select name="jerarquia" value={jerarquia || ''} onChange={OnChangeJerarquia} className="form-control">
                                                        <option value="">Seleccione...</option>
                                                        <option value="1" disabled>Superadministrador</option>
                                                        <option value="2">Administrador</option>
                                                        <option value="3">Vista</option>
                                                        <option value="4">Empleado</option>
                                                    </select>
                                                    </>
                                                )
                                        }
                                    
                                    } else {
                                    return (
                                        <>
                                        
                                        </>
                                    )
                                    }
                                })()}

                                
                            </span>
						    <span className="red_alert">{ errorJerarquia }</span>
					    </div>
				    </div>
                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Status</span>
					    </div>
					    <div className="col-md-8">
						    <span><select name="jerarquia" value={statusUser || ''} onChange={OnChangeStatus} className="form-control" >
                                        <option value="">Seleccione...</option>
                                        <option value="1">Activo</option>
                                        <option value="2">Inactivo</option>
                                    </select></span>
						    <span className="red_alert">{ errorStatus }</span>
					    </div>
				    </div>
                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Password</span>
					    </div>
					    <div className="col-md-8">
						    <span><input type="password" className="form-control" id="inp_password" name="password" onKeyUp={OnChangePassword} required="required" autocomplete="new-password" /></span>
						    <span className="red_alert">{ errorPassword }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Confirmación password</span>
					    </div>
					    <div className="col-md-8">
						    <span><input type="password" className="form-control" id="inp_password2" name="password2" onKeyUp={OnChangeRepeatPassword} required="required" /></span>
						    <span className="red_alert">{ errorRepeatPassword }</span>
					    </div>
				    </div>

				
                    <div className="row mt-3 mb-5">
                        <div className="col-md-4">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-md-8">
                            <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmit}  /></span>
                        </div>
                    </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible3} onHide={handleDeleteFalse3} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title className="titulo_login">Agregar usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Nombre completo</span>
					    </div>
					    <div className="col-md-8">
						    <span><input type="text" className="form-control" id="inp_nombre_proyecto" name="nombre_area" defaultValue={nombreCompleto || ''} onKeyUp={OnChangeNombreCompleto} required="required" /></span>
						    <span className="red_alert">{ errorNombreCompleto }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Email</span>
					    </div>
					    <div className="col-md-8">
						    <span><input type="text" className="form-control"  defaultValue={email || ''} onKeyUp={OnChangeEmail} required="required" /></span>
						    <span className="red_alert">{ errorEmail }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Jerarquía</span>
					    </div>
					    <div className="col-md-8">
						    <span>
                            

                                {(() => {
                                    if (userData.jerarquia === 1) {
                                    return (
                                        <>
                                        <select name="jerarquia" value={jerarquia || ''} onChange={OnChangeJerarquia} className="form-control" >
                                            <option value="">Seleccione...</option>
                                            <option value="1">Superadministrador</option>
                                            <option value="2">Administrador</option>
                                            <option value="3">Vista</option>
                                        </select>
                                        </>
                                    )
                                    } else if (userData.jerarquia === 2) {

                                        if(jerarquia && jerarquia< 2){
                                            return (
                                                    <>
                                                    <select name="jerarquia" value={jerarquia || ''} onChange={OnChangeJerarquia} className="form-control" disabled>
                                                        <option value="">Seleccione...</option>
                                                        <option value="1" disabled>Superadministrador</option>
                                                        <option value="2">Administrador</option>
                                                        <option value="3">Vista</option>
                                                    </select>
                                                    </>
                                                )
                                        }
                                        else{
                                            return (
                                                    <>
                                                    <select name="jerarquia" value={jerarquia || ''} onChange={OnChangeJerarquia} className="form-control">
                                                        <option value="">Seleccione...</option>
                                                        <option value="1" disabled>Superadministrador</option>
                                                        <option value="2">Administrador</option>
                                                        <option value="3">Vista</option>
                                                    </select>
                                                    </>
                                                )
                                        }
                                    
                                    } else {
                                    return (
                                        <>
                                        
                                        </>
                                    )
                                    }
                                })()}

                                
                            </span>
						    <span className="red_alert">{ errorJerarquia }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Password</span>
					    </div>
					    <div className="col-md-8">
						    <span><input type="password" className="form-control" id="inp_password" name="password" onKeyUp={OnChangePassword} required="required" autoComplete="new-password" /></span>
						    <span className="red_alert">{ errorPassword }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-4">
						    <span>Confirmación password</span>
					    </div>
					    <div className="col-md-8">
						    <span><input type="password" className="form-control" id="inp_password2" name="password2" onKeyUp={OnChangeRepeatPassword} required="required" /></span>
						    <span className="red_alert">{ errorRepeatPassword }</span>
					    </div>
				    </div>

				
                    <div className="row mt-3 mb-5">
                        <div className="col-md-4">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-md-8">
                            <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmitNew}  /></span>
                        </div>
                    </div>
                    </Container>
                </Modal.Body>
            </Modal>

        </>)
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default Usuarios;