import React, {useState, useEffect, useCallback, useRef } from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import { faTrash } from "@fortawesome/free-solid-svg-icons"; //faHome,
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import moment from "moment";
import {useDropzone} from 'react-dropzone'
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; // eslint-disable-line
import 'react-tabs/style/react-tabs.css';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid } from '@mui/x-data-grid';
import Button  from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
//------------
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import es from 'date-fns/locale/es';
import { getDay } from 'date-fns';
registerLocale('es', es);


//---------
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

//---------

const Empleados = () =>{

    //-------- hooks --------
    const [empleados, setEmpleados]                                 = useState([]);
    const [visible , SetVisible]                                    = useState({ show: false, id: null });
    const [visible2 , SetVisible2]                                  = useState(false);
    const [visible3 , SetVisible3]                                  = useState(false);
    const [visible4 , SetVisible4]                                  = useState(false);
    const [visible5 , SetVisible5]                                  = useState(false);
    const [idToChange, setIdToChange]                               = useState("");
    const [nombres, setNombres]                                     = useState("");
    const [apellidoPaterno, setApellidoPaterno]                     = useState("");
    const [apellidoMaterno, setApellidoMaterno]                     = useState("");
    const [email, setEmail]                                         = useState("");
    const [celular, setCelular]                                     = useState("");
    const [status, setStatus]                                       = useState("");
    const [area, setArea]                                           = useState("");
    const [listadoAreas, setListadoAreas]                           = useState("");
    const [puesto, setPuesto]                                       = useState("");
    const [tipoSangre, setTipoSangre]                               = useState("");
    const [fechaNacimiento, setFechaNacimiento]                     = useState("");
    const [telefonoEmergencia, setTelefonoEmergencia]               = useState("");
    const [RFC, setRFC]                                             = useState("");
    const [fechaIngreso, setFechaIngreso]                           = useState("");
    const [password, setPassword]                                   = useState("");
    const [repeatPassword, setRepeatPassword]                       = useState("");
    const [isAuthorized, setIsAuthorized]                           = useState(true);
    const [btnDisabled, setBtnDisabled]                             = useState(false);
    const [loadingUploading, setLoadingUploading]                   = useState(false)

    const [errorNombres, setErrorNombres]                           = useState(null);
    const [errorApellidoPaterno, setErrorApellidoPaterno]           = useState(null);
    const [errorApellidoMaterno, setErrorApellidoMaterno]           = useState(null);
    const [errorEmail, setErrorEmail]                               = useState(null);
    const [errorCeluar, setErrorCelular]                            = useState(null);
    const [errorPuesto, setErrorPuesto]                             = useState(null);
    const [errorArea, setErrorArea]                                 = useState(null);
    const [errorTipoSangre, setErrorTipoSangre]                     = useState(null);
    const [errorFechaNacimiento, setErrorFechaNacimiento]           = useState(null);
    const [errorTelefonoEmergencia, setErrorTelefonoEmergencia]     = useState(null);
    const [errorRFC, setErrorRFC]                                   = useState(null);
    const [errorFechaIngreso, setErrorFechaIngreso]                 = useState(null);
    const [errorPassword, setErrorPassword]                         = useState(null);
    const [errorRepeatPassword, setErrorRepeatPassword]             = useState(null);
    const [showBtn, setShowBtn]                                     = useState(false);

    // ------ vacaciones ------
    const [misDatos, setMisDatos]                                   = useState([]);
    const [listadoVacacionesNuevas, setListadoVacacionesNuevas ]    = useState("");    
    const [listadoVacacionesPasadas, setListadoVacacionesPasadas ]  = useState("");  
    const [tipoVacacion, setTipoVacacion]                           = useState(); 
    const [dataGo, setDataGo]                                       = useState([]);
    const [showDatesInput, setShowDatesInput]                       = useState(true);
    //const [vigenciaVacaciones, setVigenciaVacaciones]               = useState("");
    const [diasDisponiblesSelect, setDiasDisponiblesSelect]         = useState("-");
    // ------ calendario --------
    const [startDate, setStartDate]                                 = useState();
    const [endDate, setEndDate]                                     = useState(null);
   
    // ------    files    --------
    const [activeFiles, setActiveFiles ]                            = useState([]);

    //-------------------- nuevo data grid -------------------
    const [loading, setLoading]                                     = useState(false);
    const [page, setPage]                                           = useState(0);
    const [selectionModel, setSelectionModel]                       = useState([]);
    const prevSelectionModel                                        = useRef(selectionModel);
    const [totalRows, setTotalRows]                                 = useState(0);    
    const [value, setValue]                                         = useState(0);
    const [open, setOpen]                                           = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };


    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //------------------------------------------------------


    // ---------- vacaciones ----------

    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };

    const getDaysArray = (start, end) => {
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt));
        }
        return arr;
    };

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        let total_vacaciones;
        if(tipoVacacion === "pasado")
            total_vacaciones = misDatos.vacaciones_pasadas.vacaciones_libres;
        else
            total_vacaciones = misDatos.vacaciones_nuevas.vacaciones_libres;
       

        if(start && end){
            
            let array = getDaysArray(start,end);
            let laborables = 0;
            for (let index = 0; index < array.length; index++) {
                if( array[index].getDay()>0 && array[index].getDay()<6 )
                    laborables++
            }

            alert("seleccionaste "+laborables+" días de vacaciones y tienes "+total_vacaciones+" días disponibles");
            if(total_vacaciones>=laborables){
                if(total_vacaciones>=laborables){
                    setDataGo({end: end, start: start, laborables: laborables})
                    setShowBtn(false)
                }
                else{
                    setShowBtn(true)
                }
            }
            else{
                setShowBtn(true)
            }
                
        }
    };
    
    const onChangeVacationsType = (e) => {
        
        

        let hoy = moment(new Date()).format("YYYY-MM-DD");
        //console.log(misDatos);
        setTipoVacacion(e.target.value)
        if(e.target.value === "pasado" && misDatos.vacaciones_pasadas){
            if(misDatos.vacaciones_pasadas && (misDatos.vacaciones_pasadas.fecha_final>hoy)){
                setDiasDisponiblesSelect(misDatos.vacaciones_pasadas.vacaciones_libres);
                if(startDate && endDate && misDatos.vacaciones_pasadas.vacaciones_libres>0)
                    setShowBtn(false)
                else
                    setShowBtn(true)


                if(misDatos.vacaciones_pasadas.vacaciones_libres <1){
                    setShowDatesInput(true);
                }
                else{
                    setShowDatesInput(false);
                }
                //setVigenciaVacaciones(misDatos.vacaciones_pasadas.fecha_final);
            }
            else{
                setShowDatesInput(true);
            }
            
            
        }
        else if(e.target.value === "actual" && misDatos.vacaciones_nuevas){
            if(misDatos.vacaciones_nuevas.fecha_final>hoy)
                setDiasDisponiblesSelect(misDatos.vacaciones_nuevas.vacaciones_libres);
                if(startDate && endDate && misDatos.vacaciones_nuevas.vacaciones_libres>0)
                    setShowBtn(false)
                else
                    setShowBtn(true)
            if(misDatos.vacaciones_nuevas.vacaciones_libres <1){
                setShowDatesInput(true);
            }
            else{
                setShowDatesInput(false);
            }
            //setVigenciaVacaciones(misDatos.vacaciones_nuevas.fecha_final);
        }

    };

    // -------- end vacaciones ---------

    const OnChangeNombres = (e) =>{
        setNombres(e.target.value);        
    }

    const OnChangeApellidoPaterno = (e) =>{
        setApellidoPaterno(e.target.value);        
    }

    const OnChangeApellidoMaterno = (e) =>{
        setApellidoMaterno(e.target.value);        
    }

    const OnChangeEmail = (e) =>{
        setEmail(e.target.value);        
    }

    const OnChangeCelular = (e) =>{
        setCelular(e.target.value);        
    }

    const OnChangePuesto = (e) =>{
        setPuesto(e.target.value);        
    }

    const OnChangeArea = (e) =>{
        setArea(e.target.value);        
    }

    const OnChangeTipoSangre = (e) =>{
        setTipoSangre(e.target.value);        
    }

    const OnChangeFechaNacimiento = (e) =>{
        setFechaNacimiento(e.target.value);        
    }

    const OnChangeTelefonoEmergencia = (e) =>{
        setTelefonoEmergencia(e.target.value);        
    }

    const OnChangeRFC = (e) =>{
        setRFC(e.target.value);        
    }

    const OnChangeStatus = (e) =>{
        setStatus(e.target.value);        
    }

    const OnChangeFechaIngreso = (e) =>{
        setFechaIngreso(e.target.value);        
    }

    const OnChangePassword = (e) =>{
        setPassword(e.target.value);        
    }

    const OnChangeRepeatPassword = (e) =>{
        setRepeatPassword(e.target.value);        
    }

    const updateData = () => {
        setEmpleados([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/empleados')
            .then(response => {
                setIsAuthorized(true);
                setEmpleados(response.data.data.data);
                setTotalRows(response.data.data.total);
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log(e);
            })

        );
        
    }
  
    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia<3){ 
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
        const retrieveData = () => {

            //-------------------------- vencimiento de sesion ----------------
            trackPromise(
                instance.get('/validate-token')
                .then(response => {
                    setIsAuthorized(true);
                })
                .catch(e => {
                    setIsAuthorized(false);
                })
            );
            //------------------------ end vencimiento de sesion ---------------

            trackPromise(
                instance.get('/empleados')
                .then(response => {
                    setIsAuthorized(true);
                    setEmpleados(response.data.data.data);
                    setTotalRows(response.data.data.total);
                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })
  
            );

            trackPromise(
                instance.get('/areas')
                .then(response => {
                  setIsAuthorized(true);
                  setListadoAreas(response.data.data.data);
                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })
  
            );

            
            
        }

        retrieveData();

        }
        

    },[]);

    const handleDeleteTrue = (id) => {
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.delete('/empleados/'+id)
            .then(response => {
                if(response.status === 200){
                    updateData();
                    NextPage(page);
                    SetVisible({
                        visible: false,
                        id: null,
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    const handleDeleteFalse2 = () => {
        SetVisible2(false);
    }

    const handleDeleteFalse3 = () => {
        SetVisible3(false);
    }

    const handleDeleteFalse4 = () => {
        SetVisible4(false);
    }

    const handleDeleteFalse5 = () => {
        SetVisible5(false);
    }

    const openModal = (id) => {
        setNombres("");
        setApellidoPaterno("");
        setApellidoMaterno("");
        setEmail("");
        setPuesto("");
        setCelular("");
        setArea("");
        setTipoSangre("");
        setFechaNacimiento("");
        setTelefonoEmergencia("");
        setRFC("");
        setFechaIngreso("");
        //setStatusUser("");
        //set("");
        handleDeleteFalse5();
        setShowDatesInput(false);
        SetVisible5(true);
        SetVisible2(false);
        setIdToChange(id);
        setShowBtn(false); 
        UpdateFilesFromServer(id);

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('empleados/'+id)
            .then(response => {
                setNombres(response.data.data.nombres);
                setApellidoPaterno(response.data.data.apellido_paterno);
                setApellidoMaterno(response.data.data.apellido_materno);
                setEmail(response.data.data.email);
                setCelular(response.data.data.celular);
                setPuesto(response.data.data.puesto);
                setArea(response.data.data.id_area);
                setTipoSangre(response.data.data.tipo_sangre);
                setFechaNacimiento(response.data.data.fecha_nacimiento);
                setTelefonoEmergencia(response.data.data.tel_emergencia);
                setRFC(response.data.data.RFC);
                setFechaIngreso(response.data.data.fecha_ingreso);
                setStatus(response.data.data.status);
            
            })
              .catch(e => {
                console.log(e);
              })
          );
        
    }

    const openModalCarga = (id) => {
        setDiasDisponiblesSelect("-");
        
        setEndDate(null)
        setStartDate(null)
        handleDeleteFalse2();
        setShowDatesInput(false);
        SetVisible2(true);
        SetVisible4(false);
        setIdToChange(id);
        setShowBtn(true); 

    }

    const openModalNew = () => {
        SetVisible3(true);
        setShowBtn(false)
        setNombres("");
        setApellidoPaterno("");
        setApellidoMaterno("");
        setApellidoMaterno(""); 
        setEmail(""); 
        setCelular(""); 
        setPuesto("");
        setArea("");
        setTipoSangre("");
        setFechaNacimiento("");
        setTelefonoEmergencia("");
        setRFC("");
        setFechaIngreso("");
        setPassword("");
        setRepeatPassword("");
        
    }

    const handleSubmit = (id) => {
        
        setShowBtn(true);
        let errorsForm = false;

        /* **************************    VALIDACIONES    ****************************** */

            //let regex_letras = /^[a-zA-Z]+(\s*[a-zA-Z]*)*[a-zA-Z]+$/;
            //-------------------------------------------------------------------------------------------
            let regex_letras    = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            let regex_rfc       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
            //let regex_fecha 	= /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
            let regex_fecha_ok  = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/; 
            let regex_tipo      = /^[a-zA-Z+àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            

            //--------------------------[		validate inp_nombres	]-------------------------
            if(nombres.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombres("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorNombres("");
            }

            //-------------------[		validate categoria		]----------------
            if(apellidoPaterno.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorApellidoPaterno("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorApellidoPaterno("");
            }

            //-------------------[		validate apellido materno		]----------------
            if(apellidoMaterno.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorApellidoMaterno("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorApellidoMaterno("");
            }

            //-------------------[		validate email pt 1		]----------------
            if(!email){
                setErrorEmail("Error: email vacío");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorEmail("");
            }
        
            //-------------------[		validate email pt 2		]----------------
            if(typeof email !== "undefined"){
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');
        
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                setErrorEmail("Error: email no válido");
                setShowBtn(false);
                errorsForm = true;
                }
                else{
                    setErrorEmail("");
                }
            }  

            
            //-------------------[				celular 			]----------------

            if(celular.match(/^[0-9]+$/) == null){
                //console.log("Error: solo letras");
                setErrorCelular("Error: solo números");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorCelular("");
            }


            //-------------------[		validate puesto	    ]----------------
            if(puesto.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorPuesto("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorPuesto("");
            }


            //-------------------[		validate area	    ]----------------
            if(!area){
                //console.log("Error: solo letras");
                setErrorArea("Error: por favor seleccione una opción válida");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorArea("");
            }


            //-------------------[		validate inp_tipo_sangre	]----------------
            if(tipoSangre.match(regex_tipo) == null){
                //console.log("Error: solo letras");
                setErrorTipoSangre("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorTipoSangre("");
            }

            //-------------------[  	Fecha nacimiento	 ]----------------
            if(fechaNacimiento.match(regex_fecha_ok) == null){
                //console.log("Error: solo letras");
                setErrorFechaNacimiento("Error: Fecha no válida");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorFechaNacimiento("");
            }

            //-------------------[			tel emergencia 			]----------------

            if(telefonoEmergencia.match(/^[0-9]+$/) == null){
                //console.log("Error: solo letras");
                setErrorTelefonoEmergencia("Error: solo números");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorTelefonoEmergencia("");
            }


             //-------------------[		validate RFC		]----------------
             if(RFC.match(regex_rfc) == null){
                //console.log("Error: solo letras");
                setErrorRFC("Error: RFC No válido");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorRFC("");
            }


            //-------------------[  	Fecha ingreso	 ]----------------
            if(fechaIngreso.match(regex_fecha_ok) == null){
                //console.log("Error: solo letras");
                setErrorFechaIngreso("Error: Fecha no válida");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorFechaIngreso("");
            }


        /* **************************  END VALIDACIONES  ****************************** */
       
        
        if(!errorsForm){

            //---------------- call api ---------------- 
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });

            trackPromise(

                instance.put('/empleados/'+id,{
                    id_area: area,
                    nombres: nombres,
                    apellido_paterno: apellidoPaterno,
                    apellido_materno: apellidoMaterno,
                    email: email,
                    celular: celular,
                    puesto: puesto,
                    tipo_sangre: tipoSangre,
                    fecha_nacimiento: fechaNacimiento,
                    tel_emergencia: telefonoEmergencia,
                    RFC: RFC,
                    fecha_ingreso: fechaIngreso,
                    status: status
                })
                .then(response => {
                    setIsAuthorized(true);
                    setEmpleados([]);
                    setMisDatos([]);
                    setOpen(true);
                    NextPage(page);
                    SetVisible5(false);
                    //showVacations(id);
                })
                .catch(e => {
                    console.log(e.message);
                    console.log("Expired token - Error #45435");
                    //setIsAuthorized(false);
                })

            );
            
            //---------------- end call api ---------------- 
        
        }
        else{
            alert("Existen errores en los datos, favor de verificar")
        }       
    }

    const handleSubmitVac = (id_emp) => {
        setShowBtn(true);
        if(endDate){
            
            //---------------- call api ---------------- 
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });

            trackPromise(

                instance.post('/vacaciones/add',{
                    id_empleado: id_emp,
                    cantidad_dias: dataGo.laborables,
                    fecha_inicio: moment(dataGo.start).format("YYYY-MM-DD"),
                    fecha_fin: moment(dataGo.end).format("YYYY-MM-DD"),
                    tipo: tipoVacacion
                })
                .then(response => {
                    setIsAuthorized(true);
                    setMisDatos([])
                    SetVisible(false)
                    SetVisible2(false)
                    showVacations(id_emp)
                    retrieveDataRefresh(id_emp)
                })
                .catch(e => {
                    console.log("Expired token - Error #45435");
                    setIsAuthorized(false);
                })

            );
            
            //---------------- end call api ---------------- 
        }   
        else
            alert("Por favor seleccione un rango de fechas válido");

    }


    const retrieveDataRefresh = (id_emp) => {
                
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        
        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        //------- nuevas cosas -------

        trackPromise(

            instance.get('/vacaciones/get/'+id_emp)
            .then(response => {
                setIsAuthorized(true);
                setMisDatos(response.data.data);
            })
            .catch(e => {
                console.log("Expired token - Error #45435");
                setIsAuthorized(false);
            })

        );
        
        
        if(misDatos){
            
            let anio = misDatos.anios_laborando
            trackPromise(
                            
                instance.get('/vacaciones/list/'+id_emp+'/'+anio)
                .then(response => {
                    setIsAuthorized(true);
                    setListadoVacacionesNuevas(response.data.data.data);
                })
                .catch(e => {
                    console.log("Expired token - Error #45435");
                    setIsAuthorized(false);
                })

            );

            trackPromise(
                            
                instance.get('/vacaciones/list/'+id_emp+'/'+(anio-1))
                .then(response => {
                    setIsAuthorized(true);
                    setListadoVacacionesPasadas(response.data.data.data);
                })
                .catch(e => {
                    console.log("Expired token - Error #45435");
                    setIsAuthorized(false);
                })

            );
        }

    
    }

    const handleSubmitNew = (e) => {

        let errorsForm = false;
        setShowBtn(true);
        e.preventDefault();
        
            /* **************************    VALIDACIONES    ****************************** */

            //let regex_letras = /^[a-zA-Z]+(\s*[a-zA-Z]*)*[a-zA-Z]+$/;
            //-------------------------------------------------------------------------------------------
            let regex_letras    = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            let regex_rfc       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/; // eslint-disable-next-line
            //let regex_fecha 	= /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/; // eslint-disable-next-line
            let regex_fecha_ok  = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/; 
            let regex_tipo      = /^[a-zA-Z+àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            

            //--------------------------[		validate inp_nombres	]-------------------------
            if(nombres.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombres("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorNombres("");
            }

            //-------------------[		validate categoria		]----------------
            if(apellidoPaterno.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorApellidoPaterno("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorApellidoPaterno("");
            }

            //-------------------[		validate apellido materno		]----------------
            if(apellidoMaterno.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorApellidoMaterno("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorApellidoMaterno("");
            }

            //-------------------[		validate email pt 1		]----------------
            if(!email){
                setErrorEmail("Error: email vacío");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorEmail("");
            }
        
            //-------------------[		validate email pt 2		]----------------
            if(typeof email !== "undefined"){
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');
        
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                setErrorEmail("Error: email no válido");
                setShowBtn(false);
                errorsForm = true;
                }
                else{
                    setErrorEmail("");
                }
            }  

            
            //-------------------[				celular 			]----------------

            if(celular.match(/^[0-9]+$/) == null){
                //console.log("Error: solo letras");
                setErrorCelular("Error: solo números");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorCelular("");
            }


            //-------------------[		validate puesto	    ]----------------
            if(puesto.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorPuesto("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorPuesto("");
            }


            //-------------------[		validate area	    ]----------------
            if(!area){
                //console.log("Error: solo letras");
                setErrorArea("Error: por favor seleccione una opción válida");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorArea("");
            }


            //-------------------[		validate inp_tipo_sangre	]----------------
            if(tipoSangre.match(regex_tipo) == null){
                //console.log("Error: solo letras");
                setErrorTipoSangre("Error: solo letras");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorTipoSangre("");
            }

            //-------------------[  	Fecha nacimiento	 ]----------------
            if(fechaNacimiento.match(regex_fecha_ok) == null){
                //console.log("Error: solo letras");
                setErrorFechaNacimiento("Error: Fecha no válida");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorFechaNacimiento("");
            }

            //-------------------[			tel emergencia 			]----------------

            if(telefonoEmergencia.match(/^[0-9]+$/) == null){
                //console.log("Error: solo letras");
                setErrorTelefonoEmergencia("Error: solo números");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorTelefonoEmergencia("");
            }


             //-------------------[		validate RFC		]----------------
             if(RFC.match(regex_rfc) == null){
                //console.log("Error: solo letras");
                setErrorRFC("Error: RFC No válido");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorRFC("");
            }


            //-------------------[  	Fecha ingreso	 ]----------------
            if(fechaIngreso.match(regex_fecha_ok) == null){
                //console.log("Error: solo letras");
                setErrorFechaIngreso("Error: Fecha no válida");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorFechaIngreso("");
            }

            if(password !== repeatPassword){
                setErrorRepeatPassword("Passwords no coinciden");
                setErrorPassword("Passwords no coinciden");
                setShowBtn(false);
                errorsForm = true;
            }

        /* **************************  END VALIDACIONES  ****************************** */



                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    trackPromise(
                        instance.post('/empleados', { 
                            nombres: nombres, 
                            apellido_paterno: apellidoPaterno,
                            apellido_materno: apellidoMaterno, 
                            email: email,
                            celular: celular,
                            puesto: puesto,
                            id_area: area,
                            tipo_sangre: tipoSangre,
                            fecha_nacimiento: fechaNacimiento,
                            tel_emergencia: telefonoEmergencia,
                            RFC: RFC,
                            fecha_ingreso: fechaIngreso,
                            status: status,
                            password: password
                            })
                        .then(response => {
                        
                          if(response.data.message !== "Error400"){
                                setOpen(true);
                                setEmpleados([]);
                                //updateData();
                                setNombres(""); 
                                setApellidoPaterno(""); 
                                setApellidoMaterno(""); 
                                setEmail("");
                                setCelular("");
                                setPuesto("");
                                setArea("");
                                setTipoSangre("");
                                setFechaNacimiento("");
                                setTelefonoEmergencia("");
                                setRFC("");
                                setFechaIngreso("");
                                setStatus("");
                                setPassword("");
                                SetVisible3(false);
                                NextPage(page);
                                
                          }
                          else{
                            alert("El usuario ya existe en la base de datos");
                            setShowBtn(false);
                          }
                          
                        })
                          .catch(e => {
                            console.log(e);
                          })
                      );
                }
                else{
                    alert("Existen errores en los datos.");
                }
                
    }

    const showConfirm = (id) => {
        SetVisible({visible: true, id: id});
    }

    const showVacations = (id) => {
        SetVisible4({visible: true, id: id});
        
        //Vengaaaaaa
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        let anio;

                trackPromise(
                    instance.get('/vacaciones/get/'+id)
                    .then(response => {
                        setIsAuthorized(true);
                        //console.log(response.data.data)
                        setMisDatos(response.data.data);
                        anio = response.data.data.anios_laborando
                        
                        // -------------------------- [ vacaciones nuevas ] --------------------------
                        trackPromise(
                        
                            instance.get('/vacaciones/list/'+id+'/'+anio)
                            .then(response => {
                                setIsAuthorized(true);
                                setListadoVacacionesNuevas(response.data.data.data);
                            })
                            .catch(e => {
                                console.log("Expired token - Error #45435");
                                setIsAuthorized(false);
                            })
                
                        );
                        // -------------------------- [ vacaciones pasadas ] --------------------------
                        trackPromise(
                        
                            instance.get('/vacaciones/list/'+id+'/'+(anio-1))
                            .then(response => {
                                setIsAuthorized(true);
                                setListadoVacacionesPasadas(response.data.data.data);
                            })
                            .catch(e => {
                                console.log("Expired token - Error #45435");
                                setIsAuthorized(false);
                            })
                
                        );
                        // ---------------------------

                    })
                    .catch(e => {
                        console.log("Expired token - Error #45435");
                        setIsAuthorized(false);
                    })
    
                );
        //end vengaaaaa
    }

     const NextPage = (page) => {

        setLoading(true);
        setEmpleados([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/empleados?page='+page)
            .then(response => {
                setEmpleados(response.data.data.data);
                setTotalRows(response.data.data.total);
                setLoading(false);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    // --------------- vacaciones ---------------
    const totales = (tipo) => {

        let total_vacaciones;
        if(misDatos.vacaciones_nuevas != null)
            total_vacaciones = misDatos.vacaciones_nuevas.vacaciones_libres;
        if(misDatos.vacaciones_pasadas != null)
            total_vacaciones = misDatos.vacaciones_nuevas.vacaciones_libres + misDatos.vacaciones_pasadas.vacaciones_libres;
        
        let hoy = moment(new Date()).format("YYYY-MM-DD");
        //console.log(misDatos.vacaciones_nuevas.fecha_final+" - "+hoy)
        if(misDatos.vacaciones_nuevas){
            if(misDatos.vacaciones_nuevas.fecha_final<hoy)
                total_vacaciones = 0;
        }
        

        return (
            <>
                <h2 className="mt-4 titulo_login">Total de días disponibles</h2>
                <div className="row">
                    <div className="col-6">
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días disponibles</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{total_vacaciones}</span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }
    
    const vacacionesNuevas = () => {
    
        if (misDatos.vacaciones_nuevas) {
        return (
            <>
                <h2 className="mt-4 titulo_login">Vacaciones del periodo actual</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días de vacaciones</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_nuevas.vacaciones_reales }</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días usados</span>
                            </div>
                            <div className="col-md-6">
                                    <span className="txt_listado">{misDatos.vacaciones_nuevas.vacaciones_usadas}</span>
                            </div>
                        </div>
            
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Vacaciones disponibles</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_nuevas.vacaciones_libres}</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Vigencia</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_nuevas.fecha_final}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                    {
                        listadoVacacionesNuevas.length>0 &&
                        <>
                            <div className="row mt-2">
                            <div className="col-md-3"><span className="txt_listado_bold">Cantidad días</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Fecha inicio</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Fecha fin</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Status</span></div>
                        </div>
                        </>
                        
                    }
                        
                    
                        {
                                listadoVacacionesNuevas &&
                                listadoVacacionesNuevas.map(post => {
                                
                                    const { id, cantidad_dias, fecha_inicio, fecha_fin, status} = post;
                                    let txt_status;
                                    if( status === 1){
                                        txt_status="En proceso"
                                    }
                                    else if( status === 2){
                                        txt_status="Autorizadas"
                                    }
                                    else if( status === 3){
                                        txt_status="Rechazadas"
                                    }
                                    return (
                                        <div className="row mt-2" key={id}>
                                            <div className="col-md-3"><span className="txt_listado">{cantidad_dias}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{fecha_inicio}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{fecha_fin}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{txt_status}</span></div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </>
        )
        } 
    }

    const vacacionesPasadas = () => {
        if (misDatos.vacaciones_pasadas) {
        return (
            <>
                <h2 className="mt-4 titulo_login">Vacaciones vigentes del periodo anterior</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días de vacaciones</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_pasadas.vacaciones_reales }</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Días usados</span>
                            </div>
                            <div className="col-md-6">
                                    <span className="txt_listado">{misDatos.vacaciones_pasadas.vacaciones_usadas}</span>
                            </div>
                        </div>
            
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Vacaciones disponibles</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_pasadas.vacaciones_libres}</span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-6">
                                <span className="txt_listado_bold">Vigencia</span>
                            </div>
                            <div className="col-md-6">
                                <span className="txt_listado">{misDatos.vacaciones_pasadas.fecha_final}</span>
                                <span className="red_alert"></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">

                    {
                        listadoVacacionesPasadas.length>0 &&
                        <>
                        <div className="row mt-2">
                            <div className="col-md-3"><span className="txt_listado_bold">Cantidad días</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Fecha inicio</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Fecha fin</span></div>
                            <div className="col-md-3"><span className="txt_listado_bold">Status</span></div>
                        </div>
                        </>
                    }

                        {
                                listadoVacacionesPasadas &&
                                listadoVacacionesPasadas.map(post => {
                                
                                    const { id, cantidad_dias, fecha_inicio, fecha_fin, status} = post;
                                    let txt_status;
                                    if( status === 1){
                                        txt_status="En proceso"
                                    }
                                    else if( status === 2){
                                        txt_status="Autorizadas"
                                    }
                                    else if( status === 3){
                                        txt_status="Rechazadas"
                                    }
                                    return (
                                        <div className="row mt-2" key={id}>
                                            <div className="col-md-3"><span className="txt_listado">{cantidad_dias}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{fecha_inicio}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{fecha_fin}</span></div>
                                            <div className="col-md-3"><span className="txt_listado">{txt_status}</span></div>
                                        </div>
                                    )
                                })
                        }

                    </div>
                </div>
            </>
        )
        } 
    }
    
    ///---------------------- [  main return  ] ---------------------------

    //------------------------- [ files ] ---------------------------------

    const UpdateFilesFromServer = (id) =>{
        setLoadingUploading(true);
        setActiveFiles("");
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

          trackPromise(
            instance.get('files/show/'+id)
            .then(response => {
                //console.log(response.data.data.data);
                setActiveFiles(response.data.data.data);
                setLoadingUploading(false);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const deleteFile = (id) => {

        setBtnDisabled(true);

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
             instance.delete('files/delete/'+id)
            .then(response => {
                //console.log(response.data.data);
                if(response.status === 200){
                    setBtnDisabled(false);
                    UpdateFilesFromServer(idToChange);
                }
            })
              .catch(e => {
                console.log(e);
              })
        );

    }
    
    const MyDropzone = () =>{

        const onDrop = useCallback(acceptedFiles => {

            // ------------- Logica de AWS ----------------
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);
        
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 100000,
                headers: {'Authorization': 'Bearer '+userData.token, "content-type": 'multipart/form-data'}
            });
            
            let formd = new FormData();
            formd.append("filename", acceptedFiles[0], acceptedFiles[0].name);
            formd.append("id_empleado",idToChange);
            trackPromise(
                
                instance.post('/upload-files', formd)
                .then(response => {
                    if(response.data.success === true){
                        UpdateFilesFromServer(idToChange);
                    }
                    else{
                        alert("Error #44344");
                    }
    
                })
                .catch(e => {
                    alert(e);
                })
    
            );

            // ----------- END Logica de AWS --------------
          
        }, [])

        
        const { getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
        //console.log(activeFiles);
        if(activeFiles.length < global.config.server_endpoint.maxFilesEmployee){

            return (
            <div {...getRootProps()} style={{border: "3px dashed #2c63ff", marginTop: "30px", paddingTop: "20px", textAlign: "center"}}>
                <input {...getInputProps()} />
                {
                isDragActive ?
                    <p className="limite_empleado">Dejelos caer aquí</p> :
                    <p className="limite_empleado mt-3">Para subir un archivo nuevo, por favor arrastre los archivos aquí.</p>
                }
            </div>
            )
        }
        else{
            return(
                <div className="text-center limite_empleado">Se ha alcanzado el límite de archivos por empleado</div>
            )
        }
    }

    //------------------------ [ end files ] -------------------------------

     const columns = [
        // { field: 'id', 
        //     headerName: 'ID', 
        //     headerClassName: 'letal',
        //     width: 90 },
        {
          field: 'nombres',
          headerName: 'Nombre',
          minWidth: 120,
          flex: .15,
          headerClassName: 'letal',
        },
        {
          field: 'apellido_paterno',
          headerName: 'Apellido Paterno',
          minWidth: 120,
          flex: .15,
          headerClassName: 'letal',
        },
        {
          field: 'email',
          headerName: 'Email',
          minWidth: 220,
          flex: .25,
          headerClassName: 'letal',
        },
        {
          field: 'puesto',
          headerName: 'Puesto',
          minWidth: 160,
          flex: .1,
          headerClassName: 'letal',
        },
        {
          field: 'id_area',
          headerName: 'Área',
          minWidth: 160,
          flex: .1,
          headerClassName: 'letal',
          renderCell: (params) => {
            let name_area;
            if(listadoAreas){
                let found = listadoAreas.find(element => element.id === params.row.id_area);
                if(found)
                    name_area = found.nombre_area;
            }
            return name_area;
          }
        },
        {
          field: 'status',
          flex: 0.15,
          headerName: 'Estatus',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
                let txt_status = "";
                //console.log(params.row.status);
                switch (params.row.status) {
                    case 1:
                        txt_status = "Activo"
                        break;
                    case 2:
                            txt_status = "Inactivo"
                        break;
                    default:
                        txt_status = "-"
                        break;
                }

                return txt_status;
          },
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 250,
            flex: 1,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <>
                    <Tooltip title="Borrar">
                        <Button
                            variant="text"
                            color="error"
                            size="large"
                            className="pr-1 mr-1 btn_emp"
                            startIcon={<DeleteIcon />}
                            onClick={() => showConfirm(params.id)}
                        />
                    </Tooltip>
                    <Tooltip title="Editar">
                        <Button
                            variant="text"
                            color="primary"
                            size="large"
                            className="mr-1"
                            startIcon={<EditIcon />}
                            onClick={() => openModal(params.id)}
                        />
                    </Tooltip>
                    
                    <Tooltip title="Ver vacaciones">
                        <Button
                            variant="text"
                            color="success"
                            size="large"
                            className=""
                            startIcon={<FlightTakeoffIcon />} 
                            onClick={() => showVacations(params.id)}
                        />
                    </Tooltip>
                </>
            ),
        }
        
    
    ];

    const rows = empleados;

    const TabsComponent = () =>{
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Información" {...a11yProps(0)} />
                        <Tab label="Archivos" {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    
                    <Container className="mt-2">
                        <div className="row mt-3">
                            <div className="col-md-3">
                            <span className="txt_listado">Nombres</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_nombre_empresa" name="nombre_empresa" defaultValue={nombres || ''} onKeyUp={OnChangeNombres} required="required" /></span>
                                <span className="red_alert">{ errorNombres }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                            <span className="txt_listado">Apellido Paterno</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_apellido_paterno" name="apellido_paterno" defaultValue={apellidoPaterno || ''} onKeyUp={OnChangeApellidoPaterno} required="required" /></span>
                                <span className="red_alert">{ errorApellidoPaterno }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                            <span className="txt_listado">Apellido Materno</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_apellido_materno" name="apellido_materno" defaultValue={apellidoMaterno || ''} onKeyUp={OnChangeApellidoMaterno} required="required" /></span>
                                <span className="red_alert">{ errorApellidoMaterno }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                            <span className="txt_listado">Email</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_email" autoComplete="one-time-code1"  name="email" defaultValue={email || ''} onKeyUp={OnChangeEmail} required="required" /></span>
                                <span className="red_alert">{ errorEmail }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                            <span className="txt_listado">Celular</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_celular" name="celular" defaultValue={celular || ''} onKeyUp={OnChangeCelular} required="required" /></span>
                                <span className="red_alert">{ errorCeluar }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">Puesto</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_puesto" name="puesto" defaultValue={puesto || ''} onKeyUp={OnChangePuesto} required="required" /></span>
                                <span className="red_alert">{ errorPuesto }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">Área</span>
                            </div>
                            <div className="col-md-9">
                                <span><select className="form-control" id="inp_area" name="area" value={area} onChange={OnChangeArea} required="required">
                                        <option value="">Seleccione...</option>
                                        {
                                            listadoAreas &&
                                            listadoAreas.map(post => {
                                                const { id, nombre_area } = post;
                                                return (
                                                    <option value={id} key={id}>{nombre_area}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </span>
                                <span className="red_alert">{ errorArea }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">Tipo sangre</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_tipo_sangre" name="tipo_sangre" defaultValue={tipoSangre || ''} onKeyUp={OnChangeTipoSangre} required="required" /></span>
                                <span className="red_alert">{ errorTipoSangre }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">Fecha nacimiento</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="date" className="form-control" id="inp_fecha_nacimiento" name="fecha_nacimiento" defaultValue={fechaNacimiento || ''} onChange={OnChangeFechaNacimiento} required="required" /></span>
                                <span className="red_alert">{ errorFechaNacimiento }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">Teléfono emergencia</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_telefono_emergencia" name="telefono_emergencia" defaultValue={telefonoEmergencia || ''} onKeyUp={OnChangeTelefonoEmergencia} required="required" /></span>
                                <span className="red_alert">{ errorTelefonoEmergencia }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">RFC</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="text" className="form-control" id="inp_RFC" name="RFC" defaultValue={RFC || ''} onKeyUp={OnChangeRFC} required="required" autoComplete="off" /></span>
                                <span className="red_alert">{ errorRFC }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">Fecha ingreso</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="date" className="form-control" id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={fechaIngreso || ''} onChange={OnChangeFechaIngreso} required="required" /></span>
                                <span className="red_alert">{ errorFechaIngreso }</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <span className="txt_listado">Status</span>
                            </div>
                            <div className="col-md-9">
                                <span><select className="form-control" id="inp_area" name="status" value={status} onChange={OnChangeStatus} required="required">
                                        <option value="">Seleccione...</option>
                                        <option value="1">Activo</option>
                                        <option value="2">Inactivo</option>
                                    </select>
                                </span>
                                <span className="red_alert">{ errorArea }</span>
                            </div>
                        </div>

                        <div className="row mt-3 mb-5">
                            <div className="col-md-3">
                                <span>&nbsp;</span>
                            </div>
                            <div className="col-md-9">
                                <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={() => handleSubmit(idToChange)} /></span>
                            </div>
                        </div>
                </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Container className="mb-5">
                        <div className="row mt-2">
                            <div className="col-md-5">
                                <span className="txt_listado"><strong>Nombre archivo</strong></span>
                            </div>
                            <div className="col-md-3">
                                <span className="txt_listado"><strong>Fecha</strong></span>
                            </div>
                            <div className="col-md-3">
                                <span className="txt_listado"><strong>Subido por</strong></span>
                            </div>
                            <div className="col-md-1">
                                <span className="txt_listado"><strong>&nbsp;</strong></span>
                            </div>
                        </div>
                        {
                            loadingUploading &&
                                <>
                                <div className="text-center">
                                    <Loader type="ThreeDots"  color="#2c63ff" height="100" width="100" />
                                    <h1 className="loading-final">Cargando...</h1>
                                </div>
                                </>
                        }
                        {
                            activeFiles &&
                            activeFiles.length<1 &&
                                <h4 className="text-center mt-4 no_hay">No hay documentos disponibles</h4>
                        }
                        {
                            activeFiles &&
                            activeFiles.map(post => {
                                
                                    const { id, filename, created_at, name} = post;
                                    //let cut = created_at.split("T");
                                    let donde = "https://d2sx5pnhyjgoui.cloudfront.net/files/"+filename;
                                
                                    return (
                                        <div className="row mt-2" key={id}>
                                            <div className="col-md-5">
                                                <span className="txt_listado"><a href={donde} target="_blank" rel="noreferrer">{filename}</a></span>
                                            </div>
                                            <div className="col-md-3">
                                                <span className="txt_listado">{created_at}</span>
                                            </div>
                                            <div className="col-md-3">
                                                <span className="txt_listado">{name}</span>
                                            </div>
                                            <div className="col-md-1">
                                                <Button variant="contained" color="error" onClick={() => deleteFile(id)} disabled={btnDisabled}><FontAwesomeIcon icon={faTrash} className="icono_trash"  /></Button>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </Container>
                    <MyDropzone />
                </TabPanel>
                
            </Box>
          )
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized ){
        return(<>
                <Header />

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        La información se guardo exitosamente.
                    </Alert>
                </Snackbar>

                <Container className="mr-inf">

                <div className="row">
                        <div className="col-10">
                            <div className="titulo_login">Empleados</div>
                        </div>
                        <div className="col-2">
                        <IconButton aria-label="delete" className="mt-2 float-right" color="error" size="large" onClick={() => openModalNew()}>
                            <AddCircle fontSize="inherit" className="new-icon"  />
                            <h1 className="add-new ml-2 mt-2">Agregar</h1>
                        </IconButton>
                        {/* <h1 className="addEmpleado handy" onClick={() => openModalNew()}>
                                <FontAwesomeIcon icon={faPlus} className="icono_blanco" />
                            </h1> */}
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12">
                            <div className="areas_datagrid">
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    paginationMode="server"
                                    rowCount={totalRows}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>

            </Container>

            <Modal size="lg" show={visible.visible} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">¿Estas seguro que deseas eliminarlo?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrue(visible.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalse()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible2} onHide={handleDeleteFalse2} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <h1 className="titulo_login">Cargar vacaciones</h1>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="row mt-3">
					    <div className="col-md-3">
						    <span className="txt_listado">Vacaciones disponibles</span>
					    </div>
					    <div className="col-md-9">
						    <span><select className="form-control" onChange={onChangeVacationsType}>
                                    <option value=''>Seleccione...</option>
                                    {
                                        misDatos.anios_laborando>1 &&
                                        misDatos.vacaciones_pasadas &&
                                            <option value='pasado'>Vacaciones (periodo pasado)</option>
                                    }
                                    <option value='actual'>Vacaciones (periodo actual)</option>
                            </select></span>
					    </div>
				    </div>
                    <div className="row mt-3">
					    <div className="col-md-3">
						    <span className="txt_listado">Días disponibles</span>
					    </div>
					    <div className="col-md-9">
						    <span>{diasDisponiblesSelect}</span>
					    </div>
				    </div>
                    
                    
                    <div className="row mt-3">
					    <div className="col-md-3">
						    <span className="txt_listado">Fecha</span>
					    </div>
					    <div className="col-md-9">
						    <span><DatePicker
                                    filterDate={isWeekday}
                                    //selected={checkInDate}
                                    //minDate={new Date()}
                                    //maxDate={}
                                    selectsRange
                                    //onChange={handleCheckInDate}
                                    className="form-control w-100"
                                    placeholderText="Fecha inicio"
                                    dateFormat="yyyy-MM-dd"
                                    locale="es"
                                    selected={startDate}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    disabled={showDatesInput}
                                    // por hacer
                                    //maxDate={moment(vigenciaVacaciones)._d}
                                /></span>
					    </div>
				    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col-md-3">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-md-9">
                            <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={() => handleSubmitVac(idToChange)} /></span>
                        </div>
                    </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible5} onHide={handleDeleteFalse5} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <h1 className="titulo_login">Editar empleado</h1>
                </Modal.Header>
                <Modal.Body>
                    { TabsComponent() }
                </Modal.Body>
            </Modal>

            {/* Modal nuevo */}
            <Modal size="lg" show={visible3} onHide={handleDeleteFalse3} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Agregar empleado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>
                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Nombres</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_nombre_empresa" name="nombre_empresa" defaultValue={nombres || ''} onKeyUp={OnChangeNombres} required="required" /></span>
						    <span className="red_alert">{ errorNombres }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Apellido Paterno</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_apellido_paterno" name="apellido_paterno" defaultValue={apellidoPaterno || ''} onKeyUp={OnChangeApellidoPaterno} required="required" /></span>
						    <span className="red_alert">{ errorApellidoPaterno }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Apellido Materno</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_apellido_materno" name="apellido_materno" defaultValue={apellidoMaterno || ''} onKeyUp={OnChangeApellidoMaterno} required="required" /></span>
						    <span className="red_alert">{ errorApellidoMaterno }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Email</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control"  defaultValue={email || ''} onKeyUp={OnChangeEmail} required="required" autoComplete="new-pasword" /></span>
						    <span className="red_alert">{ errorEmail }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Celular</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_celular" name="celular" defaultValue={celular || ''} onKeyUp={OnChangeCelular} required="required" /></span>
						    <span className="red_alert">{ errorCeluar }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Puesto</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_puesto" name="puesto" defaultValue={puesto || ''} onKeyUp={OnChangePuesto} required="required" /></span>
						    <span className="red_alert">{ errorPuesto }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Área</span>
					    </div>
					    <div className="col-md-9">
						    <span><select className="form-control" id="inp_area" name="area" onChange={OnChangeArea} required="required">
                                    <option value="">Seleccione...</option>
                                    {
                                        listadoAreas &&
                                        listadoAreas.map(post => {
                                            const { id, nombre_area } = post;
                                            return (
                                                <option value={id} key={id}>{nombre_area}</option>
                                            )
                                        })
                                    }
                                </select>
                            </span>
						    <span className="red_alert">{ errorArea }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Tipo sangre</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_tipo_sangre" name="tipo_sangre" defaultValue={tipoSangre || ''} onKeyUp={OnChangeTipoSangre} required="required" /></span>
						    <span className="red_alert">{ errorTipoSangre }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Fecha nacimiento</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="date" className="form-control" id="inp_fecha_nacimiento" name="fecha_nacimiento" defaultValue={fechaNacimiento || ''} onChange={OnChangeFechaNacimiento} required="required" /></span>
						    <span className="red_alert">{ errorFechaNacimiento }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Teléfono emergencia</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_telefono_emergencia" name="telefono_emergencia" defaultValue={telefonoEmergencia || ''} onKeyUp={OnChangeTelefonoEmergencia} required="required" /></span>
						    <span className="red_alert">{ errorTelefonoEmergencia }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">RFC</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="text" className="form-control" id="inp_RFC" name="RFC" defaultValue={RFC || ''} onKeyUp={OnChangeRFC} required="required" autoComplete="new-pasword" /></span>
						    <span className="red_alert">{ errorRFC }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Fecha ingreso</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="date" className="form-control" id="inp_fecha_ingreso" name="fecha_ingreso" defaultValue={fechaIngreso || ''} onChange={OnChangeFechaIngreso} required="required" /></span>
						    <span className="red_alert">{ errorFechaIngreso }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Password</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="password" className="form-control" id="inp_password" name="password" onKeyUp={OnChangePassword} required="required" autoComplete="new-pasword" /></span>
						    <span className="red_alert">{ errorPassword }</span>
					    </div>
				    </div>

                    <div className="row mt-4">
					    <div className="col-md-3">
						    <span className="txt_listado">Confirmación password</span>
					    </div>
					    <div className="col-md-9">
						    <span><input type="password" className="form-control" id="inp_password2" name="password2" onKeyUp={OnChangeRepeatPassword} required="required"  /></span>
						    <span className="red_alert">{ errorRepeatPassword }</span>
					    </div>
				    </div>

                    <div className="row mt-3 mb-5">
                        <div className="col-md-3">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-md-9">
                            <span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmitNew}  /></span>
                        </div>
                    </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="xl" show={visible4.visible} onHide={handleDeleteFalse4} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                <h1 className="titulo_login mt-4">Vacaciones de {misDatos.nombre_completo}</h1>
                </Modal.Header>
                <Modal.Body>
                <Container className="mb-5">
				
                    <div className="row mt-2">
                        <div className="col-12">
                            <button className="float-right btn btn-info" onClick={() => openModalCarga(visible4.id)}>Cargar vacaciones</button>  
                        </div> 
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <span className="txt_listado_bold">Fecha ingreso</span>
                                </div>
                                <div className="col-md-6">
                                    <span className="txt_listado">{ misDatos.fecha_ingreso }</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <span className="txt_listado_bold">Días en la empresa</span>
                                </div>
                                <div className="col-md-6">
                                    <span className="txt_listado">{misDatos.dias_laborando || null}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <span className="txt_listado_bold">Años en la empresa</span>
                                </div>
                                <div className="col-md-6">
                                    <span className="txt_listado">{misDatos.anios_laborando || null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            &nbsp;
                        </div>
                    </div>

                    {vacacionesNuevas()}

                    {vacacionesPasadas()}

                    {totales()}
 
                </Container>
                </Modal.Body>
            </Modal>

        </>)
    }
    else{
        return <Redirect to="/logout"  />;
    }
};

export default Empleados;