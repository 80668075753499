import React, {useState, useEffect, useRef } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Container } from 'react-bootstrap';
import './css/Login.css';
import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import moment from "moment";
//import DatePicker, {registerLocale} from "react-datepicker";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import "react-datepicker/dist/react-datepicker.css";


const CargaHoras = () =>{

    
    const [showBtn, setShowBtn]                               = useState(false);
    const [isAuthorized, setIsAuthorized]                     = useState(true);
    const [open, setOpen]                                     = useState(false);
    const [errorNombreEmpresa, setErrorNombreEmpresa]         = useState(null);
    const [errorNombreCompleto, setErrorNombreCompleto]       = useState(null);
    const [errorRFC, setErrorRFC]                             = useState(null);
    const [errorEmail, setErrorEmail]                         = useState(null);
    const [errorPassword, setErrorPassword]                 = useState(null);
    const [errorRepeatPassword, setErrorRepeatPassword]     = useState(null);
    
    const [nombreEmpresa, setNombreEmpresa]                   = useState("");
    const [nombreCompleto, setNombreCompleto]                 = useState("");
    const [RFC, setRFC]                                       = useState("");
    const [email, setEmail]                                   = useState("");
    const [image, setImage]                                   = useState({ preview: "", raw: "" });
    const [password, setPassword]                             = useState("");
    const [repeatPassword, setRepeatPassword]                 = useState("");

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    //------------------------ [  operations ] --------------------------

    const ref = useRef();
    const reset = () => {
        ref.current.value = "";
    };

    const OnChangeNombreEmpresa = (e) =>{
        setNombreEmpresa(e.target.value);        
    }

    const OnChangeNombreCompleto = (e) =>{
        setNombreCompleto(e.target.value);        
    }

    const OnChangeRFC = (e) =>{
        setRFC(e.target.value);
    }

    const OnChangeEmail = (e) =>{
        setEmail(e.target.value);
    }

    const OnChangePassword = (e) =>{
        setPassword(e.target.value);        
    }

   
    const OnChangeRepeatPassword = (e) =>{
        setRepeatPassword(e.target.value);        
    }

    const onChangeLogo = e => {
        
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
        files = e.dataTransfer.files;
        } else if (e.target) {
        files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            //setRecortaDisabled(false);
        };
        reader.readAsDataURL(files[0]);

    };

    

    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia<2){ 
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
        const retrieveData = () => {

            //-------------------------- vencimiento de sesion ----------------
            trackPromise(
                instance.get('/validate-token')
                .then(response => {
                    setIsAuthorized(true);
                })
                .catch(e => {
                    setIsAuthorized(false);
                })
            );
            //------------------------ end vencimiento de sesion ---------------
        }

        retrieveData();

        }
        

    },[]);

    const handleSubmit = () => {
        
            let errorsForm = 0;
            setShowBtn(true);
       
            const regex_letras 	    = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
            let regex_rfc           = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
            
            //-------------------[		validate inp_nombre_empresa		]----------------
            if(nombreEmpresa.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreEmpresa("Error: solo letras");
                errorsForm = true;
                setShowBtn(false);
            }
            else{
                setErrorNombreEmpresa("");
            }

            if(nombreCompleto.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreCompleto("Error: solo letras");
                errorsForm = true;
                setShowBtn(false);
            }
            else{
                setErrorNombreEmpresa("");
            }


            //-------------------[		validate email pt 1		]----------------
            if(!email){
                setErrorEmail("Error: email vacío");
                setShowBtn(false);
                errorsForm = true;
            }
            else{
                setErrorEmail("");
            }
        
            //-------------------[		validate email pt 2		]----------------
            if(typeof email !== "undefined"){
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');
        
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                setErrorEmail("Error: email no válido");
                setShowBtn(false);
                errorsForm = true;
                }
                else{
                    setErrorEmail("");
                }
            }  

            //-------------------[		end email pt 2		]----------------

            if(password !== repeatPassword){
                setErrorRepeatPassword("Passwords no coinciden");
                setErrorPassword("Passwords no coinciden");
                setShowBtn(false);
                errorsForm = true;
            }

            if(RFC.match(regex_rfc) == null){
                //console.log("Error: solo letras");
                setErrorRFC("Error: RFC no válido");
                setShowBtn(false)
                errorsForm = true;
            }
            else{
                setErrorRFC("");
            }


            const clearForm = () => {
                setNombreEmpresa("");
                setRFC("");
                setNombreCompleto("");
                setEmail("");
                setPassword("");
                setRepeatPassword("");
            }

            if(!errorsForm){
                //logica para guardar todo
                try {

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);
                    
                    const instance = axios.create({
                    baseURL: global.config.server_endpoint.url,
                    timeout: 10000,
                    headers: {'Authorization': 'Bearer '+userData.token}
                    });
        
                    instance.post('/save-empresa', { 
                        nombre_empresa: nombreEmpresa, 
                        RFC: RFC, 
                        telefono: "5536666274", 
                        fecha_ingreso: moment().format("YYYY-MM-DD"),
                        logo: image
                        })
                    .then(response => {
                        
                        // obtener el id de la empresa
                        //console.log(response);
                        console.log(response.data.data.id);
                        
                        instance.post('/usuarios-adm', { 
                            id_cuenta: response.data.data.id,
                            name: nombreCompleto,
                            email: email,
                            jerarquia: 1,
                            password: password,
                            c_password: password
                            })
                        .then(response => {
                            //console.log(response);
                            if(response.data.success){
                                clearForm();
                                setShowBtn(false);
                                setOpen(true);
                                reset()
                            } 
                        })
                        .catch(error => {console.log(error)});

                    })
                    .catch(error => {console.log(error)});
        
        
                } catch (e) {
                      console.log("Error #34324");
                      
                }
                //end logica para guardar todo
            }
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    //--- return principal
    if(sessionStorage.getItem('userData') != null && userData.id===1 && userData.jerarquia===1 && isAuthorized ){
        return(<>
                    <Header />

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            La información se guardo exitosamente.
                        </Alert>
                    </Snackbar>
                
                    <Container className="mb-5">
				<h1 className="titulo_login mt-4">Agregar nueva empresa</h1>

                <div className="row mt-4">
                    <div className="col-md-2">
                        <span className="txt_listado">Nombre empresa</span>
                    </div>
                    <div className="col-md-10">
                        <span><input type="text" className="form-control" id="inp_nombre_empresa" name="nombre_empresa" value={nombreEmpresa || ''} onChange={OnChangeNombreEmpresa} required="required" /></span>
                        <span className="red_alert">{ errorNombreEmpresa }</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-2">
                        <span className="txt_listado">Logo</span>
                    </div>
                    <div className="col-md-10">
                        <span>
                            {/* <input type="file" className="form-control" onChange={onChange}  style={{display: 'none'}} className="mb-5"/> */}
                            <input type="file" ref={ref} className="form-control" id="inp_logo" name="logo"  onChange={onChangeLogo} required="required" /></span>
                        <span className="red_alert"></span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-2">
                        <span className="txt_listado">RFC</span>
                    </div>
                    <div className="col-md-10">
                        <span><input type="text" className="form-control" id="inp_rfc" name="rfc" value={RFC || ''} onChange={OnChangeRFC} required="required" /></span>
                        <span className="red_alert">{ errorRFC }</span>
                    </div>
                </div>

                {/* NOMBRE PRIMERA CUENTA */}
                <h1 className="titulo_login mt-5">Datos de cuenta maestra</h1>

                <div className="row mt-3">
					    <div className="col-md-2">
						    <span className="txt_listado">Nombre completo</span>
					    </div>
					    <div className="col-md-10">
						    <span><input type="text" className="form-control" id="inp_nombre_proyecto" value={nombreCompleto || ''} name="nombre_completo" onChange={OnChangeNombreCompleto} required="required" /></span>
						    <span className="red_alert">{ errorNombreCompleto }</span>
					    </div>
				    </div>

                    <div className="row mt-3">
					    <div className="col-md-2">
						    <span className="txt_listado">Email</span>
					    </div>
					    <div className="col-md-10">
						    <span><input type="text" className="form-control"  value={email || ''} onChange={OnChangeEmail} required="required" /></span>
						    <span className="red_alert">{ errorEmail }</span>
					    </div>
				    </div>

                    <div className="row mt-3">
					    <div className="col-md-2">
						    <span className="txt_listado">Password</span>
					    </div>
					    <div className="col-md-10">
						    <span><input type="password" className="form-control" value={password || ''} id="inp_password" name="password" onChange={OnChangePassword} required="required" autocomplete="new-password" /></span>
						    <span className="red_alert">{ errorPassword }</span>
					    </div>
				    </div>

                    <div className="row mt-3">
					    <div className="col-md-2">
						    <span className="txt_listado">Confirmación password</span>
					    </div>
					    <div className="col-md-10">
						    <span><input type="password" className="form-control" id="inp_password2" value={repeatPassword || ''} name="password2" onChange={OnChangeRepeatPassword} required="required" /></span>
						    <span className="red_alert">{ errorRepeatPassword }</span>
					    </div>
				    </div>


				

				{ /* CONTACTO COMERCIAL */ }

				
				<div className="row mt-3 mb-5">
					<div className="col-md-2">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-10">
						<span><input type="submit" value="Guardar" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmit} /></span>
					</div>
				</div>

				

				{ /* espacio de seguridad */ }
				<div className="row mb-2">
					<div className="col-md-2">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-10">
						<span>&nbsp;</span>
					</div>
				</div>

			</Container>
            </>)
    }
    else{
        return <Redirect to="/home"  />;
    }

}


export default CargaHoras;