import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './css/Welcome.css';
import Header from './header';
import { Container} from 'react-bootstrap';
import Button  from '@mui/material/Button';
import  { Redirect } from 'react-router-dom'
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';

//--------
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


//---------
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


// const defaultSrc =
//   "https://tonino-files.s3.us-east-2.amazonaws.com/images/avatar-20210918-113550.jpg?xyz";
//   //"https://d3ejay4e60d8yz.cloudfront.net/img/logoBlack.png"
  const defaultSrc = "./gris.png"
  //const defaultSrc = "http://d3ejay4e60d8yz.cloudfront.net/img/logoBlack.png";

const MiCuenta= () =>{
    //new development
    const [image, setImage]                                 = useState(defaultSrc);
    //const [reload, setReload]                             = useState(false);
    const [value, setValue]                                 = React.useState(0);
    const [cropData, setCropData]                           = useState("gris.jpg");
    const [cropper, setCropper]                             = useState();
    const [showSaveButton, setShowSaveButton]               = useState(false);
    const [isAuthorized, setIsAuthorized]                   = useState(true);
    const [recortaDisabled, setRecortaDisabled]             = useState(true);
    const [btnGuardar, setBtnGuardar]                       = useState(false);
    const [open, setOpen]                                   = useState(false);
    const [open2, setOpen2]                                 = useState(false);
    const [open3, setOpen3]                                 = useState(false);
    const [showLoading, setShowLoading]                     = useState({display: "none"});
    const inputFile                                         = useRef(null);
    const [showBtn, setShowBtn]                             = useState(false);
    const form                                              = useRef(null);
    const [passwordActual, setPasswordActual]               = useState("");
    const [passwordNuevo, setPasswordNuevo]                 = useState("");
    //--------------
    const [errorPasswordActual, setErrorPasswordActual]     = useState(null);
    const [errorPasswordNuevo, setErrorPasswordNuevo]     = useState(null);
    
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen2(false);
    };

    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen3(false);
    };

    const onButtonClick = () => {
        // `current` points to the mounted file input element
       inputFile.current.click();
    };

    const OnChangePasswordActual = (e) =>{
        setPasswordActual(e.target.value);        
    }

    const OnChangePasswordNuevo = (e) =>{
        setPasswordNuevo(e.target.value);        
    }

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
        files = e.dataTransfer.files;
        } else if (e.target) {
        files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            setRecortaDisabled(false);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            setShowSaveButton(true);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const saveImage = () => {
        setShowLoading({display: "block"});
        setBtnGuardar(true);
        
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);
        
        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        const updateInfo = (value) => { 
            let prevData = JSON.parse(sessionStorage.getItem('userData'));
            Object.keys(value).forEach(function(val, key){
                 prevData[val] = value[val];
            })
            sessionStorage.setItem('userData', JSON.stringify(prevData));
        }

        trackPromise(

            instance.post('/cambio-avatar', {
                avatar: cropData
            })
            .then(response => {
                
                if(response.data.success === true){
                    setShowLoading({display: "none"});
                    setOpen(true);
                    updateInfo({avatar: response.data.data.avatar})
                    setCropData("gris.jpg");
                    setImage(defaultSrc);
                    setBtnGuardar(false);
                    setIsAuthorized(true);
                }
                else{
                    setIsAuthorized(false);
                }

            })
            .catch(e => {
                console.log("Expired token - Error #45435");
                setIsAuthorized(false);
            })

        );


    };

    const RequestForgot = () => {
            
            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token, 'Accept': 'multipart/form-data'}
            });

            trackPromise(
                instance.post('/forgot', { 
                   email: userData.email
                })
                .then(response => {
                    console.log(response);
                    if(response.data.data.status === 200 && response.data.data.message === 'Generado exitosamente'){
                        setOpen3(true);
                    }
                    // if(response.data.success === true){
                    //     //alert("Los datos se guardaron exitosamente")
                    //     setOpen(true);
                    //     // setNombreEmpresa(""); 
                    //     // setRFC("");
                    //     // setLogo("");
                    //     // setTelefono("");
                    // }
                    // else{
                    //     setShowBtn(false)
                    // }
                
                })
                .catch(e => {
                    console.log(e);
                })
            );

            // trackPromise(

            //     instance.post('/usuarios-self', {
            //         status: 1,
            //         password: "yaya1",
		    //         c_password: "yaya1"
            //     })
            //     .then(response => {
                    
            //         if(response.data.success === true){
            //             setOpen(true);
            //             setIsAuthorized(true);
            //         }
            //         else{
            //             setIsAuthorized(false);
            //         }
    
            //     })
            //     .catch(e => {
            //         console.log("Expired token - Error #45435");
            //         setIsAuthorized(false);
            //     })
    
            // );

    }

    const handleSubmit = (e) =>{

        e.preventDefault();
        let errorsForm = false;
        setShowBtn(true)
        

        //let regex_letras    = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        let regex_pass1     = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
        
        //--------------------------[		validate pwds		]-------------------------
        
        if(passwordActual.match(regex_pass1) == null){
            //console.log("Error: solo letras");
            setErrorPasswordActual("Error, el password debe de tener una longitud minima de 8 caracteres, por lo menos una letra mayúscula, una minúscula y un caracter como: ?=.*[!@#$%^&*] ");
            setShowBtn(false)
            errorsForm = true;
        }
        else{
            setErrorPasswordActual("");
        }

        if(passwordNuevo.match(regex_pass1) == null){
            //console.log("Error: solo letras");
            setErrorPasswordNuevo("Error, el password debe de tener una longitud minima de 8 caracteres, por lo menos una letra mayúscula, una minúscula y un caracter como: ?=.*[!@#$%^&*] ");
            setShowBtn(false)
            errorsForm = true;
        }
        else{
            setErrorPasswordNuevo("");
        }


        if(!errorsForm){
           
            setShowBtn(true);

            let ud       = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);

            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token, 'Accept': 'multipart/form-data'}
            });

            trackPromise(

                instance.put('/usuarios-self', {
                    status: 1,
                    password_actual: passwordActual,
                    password: passwordNuevo,
		            c_password: passwordNuevo
                })
                .then(response => {
                    console.log(response);
                    if(response.data.success === true && response.data.message !== "El password no coincide"){
                        setOpen(true)
                        setPasswordActual("")
                        setPasswordNuevo("")
                        setIsAuthorized(true)
                        setShowBtn(false)
                    }
                    else{
                        setOpen2(true)
                        setPasswordActual("")
                        setPasswordNuevo("")
                        setShowBtn(false)
                    }
    
                })
                .catch(e => {
                    console.log("Expired token - Error #45435");
                    setIsAuthorized(false);
                })
    
            );
        }
        else{
            alert("Por favor revise los datos");
        }

    };

    function myFunction() {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
    }

    function changeNuevo() {
        var x = document.getElementById("myInput2");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
    }

    const FormChangePassword = () => {
        return(
            <>
                <div className="row">
                    <div className="col-12">
                    <Button variant="border" className="float-right mb-4" size="small" color="error" onClick={RequestForgot}>Olvide mi contraseña actual</Button>
                    </div>
                </div>
                
                <form ref={form}>
                        <div className="row mt-4">
                            <div className="col-md-2 col-12">
                                <span className="txt_listado">Password actual</span>
                            </div>
                            <div className="col-md-9 col-10">
                                <span><input type="password" id="myInput" className="form-control" value={passwordActual || ''} onChange={OnChangePasswordActual} required="required" /></span>
                                <span className="red_alert">{ errorPasswordActual }</span>
                                
                            </div>
                            <div className="col-md-1 col-2">
                            <Tooltip title="Mostrar password">
                                <VisibilityIcon
                                    color="primary"
                                    onClick={() => myFunction()}
                                />
                            </Tooltip>
                                {/* <span className="txt_listado"><input type="checkbox" onClick={myFunction} /> Mostrar password</span> */}
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-2 col-12">
                                <span className="txt_listado">Password nuevo</span>
                            </div>
                            <div className="col-md-9 col-10">
                                <span><input type="password" id="myInput2" className="form-control"  value={passwordNuevo || ''} onChange={OnChangePasswordNuevo} required="required" /></span>
                                <span className="red_alert">{ errorPasswordNuevo }</span>
                                
                            </div>
                            <div className="col-md-1 col-2">
                                <Tooltip title="Mostrar password">
                                    <VisibilityIcon
                                        color="primary"
                                        onClick={() => changeNuevo()}
                                    />
                                </Tooltip>
                            </div>
                        </div>


                        <div className="row mt-3 mb-5">
                            <div className="col-md-2">
                                <span>&nbsp;</span>
                            </div>
                            <div className="col-md-10">
                                <span><Button type="submit" disabled={showBtn} variant="contained" className="w-100" color="success"  id="inp_enviar" name="inp_enviar" onClick={handleSubmit}>Guardar</Button></span>
                            </div>
                        </div>
                    </form>
            </>
        )
    }

    const TabsComponent = () =>{
        
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, width: "100%", borderColor: 'divider' }}>
                    <Tabs value={value} variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="basic tabs example">
                        <Tab className="tabu" label="Cambiar password" {...a11yProps(0)} />
                        <Tab className="tabu" label="Cambiar imagen de perfil" {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Container>
                        { FormChangePassword() }
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Container className="mt-5 mb-5">
                        <div className="row">
                            <div className="col-md-4 offset-md-2">
                                    <h1 className="mb-3 titulo-cuenta">Selecciona tu imagen</h1>
                                    <input type="file" onChange={onChange} ref={inputFile} style={{display: 'none'}} className="mb-5"/>
                                    <button className="form-control2 mb-3" onClick={onButtonClick}>Cambia tu foto</button>
                            </div>
                        </div>
                        <div className="row pull-left justify-content-center">
                            <div className="col-md-4">
                                <Cropper
                                style={{ width: "100%", maxHeight: "400px" }}
                                zoomTo={0}
                                initialAspectRatio={1}
                                aspectRatio={1}
                                preview=".img-preview"
                                src={image}
                                //src="https://tonino-files.s3.us-east-2.amazonaws.com/images/avatar-20210918-113550.jpg"
                                center={true}
                                viewMode={1}
                                scalable={false}
                                minCropBoxHeight={200}
                                minCropBoxWidth={200}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                                guides={true}
                                />

                                    <h1 className="mt-3 titulo-cuenta text-center"><Button className="btn mb-5 form-control2 cincuenta" disabled={recortaDisabled} onClick={getCropData}>Recortar</Button></h1>

                            </div>


                            <div className="col-md-4">
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className="row height-prev">
                                            <h1 className="mb-5" style={{ fontSize: "25px" }} >2) Preview</h1>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="img-preview" style={{ width: "100%", float: "left", height: "300px" }} />
                                            </div>
                                            
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 text-center">
                                        <div className="row">
                                            <div className="col-md-12">
                                            
                                            </div>
                                        </div>
                                        <div className="row float-center">
                                            <div className="col-md-12 text-center">
                                                <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                                            
                                            {
                                                showSaveButton &&
                                                <Button className="btn btn-info mt-3 form-control2 cincuenta"  disabled={btnGuardar} onClick={saveImage}>Guardar</Button>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </TabPanel>
            </Box>
          )
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const AlertW = React.forwardRef(function AlertW(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const AlertS = React.forwardRef(function AlertS(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    if(isAuthorized === true){
        return (
        <>
        <div className="loading-div text-center" style={showLoading}>
            <h3 style={{color: "#FFF", marginTop: "100px"}}>Loading...</h3>
        </div>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                La información se guardo exitosamente.
            </Alert>
        </Snackbar>

        <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
            <AlertW onClose={handleClose2} severity="error" sx={{ width: '100%' }}>
                El password no coincide
            </AlertW>
        </Snackbar>

        <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
            <AlertS onClose={handleClose3} severity="primary" sx={{ width: '100%' }}>
                Por favor revise su email para restablecer su contraseña
            </AlertS>
        </Snackbar>

        <Header />
        <Container className="mr-inf">
            <div className="row">
                <div className="col-md-12">
                    <div className="titulo_login">Mi cuenta</div>
                </div>
            </div>
            <div className="row mt-4">
                {TabsComponent()}
            </div>
        </Container>
        </>
    );
    }
    else{
        return <Redirect to="/welcome"  />;
        // if(isAuthorized === true)
        //     window.location.reload();
        // else
        //     return <Redirect to="/logout"  />;
        //alert(isAuthorized);
    }

}

export default MiCuenta;