import React, {useState, useEffect, useRef } from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import './css/Login.css';
//import Header from './header';
import axios from 'axios';
import  { Redirect } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
//import Loader from 'react-loader-spinner';
import { DataGrid } from '@mui/x-data-grid';
import Button  from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

const Unidades = () =>{

    // ---------------------------------------- [ hooks ] ----------------------------------------

    const [unidadesListado, setUnidadesListado]     = useState([]);
    const [visible , SetVisible]                    = useState({ show: false, id: null });
    const [visible2 , SetVisible2]                  = useState(false);
    const [visible3 , SetVisible3]                  = useState(false);
    const [nombreUnidad, setNombreUnidad]           = useState("");
    const [status, setStatus]                       = useState("");
    const [errorNombreUnidad, setErrorNombreUnidad] = useState(null);
    const [errorStatus, setErrorStatus]             = useState(null);
    const [showBtn, setShowBtn]                     = useState(false);
    const [dataUnidad, setDataUnidad]                   = useState([]);
    const [isAuthorized, setIsAuthorized]           = useState(true);

    //-------------------- nuevo data grid -------------------
    const [loading, setLoading]                     = useState(false);
    const [page, setPage]                           = useState(0);
    const [selectionModel, setSelectionModel]       = useState([]);
    const prevSelectionModel                        = useRef(selectionModel);
    const [totalRows, setTotalRows]                 = useState(0);
    //---- snackbar
    const [open, setOpen]                           = useState(false);
    
    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };
    
    const OnChangeNombreUnidad = (e) =>{
        setNombreUnidad(e.target.value);        
    }

    const OnChangeStatus = (e) =>{
        setStatus(e.target.value);          
    }

    const updateData = () => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/unidades/list')
            .then(response => {
                setIsAuthorized(true);
                setUnidadesListado(response.data.data.data);
            })
            .catch(e => {
                setIsAuthorized(false);
                console.log("Expired token - Error #45435");
            })

        );
        
    }

    useEffect(() => {

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
        const retrieveData = () => {

            trackPromise(

                instance.get('/unidades/list')
                .then(response => {
                    
                    setTotalRows(response.data.data.total);
                    setUnidadesListado(response.data.data.data);

                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })
  
            );
            
        }

        retrieveData();

        }
        

    },[]);

    const updateUnidades = () => {
        setUnidadesListado([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/unidades/list')
            .then(response => {
                setUnidadesListado(response.data.data.data);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const NextPage = (page) => {

        setLoading(true);
        setUnidadesListado([]);
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(

            instance.get('/unidades/list?page='+page)
            .then(response => {
                setUnidadesListado(response.data.data.data);
                setTotalRows(response.data.data.total);
                setLoading(false);
            })
            .catch(e => {
              console.log(e);
            })

        );
    }

    const handleDeleteTrue = (id) => { 

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.delete('/unidades/delete/'+id)
            .then(response => {
                if(response.status === 200){
                    updateUnidades();
                    NextPage(page);
                    SetVisible({
                        visible: false,
                        id: null,
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
        );
    }

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    const handleDeleteFalse2 = () => {
        SetVisible2(false);
    }

    const handleDeleteFalse3 = () => {
        SetVisible3(false);
    }

    const openModal = (id) => {
        SetVisible2(true);
        setNombreUnidad(""); 
        setStatus("");
        setShowBtn(false); 

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        trackPromise(
            instance.get('unidades/show/'+id)
            .then(response => {
                setDataUnidad(response.data.data);
                setNombreUnidad(response.data.data.nombre_unidad);
                setStatus(response.data.data.status);
            })
              .catch(e => {
                console.log(e);
              })
          );
    }

    const openModalNew = (id) => {
        SetVisible3(true);
        setShowBtn(false)
        setNombreUnidad(""); 
        setStatus("");

    }

    const handleSubmit = () => {

        let errorsForm = 0;
        setShowBtn(true)
            const regex_letras 	= /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
            
            //---------------------- [		validaciones		] --------------------------
            if(nombreUnidad.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreUnidad("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
            }
            else{
                setErrorNombreUnidad("");
            }

                if(!status){
                    //console.log("Error: solo letras");
                    setErrorStatus("Por favor seleccione una opción válida");
                    errorsForm = errorsForm + 1;
                    console.log(errorsForm);
                }
                else{
                    setErrorStatus("");
                }

                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    trackPromise(
                        instance.put('unidades/update/'+dataUnidad.id, { 
                            nombre_unidad: nombreUnidad, 
                            status: status
                            })
                        .then(response => {
                          
                          if(response.data.success === true){
                                
                                setNombreUnidad(""); 
                                setStatus("");
                                SetVisible2(false);
                                updateData();
                                NextPage(page);
                                setOpen(true);
                                //console.log("Pagina: "+page);
                          }
                          
                        })
                          .catch(e => {
                            console.log(e);
                          })
                      );
                } 
    }

    const handleSubmitNew = () => {

        let errorsForm = 0;
        setShowBtn(true)
            const regex_letras 	= /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u;
            
            //---------------------- [		validaciones		] --------------------------
            if(nombreUnidad.match(regex_letras) == null){
                //console.log("Error: solo letras");
                setErrorNombreUnidad("Sólo letras");
                errorsForm = errorsForm + 1;
                console.log(errorsForm);
            }
            else{
                setErrorNombreUnidad("");
            }

                if(!status){
                    //console.log("Error: solo letras");
                    setErrorStatus("Por favor seleccione una opción válida");
                    errorsForm = errorsForm + 1;
                    console.log(errorsForm);
                }
                else{
                    setErrorStatus("");
                }

                if(!errorsForm){

                    let ud       = sessionStorage.getItem('userData');
                    let userData = JSON.parse(ud);

                    const instance = axios.create({
                        baseURL: global.config.server_endpoint.url,
                        timeout: 10000,
                        headers: {'Authorization': 'Bearer '+userData.token}
                    });

                    trackPromise(
                        instance.post('unidades/add', { 
                            nombre_unidad: nombreUnidad, 
                            status: status
                            })
                        .then(response => {
                          
                          if(response.data.success === true){
                                //alert("El área se guardó exitosamente")
                                setUnidadesListado([]);
                                updateData();
                                setNombreUnidad(""); 
                                setStatus("");
                                SetVisible3(false);
                                NextPage(page);
                          }
                          
                        })
                          .catch(e => {
                            console.log(e);
                          })
                      );
                } 
                else{
                    setShowBtn(false)
                }
    }

    const showConfirm = (id) => {
        SetVisible({visible: true, id: id});
    }

    const columns = [
        { field: 'id', 
            headerName: 'ID', 
            headerClassName: 'letal',
            width: 90 },
        {
          field: 'nombre_unidad',
          headerName: 'Nombre unidad',
          minWidth: 250,
          flex: 1,
          headerClassName: 'letal',
        },
        {
          field: 'status',
          flex: 0.5,
          headerName: 'Estatus',
          headerClassName: 'letal',
          minWidth: 100,
          renderCell: (params) => {
                let txt_status = "";
                //console.log(params.row.status);
                switch (params.row.status) {
                    case 1:
                        txt_status = "Activo"
                        break;
                    case 2:
                            txt_status = "Inactivo"
                        break;
                    default:
                        txt_status = "-"
                        break;
                }

                return txt_status;
          },
        },
        {
            field: ' ',
            headerName: "sd",
            headerClassName: 'letal',
            width: 250,
            sortable: false,
            renderHeader: (params) => (
                <h2 style={{height: "90px"}}>&nbsp;</h2>
            ),
            renderCell: (params) => (
                <strong>
                    <Tooltip title="Borrar">
                        <Button
                            variant="text"
                            color="error"
                            size="large"
                            className="pr-1 mr-1 btn_emp"
                            startIcon={<DeleteIcon />}
                            onClick={() => showConfirm(params.id)}
                        />
                    </Tooltip>
                  
                    <Tooltip title="Editar">
                        <Button
                            variant="text"
                            color="primary"
                            size="large"
                            className="mr-1"
                            startIcon={<EditIcon />}
                            onClick={() => openModal(params.id)}
                        />
                    </Tooltip>
                </strong>
            ),
        }
        
    
    ];

    const rows = unidadesListado;
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    ///----------------------[  main return  ]---------------------------
      
     if(sessionStorage.getItem('userData') != null && userData.jerarquia<3 && isAuthorized){
        return(<>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        La información se guardo exitosamente.
                    </Alert>
                </Snackbar>

                <Container className="mr-inf">
                    <div className="row">
                        <div className="col-10">
                            <div className="titulo_login">Unidades</div>
                        </div>
                        <div className="col-2">
                        <IconButton aria-label="delete" className="mt-2 float-right" color="error" size="large" onClick={() => openModalNew()}>
                            <AddCircle fontSize="inherit" className="new-icon"  />
                            <h1 className="add-new ml-2 mt-2">Agregar</h1>
                        </IconButton>
                        {/* <h1 className="addEmpleado handy" onClick={() => openModalNew()}>
                                <FontAwesomeIcon icon={faPlus} className="icono_blanco" />
                            </h1> */}
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12">
                            <div className="areas_datagrid">
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    paginationMode="server"
                                    rowCount={totalRows}
                                    loading={loading}
                                    onPageChange={(newPage) => {
                                        prevSelectionModel.current = selectionModel;
                                        NextPage(newPage+1);
                                        setPage(newPage+1);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </div>
                    </div>

            </Container>

            <Modal size="lg" show={visible.visible} onHide={handleDeleteFalse} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">¿Estas seguro que deseas eliminarlo?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="text-center">
                            <Button onClick={() => handleDeleteTrue(visible.id)} variant="contained" color="success" className="mt-3 float-left" >
                                Confirmar
                             </Button>
                             <Button onClick={() => handleDeleteFalse()} variant="contained" color="error" className="mt-3 float-right" >
                                Cancelar
                             </Button> 
                             </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible2} onHide={handleDeleteFalse2} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Editar unidad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="row mt-4">
					<div className="col-md-2">
						<span className="txt_listado">Nombre Unidad</span>
					</div>
					<div className="col-md-10">
						<span><input type="text" className="form-control" id="inp_nombre_proyecto" name="nombre_area" defaultValue={nombreUnidad || ''} onChange={OnChangeNombreUnidad} required="required" /></span>
						<span className="red_alert">{ errorNombreUnidad }</span>
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-2">
						<span className="txt_listado">Status</span>
					</div>
					<div className="col-md-10">
						<span>
							<select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
								<option value="">Seleccione...</option>
                                <option value="1">Activo</option>
								<option value="2">Inactivo</option>
							</select>
						</span>
                        <span className="red_alert">{ errorStatus }</span>
					</div>
				</div>
                <div className="row mt-3 mb-5">
					<div className="col-md-2">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-10">
						<span><Button type="submit" value="Guardar" disabled={showBtn}  variant="contained" color='success' className="w-100" id="inp_enviar" name="inp_enviar" onClick={handleSubmit}>Guardar</Button></span>
					</div>
				</div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={visible3} onHide={handleDeleteFalse3} animation={false}>
                <Modal.Header closeButton className="no-line-footer">
                    <Modal.Title className="titulo_login">Agregar unidad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="row mt-4">
					<div className="col-md-2">
                        <span className="txt_listado">Nombre unidad</span>
					</div>
					<div className="col-md-10">
						<span><input type="text" className="form-control" id="inp_nombre_unidad" name="nombre_unidad" defaultValue={nombreUnidad || ''} onChange={OnChangeNombreUnidad} required="required" /></span>
						<span className="red_alert">{ errorNombreUnidad }</span>
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-md-2">
                        <span className="txt_listado">Status</span>
					</div>
					<div className="col-md-10">
						<span>
							<select className="form-control" id="inp_status" name="status" value={status} onChange={OnChangeStatus} required="required">
								<option value="">Seleccione...</option>
                                <option value="1">Activo</option>
								<option value="2">Inactivo</option>
							</select>
						</span>
                        <span className="red_alert">{ errorStatus }</span>
					</div>
				</div>
                <div className="row mt-3 mb-5">
					<div className="col-md-2">
						<span>&nbsp;</span>
					</div>
					<div className="col-md-10">
						<span><Button type="submit" variant="contained" color="success" disabled={showBtn} className="btn btn-success form-control" id="inp_enviar" name="inp_enviar" onClick={handleSubmitNew}>Guardar</Button></span>
					</div>
				</div>
                    </Container>
                </Modal.Body>
            </Modal>

        </>)
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default Unidades;