import React, {Component} from 'react';
import './css/Footer.css';

class Footer extends Component {

render() {

return (
	<>
	<div className="footer"><span className="txt_footer">Todos los derechos reservados 2021 | Tonino.mx &reg;</span></div>
	</>);


}
}

export default Footer;