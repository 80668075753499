import React, {useState, useEffect } from 'react';
import { Container, Modal } from 'react-bootstrap';
import './css/Login.css';
import Header from './headerInterior';
import axios from 'axios';
import  { Redirect, useParams } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupIcon from '@mui/icons-material/Group';
import ShareIcon from '@mui/icons-material/Share';
import CheckIcon from '@mui/icons-material/Check';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { initFacebookSdk } from './fb-connect';

//import { initFacebookSdk } from './fb_connect';
//------------

//---------------------------------------------------------------

const Parrillas = () =>{

    //------------------------- hooks ----------------------
    const { id_cliente }                                    = useParams()
    const [isAuthorized, setIsAuthorized]                   = useState(true)
    const [estadoInicial, setEstadoInicial]                 = useState(1)
    const [nombreCliente, setNombreCliente]                 = useState("")
    const [logoCliente, setLogoCliente]                     = useState("")
    const [nombreCampana, setNombreCampana]                 = useState("")
    const [zonaHoraria, setZonaHoraria]                     = useState("")
    const [tipoUsuario, setTipoUsuario]                     = useState("")
    const [listadoEmpleados, setListadoEmpleados]           = useState([])
    const [usuariosInternos, setUsuariosInternos]           = useState([])
    const [usuariosExternos, setUsuariosExternos]           = useState([])
    const [tmpEmail, setTmpEmail]                           = useState("")
    const [tmpJerarquia, setTmpJerarquia]                   = useState("")
    const [tmpFullname, setTmpFullname]                     = useState("")
    const [listadoPages, setListadoPages]                   = useState([])
    const [fbToken, setFbToken]                             = useState("")  
    const [visible , SetVisible]                            = useState(false)  
    const [selectedToken, setSelectedToken]                 = useState("");   
    const [tipoWorkflow, setTipoWorkflow]                   = useState(""); 
    const [isActiveNone, setActiveNone]                     = useState(false);
    const [isActiveOptional, setActiveOptional]             = useState(false);
    const [isActiveMandatory, setActiveMandatory]           = useState(false);
    const [isActiveMulti, setActiveMulti]                   = useState(false);
    //-------------------- nuevo data grid -------------------
    const facebookAppId                                     = process.env.REACT_APP_FACEBOOK_APP_ID;
    const facebookAppSecret                                 = process.env.REACT_APP_FACEBOOK_APP_SECRET;
    const [open, setOpen]                                   = useState(false)

    initFacebookSdk()

    let ud       = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
      return;
      }
      setOpen(false);
    }

    const OnChangeNombreCampana = (e) =>{
        setNombreCampana(e.target.value);        
    }

    const OnChangeTmpEmail = (e) =>{
        setTmpEmail(e.target.value);        
    }

    const OnChangeTmpFullname = (e) =>{
        setTmpFullname(e.target.value);        
    }

    const OnChangeTmpJerarquia = (e) =>{
        setTmpJerarquia(e.target.value);        
    }

    const OnChangeZonaHoraria = (e) =>{
        //console.log(e.target.value);
        setZonaHoraria(e.target.value);          
    }

    const OnChangeTipoUsuario = (e) =>{
        //console.log(e.target.value);
        setTipoUsuario(e.target.value);          
    }

    useEffect(() => {

        //document.body.style = 'background: #DFDFDF !important;';

        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        if(userData.jerarquia<3){
            const instance = axios.create({
                baseURL: global.config.server_endpoint.url,
                timeout: 10000,
                headers: {'Authorization': 'Bearer '+userData.token}
            });
            
            
            trackPromise(
                    
                instance.get("/clientes/"+id_cliente)
                .then(response => {
                    //console.log(response.data.data.nombre_empresa);
                    setNombreCliente(response.data.data.nombre_empresa);
                    setLogoCliente(response.data.data.logo);
                    setIsAuthorized(true);
                    //setListadoClientes(response.data.data);
                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })

            );

            trackPromise(
                
                instance.get("/empleados")
                .then(response => {
                    //console.log(response.data.data.data);
                    setListadoEmpleados(response.data.data.data);
                    //setLogoCliente(response.data.data.logo);
                    setIsAuthorized(true);
                    //setListadoClientes(response.data.data);
                })
                .catch(e => {
                    setIsAuthorized(false);
                    console.log("Expired token - Error #45435");
                })

            );

        }
        

    },[id_cliente])

    const updateState = () =>{
        let actual = estadoInicial+1;
        setEstadoInicial(actual);
        //console.log(actual);

    }

    const Finish = () =>{

        // ------------------------
        let ud       = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);
        
        const instance = axios.create({
            baseURL: global.config.server_endpoint.url,
            timeout: 10000,
            headers: {'Authorization': 'Bearer '+userData.token}
        });

        if(nombreCampana && zonaHoraria && tipoWorkflow){

        trackPromise(

            instance.post('/parrillas', {
                id_cliente: id_cliente,
                nombre_campana: nombreCampana,
                zona_horaria: zonaHoraria,
                tipo_workflow: tipoWorkflow
            })
            .then(response => {
                if(response.status === 200){
                    setNombreCampana(null)
                    setZonaHoraria(null)
                    setTipoWorkflow(null)
                    
                    // ------------------------
                    if(usuariosInternos.length !== 0){
                        
                        usuariosInternos.forEach(element => {
                            trackPromise(
            
                                instance.post('/stakeholders', {
                                    id_cliente: id_cliente,
                                    id_parrilla: response.data.data.id,
                                    tipo: "interno",
                                    id_empleado: element.id,
                                    jerarquia: element.jerarquia
                                })
                                .then(response2 => {
                                    //console.log(response2.data.success)
                                })
                                .catch(e => {
                                  console.log(e);
                                })
                    
                            );
                        })
                        
                    }
                    // ------------------------
                    if(usuariosExternos.length !== 0){
                        
                        //console.log(usuariosExternos)
                        usuariosExternos.forEach(element => {
                            //console.log(element)
                            trackPromise(
            
                                instance.post('/stakeholders', {
                                    id_cliente: id_cliente,
                                    id_parrilla: response.data.data.id,
                                    tipo: "externo",
                                    full_name: element.fullname,
                                    email: element.email,
                                    jerarquia: element.jerarquia
                                })
                                .then(response2 => {
                                    //console.log(response2.data.success)
                                })
                                .catch(e => {
                                  console.log(e);
                                })
                    
                            );
                        })
                        
                    }
                    // ------------------------
                }
                //console.log(response.data.data.id)
            })
            .catch(e => {
              console.log(e);
            })

        );
        // ------------------------

        }


        // ------------- check tokens

        
        // ------------------------
        // save tokens
            //console.log(selectedToken[0])

            for (let index = 0; index < selectedToken.length; index++) {
                //const element = selectedToken[index];
                //console.log(selectedToken[index])
                trackPromise(
                    instance.post('/access-tokens',  selectedToken[index] )
                    .then(response2 => {
                        //console.log(response2)
                    })
                    .catch(e => {
                      console.log(e);
                    })
        
                );
            }
        // ------------------------

        let actual = estadoInicial+1;
        setEstadoInicial(actual);

    }

    const updateLessState = () =>{
        let actual = estadoInicial-1;
        setEstadoInicial(actual);
        //console.log(actual);

    }

    const onCheckBox = (e) => {
       
        if (e.target.checked) {
        //   setUsuariosInternos([
        //     ...usuariosInternos,
        //     {
        //       id: parseInt(e.target.value,10),
        //       nombres: e.target.name
        //     },
        //   ]);
        } else {
        console.log(e.target.value)
          // remove from list
            setUsuariosInternos(
            usuariosInternos.filter((usuarios) => usuarios.id !== parseInt(e.target.value,10)),
          );
        }

    }

    const OnChangeJerarquia = (e) => {
        
        // console.log(e.target.value)
        // console.log(e.target.name)
        // console.log( parseInt(e.target.id,10))
        
        let existe = usuariosInternos.find( (usuarios) => usuarios.id === parseInt(e.target.id,10))

        if(existe){

            setUsuariosInternos(
                     usuariosInternos.filter((usuarios) => usuarios.id !== parseInt(e.target.id,10)),
            );
            
            e.target.value= ""

        }
        else{
                setUsuariosInternos([
                  ...usuariosInternos,
                  {
                    id: parseInt(e.target.id,10),
                    nombres: e.target.name,
                    jerarquia: e.target.value
                  },
                ]);
        }

    }

    const addExterno = (e) => {
       
        if (tmpEmail && tmpJerarquia && tmpFullname) {
          setUsuariosExternos([
            ...usuariosExternos,
            {
              email: tmpEmail,
              jerarquia: tmpJerarquia,
              fullname: tmpFullname
            },
          ]);

          setTmpEmail("")
          setTmpJerarquia("")
          setTmpFullname("")
        } 
        else{
            alert("faltan datos ["+tmpEmail+"]");
        }
    }

    const deleteExterno = (ide) => {
            // remove from list
            setUsuariosExternos(
            usuariosExternos.filter((usuarios) => usuarios.email !== ide),
          );


    }

    const getData = (id) => {
        
        window.FB.api(id+"?fields=access_token,name&access_token="+fbToken, function(response3) {
            
            window.FB.api('debug_token?input_token='+response3.access_token, function(response4) {
                
                // let milliseconds = response4.data.data_access_expires_at * 1000 
                // let dateObject = new Date(milliseconds)
                // let fecha_vigencia = dateObject.toLocaleString()
                
                if(response3.access_token){

                    setSelectedToken([{ id_cliente: id_cliente, id_pagina: response3.id, plataforma: "facebook", name: response3.name, token: response3.access_token, vigencia: response4.data.data_access_expires_at}])
                    SetVisible(false)
                }

                // ------------ posteo ---------------
                //
                // console.log(response3.id)
                // var lgo = 'Reading JS SDK documentation';
                // window.FB.api(response3.id+'/feed', 'post', { access_token: response3.access_token, message: lgo }, function(response) {
                //     if (!response || response.error) {
                //         alert('Error occured');
                //     } else {
                //         alert('Post ID: ' + response.id);
                //     }
                // });
                //
                // ------------------------------------
                

            });
        });
    } 

    const doLogin = () => {
        
        window.FB.init({
            appId: facebookAppId,
            appSecret: facebookAppSecret,
            cookie: true,
            xfbml: true,
            version: 'v12.0'
        });
        
        
        window.FB.getLoginStatus(function(response) {  
            if (response.status !== 'connected') {
                const { authResponse } = new Promise(window.FB.login);
                // Access - Token
                if(authResponse)
                    setFbToken(authResponse.accessToken);
            }
           
        });

        window.FB.api('/me?fields=name,first_name,last_name,email,link,gender,locale,picture', function(response) {
      
            // /accounts
            window.FB.api(response.id+"/accounts?fields=picture,access_token,id,name", function(response2) {
              //console.log(response2.data)
              setListadoPages(response2.data)
              if(response2.data){
                    SetVisible(true);
              }
            });
      
          });

       
    }

    // --------- todas las pantallas -----------
    const changeState = () =>{

        // pantalla 1
        if(estadoInicial === 1){
            return(
                <>
                    <Container className='pb-5'>
                        <div className="d-none d-sm-none d-md-block">
                            <div className="row bread-div p-2 pb-3 mt-5 rounded">
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /> &nbsp; CREAR CAMPAÑA</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior"><GroupIcon className="icono_bread_off" /> &nbsp; COLABORADORES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior"><ShareIcon className="icono_bread_off" /> &nbsp; CANALES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior"><CheckIcon className="icono_bread_off" /> &nbsp; WORKFLOW</h1>
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-block d-md-none">
                            <div className="row bread-div pb-2 mt-5 rounded">
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><GroupIcon className="icono_bread_off" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><ShareIcon className="icono_bread_off" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><CheckIcon className="icono_bread_off" /></h1>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center mt-4'>
                            <div className='col-md-6 text-center'>
                                <h1 className='new_campaign_title'>CREAR NUEVA CAMPAÑA</h1>
                                <h2 className='new_campaign_subtitle'>Una nueva campaña es un grupo de canales de Social Media y colaboradores.</h2>
                                <h2 className='new_campaign_subtitle'>Aquí podrás organizar tus campañas, flujos y aprobaciones.</h2>
                            </div>
                        </div>
                        <div className='row justify-content-center mt-4'>
                            <div className='col-md-6'>
                                <label htmlFor="name" className='new_campaign_subtitle2'>Nombre de campaña</label>
                                <input id="name" className='form-control input_campaign' autoComplete='off' type="text" defaultValue={nombreCampana || ''} onKeyUp={OnChangeNombreCampana} />
                            </div>
                        </div>
                        <div className='row justify-content-center mt-4'>
                            <div className='col-md-6'>
                                <label htmlFor="name" className='new_campaign_subtitle2'>Zona horaria (opcional para programar y publicar automaticamente)</label>
                                <select className='form-control select_campaign' value={zonaHoraria} onChange={OnChangeZonaHoraria}>
                                    <option value="">Seleccione...</option>
                                    <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                                    <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                                    <option value="-10:00">(GMT -10:00) Hawaii</option>
                                    <option value="-09:50">(GMT -9:30) Taiohae</option>
                                    <option value="-09:00">(GMT -9:00) Alaska</option>
                                    <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                    <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                    <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                    <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                    <option value="-04:50">(GMT -4:30) Caracas</option>
                                    <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                    <option value="-03:50">(GMT -3:30) Newfoundland</option>
                                    <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                    <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                                    <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                                    <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                    <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                    <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                                    <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                    <option value="+03:50">(GMT +3:30) Tehran</option>
                                    <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                    <option value="+04:50">(GMT +4:30) Kabul</option>
                                    <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                    <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                    <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                                    <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                    <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                                    <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                    <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                    <option value="+08:75">(GMT +8:45) Eucla</option>
                                    <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                    <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                                    <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                    <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                                    <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                    <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                                    <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                    <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                                    <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                                    <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                                </select>
                            </div>
                        </div>
                        <div className='row justify-content-center mt-5 mb-5'>
                            <div className='col-md-6'>
                                <input type="button" className="btn_blue" value="Siguiente" onClick={() => updateState()} />
                            </div>
                        </div>
                    
                    </Container>
                </>
            )
        }
        // pantalla 2 players
        if(estadoInicial === 2){
            return(
                <>
                    <Container className='pb-5'>
                    <div className="d-none d-sm-none d-md-block">
                            <div className="row bread-div p-2 pb-3 mt-5 rounded">
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /> &nbsp; CREAR CAMPAÑA</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><GroupIcon className="icono_bread" /> &nbsp; COLABORADORES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior"><ShareIcon className="icono_bread_off" /> &nbsp; CANALES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior"><CheckIcon className="icono_bread_off" /> &nbsp; WORKFLOW</h1>
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-block d-md-none">
                            <div className="row bread-div pb-2 mt-5 rounded">
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><GroupIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><ShareIcon className="icono_bread_off" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><CheckIcon className="icono_bread_off" /></h1>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-9'>

                        
                                <div className='row justify-content-center mt-4'>
                                    <div className='col-md-10 text-center'>
                                        <h1 className='new_campaign_title'>INVITA A TU CLIENTE Y A LOS COLABORADORES DE LA CAMPAÑA</h1>
                                        <h2 className='new_campaign_subtitle'>Invita a todas las personas involucradas en la campaña, así podrán dejar comentarios o aprobar sobre el contenido</h2>
                                    </div>
                                </div>
                                <div className='row justify-content-center mt-2'>
                                    <div className='col-md-10'>
                                        <label htmlFor="name" className='new_campaign_subtitle2'>Tipo de usuario</label>
                                        <select className='form-control select_campaign' value={tipoUsuario} onChange={OnChangeTipoUsuario}>
                                            <option value="">Seleccione...</option>
                                            <option value="interno">Interno</option>
                                            <option value="externo">Externo</option>
                                        </select>
                                    </div>
                                </div>
                        {
                            tipoUsuario === "interno" && (
                        
                                <div className='row justify-content-center mt-4'>
                                    <div className='col-md-10'>
                                        <div className='row'>
                                        
                                            {
                                                
                                                listadoEmpleados.map(post => {
                                                const { id, nombres, apellido_paterno } = post;
                                                //aqui mero
                                                if(usuariosInternos.find((usuarios) => usuarios.id === id)){
                                                    return (
                                                        <div className='col-md-12 mt-3' key={id}>
                                                            <div className='row'>
                                                                <div className='col-md-1'>
                                                                    <input type='checkbox' defaultChecked name={nombres+" "+apellido_paterno} value={id} onClick={onCheckBox}  />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <span className='new_campaign_subtitle2'>{nombres} {apellido_paterno}</span>
                                                                </div>
                                                                <div className='col-md-5'>
                                                                    <select  name={nombres+" "+apellido_paterno} id={id} className='form-control select_campaign' onChange={OnChangeJerarquia}>
                                                                        <option value="">Seleccione...</option>
                                                                        <option value="administrador">Administrador</option>
                                                                        <option value="copy">Copy</option>
                                                                        <option value="designer">Diseñador</option>
                                                                        <option value="autorizador">Autorizador</option>
                                                                        <option value="invitado">Invitado (solo puede comentar)</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else{
                                                    return (
                                                        <div className='col-md-12 mt-3' key={id}>
                                                            <div className='row'>
                                                                <div className='col-md-1'>
                                                                    <input type='checkbox' name={nombres+" "+apellido_paterno} value={id} onClick={onCheckBox}  />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <span className='new_campaign_subtitle2'>{nombres} {apellido_paterno}</span>
                                                                </div>
                                                                <div className='col-md-5'>
                                                                    <select id={id} name={nombres+" "+apellido_paterno} className='form-control select_campaign' onChange={OnChangeJerarquia}>
                                                                        <option value="">Seleccione...</option>
                                                                        <option value="administrador">Administrador</option>
                                                                        <option value="copy">Copy</option>
                                                                        <option value="designer">Diseñador</option>
                                                                        <option value="autorizador">Autorizador</option>
                                                                        <option value="invitado">Invitado (solo puede comentar)</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                //end aqui mero
                                               
                                                })
                                                
                                            }
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            )
                        }

                        {
                            tipoUsuario === "externo" && (
                                <>
                                <div className='row justify-content-center mt-1'>
                                    <div className='col-md-10' key={id_cliente}>
                                        <label htmlFor="email" className='new_campaign_subtitle2'>Nombre completo</label>
                                        <input id="fullname" type='text' className='form-control input_campaign' value={tmpFullname || ''} onChange={OnChangeTmpFullname} />
                                    </div>
                                </div>
                                <div className='row justify-content-center mt-1'>
                                    <div className='col-md-10' key={id_cliente}>
                                        <label htmlFor="email" className='new_campaign_subtitle2'>Email</label>
                                        <input id="email" type='email' className='form-control input_campaign' value={tmpEmail || ''} onChange={OnChangeTmpEmail} />
                                    </div>
                                </div>
                                <div className='row justify-content-center mt-1'>
                                    <div className='col-md-10' key={id_cliente}>
                                        <label htmlFor="jerarquia" className='new_campaign_subtitle2'>Jerarquía</label>
                                        <select id="jerarquia" className='form-control select_campaign' value={tmpJerarquia} onChange={OnChangeTmpJerarquia}>
                                            <option value="">Seleccione...</option>
                                            <option value="administrador">Administrador</option>
                                                <option value="copy">Copy</option>
                                                <option value="designer">Diseñador</option>
                                                <option value="autorizador">Autorizador</option>
                                                <option value="invitado">Invitado (solo puede comentar)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row justify-content-center mt-4 mb-5'>
                                    <div className='col-md-10 text-center'>
                                    <input type="button" className="btn_grey_50" value="Agregar usuario" onClick={() => addExterno()} />
                                    </div>
                                </div>
                                </>
                            )
                        }
                        
                        

                        <div className='row justify-content-center mt-5 mb-5'>
                            <div className='col-md-10 text-center'>
                                <input type="button" className="btn_blue_50" value="Regresar" onClick={() => updateLessState()} />&nbsp; 
                                <input type="button" className="btn_blue_50" value="Siguiente" onClick={() => updateState()} />
                            </div>
                        </div> 
                    
                    </div>
                    <div className='col-md-3 pt-4 nowe'>
                        <h1 className='new_campaign_subtitle font-weight-bold'>USUARIOS INTERNOS</h1>
                        <ul>
                                {
                                    usuariosInternos.map(post => {
                                    const { id, nombres, jerarquia } = post;
                                    return (
                                        <li className='listado_reales' key={id}>{nombres}<br /><span className='small_list'>{jerarquia}</span></li>
                                        
                                    );
                                    })
                                }
                        </ul>
                        <h1 className='new_campaign_subtitle font-weight-bold'>USUARIOS EXTERNOS</h1>
                        <ul>
                                {
                                    usuariosExternos.map(post => {
                                    const { email, jerarquia, fullname } = post;
                                    return (
                                        <li className='listado_reales' key={email}>{fullname} <DeleteIcon className="icon_trash_small" onClick={() => deleteExterno(email)} /> <br /> {email} <br /> <span className='small_list'>{jerarquia}</span></li>
                                        
                                    );
                                    })
                                }
                        </ul>
                    </div>
                    
                    </div>
                    
                    </Container>
                </>
            )
        }

        //pantalla 3 - choose social network
        if(estadoInicial === 3){
            return(
                <>
                    <Container className='pb-5'>
                    <div className="d-none d-sm-none d-md-block">
                            <div className="row bread-div p-2 pb-3 mt-5 rounded">
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /> &nbsp; CREAR CAMPAÑA</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><GroupIcon className="icono_bread" /> &nbsp; COLABORADORES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><ShareIcon className="icono_bread" /> &nbsp; CANALES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior"><CheckIcon className="icono_bread_off" /> &nbsp; WORKFLOW</h1>
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-block d-md-none">
                            <div className="row bread-div pb-2 mt-5 rounded">
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior_on"><GroupIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior_on"><ShareIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><CheckIcon className="icono_bread_off" /></h1>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center mt-4'>
                            <div className='col-md-8 text-center'>
                                <img src={"https://d2sx5pnhyjgoui.cloudfront.net/images/"+logoCliente}  alt={nombreCliente} className='image-campaign' />
                                <h1 className='new_campaign_title_blue'>CONECTAR PÁGINAS DE {nombreCliente}</h1>
                                <h2 className='new_campaign_subtitle'>Agrega las páginas de redes sociales de la marca. También podrás agregarlas después.</h2>
                            </div>
                        </div>
                     
                        <div className='row justify-content-center mt-5'>
                            <div className='col-md-8 text-center'>
                                <div className='row'>
                                    <div className='col-md-4 col-6'>
                                        <div className='col-md-10 justify-content-center cliqueable marco_new mt-4' onClick={doLogin}>
                                            <FacebookIcon className='fb_icon' />
                                            <h1 className='txr_rrss'>Facebook</h1>
                                            <h2 className='txr_rrss_gris'>Página o grupo</h2>
                                            {
                                                //console.log(selectedToken)
                                                selectedToken[0] && selectedToken[0].token && (
                                                    <>
                                                        <CheckCircleIcon className="checked_icon" />
                                                    </>
                                                )

                                            }
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='col-md-10 justify-content-center marco_new mt-4'>
                                            <TwitterIcon className='tw_icon' />
                                            <h1 className='txr_rrss'>Twitter</h1>
                                            <h2 className='txr_rrss_gris'>Perfil</h2>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='col-md-10 justify-content-center marco_new mt-4'>
                                            <InstagramIcon className='ig_icon' />
                                            <h1 className='txr_rrss'>Instagram</h1>
                                            <h2 className='txr_rrss_gris'>Perfil</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-md-8 text-center'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-4 col-6'>
                                        <div className='col-md-10 justify-content-center marco_new mt-4'>
                                            <YouTubeIcon className='yt_icon' />
                                            <h1 className='txr_rrss'>Youtube</h1>
                                            <h2 className='txr_rrss_gris'>Página o grupo</h2>
                                        </div>
                                        
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='col-md-10 justify-content-center marco_new mt-4'>
                                            <LinkedInIcon className='li_icon' />
                                            <h1 className='txr_rrss'>LinkedIn</h1>
                                            <h2 className='txr_rrss_gris'>Perfil</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='row justify-content-center mt-5 mb-5'>
                            <div className='col-md-8 text-center'>
                                <input type="button" className="btn_blue_50" value="Regresar" onClick={() => updateLessState()} />&nbsp; 
                                <input type="button" className="btn_blue_50" value="Siguiente" onClick={() => updateState()} />
                            </div>
                        </div> 
                    
                    </Container>
                </>
            )
        }

        //pantalla 4 - workflow
        if(estadoInicial === 4){
            return(
                <>
                    <Container className='pb-5'>
                    <div className="d-none d-sm-none d-md-block">
                            <div className="row bread-div p-2 pb-3 mt-5 rounded">
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /> &nbsp; CREAR CAMPAÑA</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><GroupIcon className="icono_bread" /> &nbsp; COLABORADORES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><ShareIcon className="icono_bread" /> &nbsp; CANALES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><CheckIcon className="icono_bread" /> &nbsp; WORKFLOW</h1>
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-block d-md-none">
                            <div className="row bread-div pb-2 mt-5 rounded">
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><GroupIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><ShareIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><CheckIcon className="icono_bread" /></h1>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center mt-4'>
                            <div className='col-md-8 text-center'>
                                <h1 className='new_campaign_title'>WORKFLOW</h1>
                                <h2 className='new_campaign_subtitle'>Elige el flujo de aprobaciones para esta campaña. Puedes modificarlo después en el menú <strong>HERRAMIENTAS</strong></h2>
                            </div>
                        </div>
                        <div className='row justify-content-center mt-4'>
                            <div className='col-md-6 mt-4'>
                                <div className='row justify-content-center'>
                                    <div className={isActiveNone ? "app col-md-10 col-10 p-4 backed-grey rounded" : "col-md-10 col-10 p-4 backed-grey rounded"} id="none" onClick={(e) => addWorkflow(e)}> 
                                        <RadioButtonUncheckedIcon className='grey-icon' />
                                        <h4 className='new_campaign_title_small'>NINGUNA</h4>
                                        <h2 className='new_campaign_subtitle'>Las aprobaciones están deshabilitadas y no son necesarias para publicar contenido</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mt-4'>
                                <div className='row justify-content-center'>
                                    <div className={isActiveOptional ? "app col-md-10 col-10 p-4 backed-grey rounded" : "col-md-10 col-10 p-4 backed-grey rounded"} id="optional" onClick={(e) => addWorkflow(e)}>
                                        <CheckCircleOutlineIcon className='green-icon' />
                                        <h4 className='new_campaign_title_small'>OPCIONAL</h4>
                                        <h2 className='new_campaign_subtitle'>Las aprobaciones están habilitadas pero no son necesarias para publicar contenido</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mt-4'>
                                <div className='row justify-content-center'>
                                    <div className={isActiveMandatory ? "app col-md-10 col-10 p-4 backed-grey rounded" : "col-md-10 col-10 p-4 backed-grey rounded"} id="mandatory" onClick={(e) => addWorkflow(e)}>
                                    <CheckCircleIcon className='green-icon' /> 
                                        <h4 className='new_campaign_title_small'>OBLIGATORIAS</h4>
                                        <h2 className='new_campaign_subtitle'>Un miembro debe de aprobar el contenido antes de que se pueda publicar</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mt-4'>
                                <div className='row justify-content-center'>
                                    <div className={isActiveMulti ? "app col-md-10 col-10 p-4 backed-grey rounded" : "col-md-10 col-10 p-4 backed-grey rounded"} id="multi" onClick={(e) => addWorkflow(e)}>
                                        <CheckCircleOutlineIcon className='green-icon' />
                                        <CheckCircleOutlineIcon className='green-icon' />
                                        <CheckCircleOutlineIcon className='green-icon' />
                                        <h4 className='new_campaign_title_small'>MULTI NIVEL</h4>
                                        <h2 className='new_campaign_subtitle'>2 ó más miembros necesitan aprobar el contenido antes de que se pueda publicar</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='row justify-content-center mt-5 mb-5'>
                            <div className='col-md-8 text-center'>
                                <input type="button" className="btn_blue_50" value="Regresar" onClick={() => updateLessState()} />&nbsp; 
                                <input type="button" className="btn_blue_50" value="Siguiente" onClick={() => Finish()} />
                            </div>
                        </div> 
                    
                    </Container>
                </>
            )
        }

        //pantalla  thank you
        if(estadoInicial === 5){

            
            return(
                <>
                    <Container className='pb-5'>
                    <div className="d-none d-sm-none d-md-block">
                            <div className="row bread-div p-2 pb-3 mt-5 rounded">
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /> &nbsp; CREAR CAMPAÑA</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><GroupIcon className="icono_bread" /> &nbsp; COLABORADORES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><ShareIcon className="icono_bread" /> &nbsp; CANALES</h1>
                                </div>
                                <div className='col-md-3 col-6'>
                                    <h1 className="titulo_interior_on"><CheckIcon className="icono_bread" /> &nbsp; WORKFLOW</h1>
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-block d-md-none">
                            <div className="row bread-div pb-2 mt-5 rounded">
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior_on"><CampaignIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><GroupIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><ShareIcon className="icono_bread" /></h1>
                                </div>
                                <div className='col-md-3 col-3 text-center'>
                                    <h1 className="titulo_interior"><CheckIcon className="icono_bread" /></h1>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-center mt-5'>
                            <div className='col-md-8 text-center'>
                                <h1 className='new_campaign_title'>¡GRACIAS!</h1>
                                <h2 className='new_campaign_subtitle'>Copy thank you page</h2>
                                
                            </div>
                        </div>
                        
                        <div className='row justify-content-center mt-5 mb-5'>
                            <div className='col-md-8'>
                                <a href="/parrillas">
                                    <input type="button" className="btn_blue" value="Ir a mis parrillas" />
                                </a>
                            </div>
                        </div> 
                        
                    </Container>
                </>
            )
        }
    }

    const addWorkflow = (e) => {
        
        switch (e.target.id) {
            case "none":
                setActiveNone(!isActiveNone);  
                setActiveOptional(false);
                setActiveMandatory(false);
                setActiveMulti(false);  
            break;

            case "optional":
                setActiveNone(false);  
                setActiveOptional(!isActiveOptional);
                setActiveMandatory(false);
                setActiveMulti(false);  
            break;

            case "mandatory":
                setActiveNone(false);  
                setActiveOptional(false);
                setActiveMandatory(!isActiveMandatory);
                setActiveMulti(false); 
            break;

            case "multi":
                setActiveNone(false);  
                setActiveOptional(false);
                setActiveMandatory(false);
                setActiveMulti(!isActiveMulti); 
            break;
        
            default:
                setActiveNone(false);  
                setActiveOptional(false);
                setActiveMandatory(false);
                setActiveMulti(false); 
                break;
        }
        setTipoWorkflow(e.target.id)
    }

    const handleDeleteFalse = () => {
        SetVisible(false);
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    ///----------------------[  main return  ]---------------------------
      
     if(sessionStorage.getItem('userData') != null && userData.jerarquia<5 && isAuthorized){
        return(
                <>
                <Header />
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        La información se guardo exitosamente.
                    </Alert>
                </Snackbar>
                
                {changeState()}
                
                <Modal size="lg" show={visible} onHide={handleDeleteFalse} animation={false}>
                    <Modal.Header closeButton className="no_linea_inferior">
                        <Modal.Title className="titulo_login">Cuentas de Facebook</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                        {                 
                            listadoPages && listadoPages.map(post => {
                                const { id, name, picture } = post;
                                //console.log();
                                return (
                                    <div className="col-md-4 col-6 mb-5 text-center cliqueable" key={id} onClick={() => getData(id)}>
                                        <img src={picture.data.url} className='img-rounded2 mb-1' alt={name} /><br />
                                        <span className='title_page'>{name}</span>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </Modal.Body>
                </Modal>
                </>
                )
     }
     else{
        return <Redirect to="/logout"  />;
    }
};

export default Parrillas;